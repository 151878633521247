import {
  DEFAULT_CURRENCY,
  PricingModel,
  computeCumulativeValue,
  computeQuantities,
  formatAmount,
  formatAmountFromString,
  formatPriceUnit,
  getDisplayTierByQuantity,
  getDisplayTiersByQuantity,
  getTierDescription,
  computeAggregatedAndPriceTotals
} from '@epilot/pricing'
import type { PriceTierDisplayMode, Currency } from '@epilot/pricing'
import type { Paths, RedeemedPromo } from '@epilot/pricing-client'
import type { TFunction } from 'i18next'

import { shouldShowDecimals } from '../../utils/shouldShowDecimals'
import { extractAndSortItemRecurrences } from '../product/sortItemsRecurrenceDetails'
import type {
  BlockMappingData,
  BillingPeriod,
  CompositePrice,
  Price,
  PriceItem,
  CompositePriceItem,
  TypeGetAg,
  PriceTier,
  ComputePrice,
  PriceWithBlockMappings,
  RecurrenceAmount,
  PriceInputMappings,
  ProductTileData,
  PriceItemWithBlockConfiguration
} from '../types'

import { formatUnitWithSuffix } from './quantity'

type AverageMarketPrice = Paths.$AverageMarketPrice.Responses.$200

type HydratedCompositePrice = CompositePrice & {
  price_components: Price[]
}

type PriceWithTieredPricingModel = Omit<Price, 'pricing_model'> & {
  tiers: PriceTier[]
  pricing_model:
    | PricingModel.tieredFlatFee
    | PricingModel.tieredVolume
    | PricingModel.tieredGraduated
}

const priceMaskingRules = {
  show_as_starting_price: {
    maskItemAmount: true
  },
  estimated_price: {
    maskItemAmount: true
  },
  show_price: {
    maskItemAmount: false
  },
  show_as_on_request: {
    maskItemAmount: true
  }
}

/**
 * Removes trailing double-zeros from a price
 * @example 10.00 => 10, 10.20 => 10.20
 */
export const omitTrailingDoubleDecimalZeros = (price: string) => {
  /*
    These Regexes captures 2 groups, the first being the trailing zeros
    and the second what could be after it (spaces and/or currency symbols or a slash)
    These Regexes captures 2 groups, the first being the trailing zeros
    and the second what could be after it (spaces and/or currency symbols or a slash)

    We then use the replace function to remove the first group
  */
  const trailingZerosWithDot = /(\.{1}00)(\s.*)?$/
  const trailingZerosWithComma = /(,{1}00)(\s.*)?$/

  // Added to handle Tiered Pricing prices that contain a slash at the end. e.g. €10.00/Stück
  const trailingZerosWithDotBeforeSlash = /(\.{1}00)(\/[\w\W]*)$/
  const trailingZerosWithCommaBeforeSlash = /(,{1}00)(\/[\w\W]*)$/

  return price
    .replace(trailingZerosWithDot, '$2')
    .replace(trailingZerosWithComma, '$2')
    .replace(trailingZerosWithDotBeforeSlash, '$2')
    .replace(trailingZerosWithCommaBeforeSlash, '$2')
}

export const formatPrice = (
  amount: string | number,
  currency: Currency,
  showTrailingDecimalZeros = false
): string => {
  const formattedAmount =
    typeof amount === 'number'
      ? formatAmount({
          amount,
          currency,
          locale: navigator.language,
          enableSubunitDisplay: true
        })
      : formatAmountFromString({
          decimalAmount: amount,
          currency,
          locale: navigator.language,
          useRealPrecision: false,
          enableSubunitDisplay: true
        })

  if (showTrailingDecimalZeros) {
    return formattedAmount
  }

  return omitTrailingDoubleDecimalZeros(formattedAmount)
}

export const getDiscountAmount = ({
  t,
  showTrailingDecimalZeros,
  recurrence,
  currency
}: {
  t: TFunction
  showTrailingDecimalZeros?: boolean
  recurrence: RecurrenceAmount
  currency?: string
}) => {
  return getDisplayPrice(
    t,
    showTrailingDecimalZeros
      ? recurrence.discount_amount_decimal
      : recurrence.discount_amount,
    undefined,
    showTrailingDecimalZeros,
    currency
  )
}

export const getBeforeDiscountAmount = ({
  t,
  showTrailingDecimalZeros,
  recurrence,
  currency
}: {
  t: TFunction
  showTrailingDecimalZeros?: boolean
  recurrence: RecurrenceAmount
  currency?: string
}) => {
  return getDisplayPrice(
    t,
    showTrailingDecimalZeros
      ? recurrence.before_discount_amount_total_decimal
      : recurrence.before_discount_amount_total,
    undefined,
    showTrailingDecimalZeros,
    currency
  )
}

export function getDisplayPrice(
  t: TFunction,
  unitAmount?: string | number,
  priceDisplayInJourneys?: Price['price_display_in_journeys'],
  showTrailingDecimalZeros = false,
  currency = DEFAULT_CURRENCY
): string {
  if (priceDisplayInJourneys === 'show_as_on_request') {
    return t('show_as_on_request')
  }

  const priceAmount = formatPrice(
    unitAmount || 0,
    currency as Currency,
    showTrailingDecimalZeros
  )

  if (priceDisplayInJourneys === 'show_as_starting_price') {
    return `${t('show_as_starting_price')} ${priceAmount}`
  }

  if (priceDisplayInJourneys === 'estimated_price') {
    return `${t('estimated_price')} ${priceAmount}`
  }

  return priceAmount
}

export function getPriceDisplayText({
  t,
  amount = 0,
  discountAmount,
  beforeDiscountAmountToDisplay,
  afterCashbackAmountToDisplay,
  priceDisplayInJourneys,
  showTrailingDecimalZeros = false,
  spotlightPriceAfterCashback = false,
  currency = DEFAULT_CURRENCY
}: {
  t: TFunction
  amount?: string | number
  discountAmount?: string | number
  beforeDiscountAmountToDisplay?: string | number
  afterCashbackAmountToDisplay?: string | number
  priceDisplayInJourneys?: Price['price_display_in_journeys']
  showTrailingDecimalZeros?: boolean
  spotlightPriceAfterCashback?: boolean
  currency?: string
}): {
  prefix?: string
  formattedPrice?: string
  formatedPriceDiscount?: string
  formatedPriceBeforeDiscount?: string
} {
  if (priceDisplayInJourneys === 'show_as_on_request') {
    return { prefix: t('show_as_on_request') }
  }

  const priceAmount = formatPrice(
    spotlightPriceAfterCashback && afterCashbackAmountToDisplay
      ? afterCashbackAmountToDisplay
      : amount,
    currency as Currency,
    showTrailingDecimalZeros
  )

  const priceDiscountAmount = discountAmount
    ? formatPrice(
        discountAmount,
        currency as Currency,
        showTrailingDecimalZeros
      )
    : undefined

  const priceBeforeDiscountAmount = beforeDiscountAmountToDisplay
    ? formatPrice(
        beforeDiscountAmountToDisplay,
        currency as Currency,
        showTrailingDecimalZeros
      )
    : undefined

  const prefix =
    priceDisplayInJourneys &&
    ['show_as_starting_price', 'estimated_price'].includes(
      priceDisplayInJourneys
    ) &&
    t(priceDisplayInJourneys)

  return {
    ...(prefix && { prefix }),
    formattedPrice: priceAmount,
    formatedPriceDiscount: priceDiscountAmount,
    formatedPriceBeforeDiscount: priceBeforeDiscountAmount
  }
}

type PriceType = NonNullable<Price['type']>
type RecurringPriceWithBillingPeriod = `recurring.${BillingPeriod}`

const AVAILABLE_PRICE_TYPES: Set<PriceType> = new Set(['one_time', 'recurring'])

const isValidPriceType = (priceType?: string): priceType is PriceType =>
  AVAILABLE_PRICE_TYPES.has(priceType as PriceType)

/**
 * Generates a price type string, potentially including a billing period.
 * @param {PriceType | undefined} priceType - The type of the price.
 * @param {BillingPeriod} [billingPeriod='monthly'] - The billing period.
 * @returns {PriceType | RecurringPriceWithBillingPeriod} A PriceType or a PriceType.BillingPeriod combination.
 */
export function getPriceType(
  priceType: PriceType | undefined,
  billingPeriod: BillingPeriod = 'monthly'
): 'one_time' | RecurringPriceWithBillingPeriod {
  if (!isValidPriceType(priceType) || !billingPeriod) {
    return 'one_time'
  }

  if (priceType === 'recurring') {
    return `recurring.${billingPeriod}`
  }

  return priceType
}

export const priceHasComponents = (
  price?: Price | CompositePrice
): price is HydratedCompositePrice => {
  if (!price || !isCompositePrice(price)) return false

  if (
    'price_components' in price &&
    Array.isArray(price?.price_components) &&
    price?.price_components.length
  ) {
    return true
  }

  return false
}

export const isCompositePrice = (
  price: Price | CompositePrice
): price is CompositePrice => {
  return price?.is_composite_price === true
}

export const priceItemIsComposite = (
  price: PriceItem | CompositePriceItem
): price is CompositePriceItem => {
  if (!price?.is_composite_price) return false

  if ('item_components' in price && Array.isArray(price?.item_components)) {
    return true
  }

  return false
}

export const priceItemHasComponents = (
  priceItem: PriceItem | CompositePriceItem
) => {
  return (
    priceItemIsComposite(priceItem) &&
    Boolean(priceItem.item_components?.length)
  )
}

export const getPriceMappings = (
  price?: Price | CompositePrice
): PriceInputMappings =>
  !price
    ? []
    : [
        {
          priceId: price._id,
          ...(price.blockMappingData as BlockMappingData)
        },
        ...((price.price_components as Price[]) ?? []).map(
          ({ _id: priceId, blockMappingData }) => ({
            priceId,
            ...blockMappingData
          })
        )
      ]
        .filter((mapping) => typeof mapping.numberInput === 'number')
        .map(
          ({
            priceId: price_id,
            numberInput: value,
            frequencyUnit: frequency_unit
          }) => ({ price_id, value, frequency_unit })
        )

export const getExternalFeesMappings = (price?: PriceWithBlockMappings) => {
  if (!price || (!price.getag_price && !price.dynamic_tariff_price)) {
    return []
  }

  const isComposite =
    isCompositePrice(price) && Array.isArray(price.price_components)
  const components = isComposite ? price.price_components : [price]

  if (!components) {
    return []
  }

  return (components as Price[]).map(({ _id, pricing_model, get_ag }) => {
    if (price.dynamic_tariff_price) {
      if (pricing_model === PricingModel.externalGetAG && price.getag_price) {
        return toGetAgExternalFeesMapping(
          _id,
          get_ag?.type,
          price.getag_price,
          get_ag?.tariff_type
        )
      }

      return toDynamicTariffExternalFeesMapping(_id, price.dynamic_tariff_price)
    }

    return toGetAgExternalFeesMapping(
      _id,
      get_ag?.type,
      price.getag_price,
      get_ag?.tariff_type
    )
  })
}

const toGetAgExternalFeesMapping = (
  priceId: string | undefined,
  type: TypeGetAg = 'base_price',
  getagPrice: ComputePrice,
  tariffType: 'HT' | 'NT' = 'HT'
) => {
  const amountTotal =
    type === 'work_price'
      ? tariffType === 'NT'
        ? getagPrice.amount_variable_nt
        : getagPrice.amount_variable_ht
      : getagPrice.amount_static

  const amountTotalDecimal =
    type === 'work_price'
      ? tariffType === 'NT'
        ? getagPrice.amount_variable_decimal_nt
        : getagPrice.amount_variable_decimal_ht
      : getagPrice.amount_static_decimal

  return {
    price_id: priceId,
    amount_total: amountTotal,
    amount_total_decimal: amountTotalDecimal,
    frequency_unit: getagPrice.billing_period
  }
}

const toDynamicTariffExternalFeesMapping = (
  priceId: string | undefined,
  dynamicTariffPrice: AverageMarketPrice
) => {
  return {
    price_id: priceId,
    amount_total: dynamicTariffPrice.price?.unit_amount,
    amount_total_decimal: dynamicTariffPrice.price?.unit_amount_decimal,
    frequency_unit: undefined
  }
}

export const hasPricingModel = (
  pricingModel: PricingModel,
  price: Price | CompositePrice | HydratedCompositePrice
): boolean => {
  if (!price) {
    return false
  }

  return price.pricing_model === pricingModel
}

export const hasTieredPricingModel = (
  price?: Price | CompositePrice
): price is PriceWithTieredPricingModel => {
  if (!price) return false

  return (
    hasPricingModel(PricingModel.tieredVolume, price) ||
    hasPricingModel(PricingModel.tieredGraduated, price) ||
    hasPricingModel(PricingModel.tieredFlatFee, price)
  )
}

export const getPriceDisplayInJourneys = (
  price?: Price | CompositePrice,
  tiersDisplayModes?: PriceTierDisplayMode[]
): Price['price_display_in_journeys'] => {
  if (!price) return 'show_price'

  const isPriceCompositeAndHasOnRequestComponent =
    isCompositePrice(price) &&
    Array.isArray(price.price_components) &&
    price.price_components?.some(
      (priceComponent) =>
        priceComponent.price_display_in_journeys === 'show_as_on_request' ||
        priceComponent._price?.price_display_in_journeys ===
          'show_as_on_request'
    )

  const isPriceCompositeShowAsStartingPrice =
    isCompositePrice(price) &&
    Array.isArray(price.price_components) &&
    price.price_components?.some(
      (priceComponent) =>
        priceComponent.price_display_in_journeys === 'show_as_starting_price' &&
        priceComponent._price?.price_display_in_journeys !==
          'show_as_starting_price'
    )

  const isPriceCompositeShowAsEstimatedPrice =
    isCompositePrice(price) &&
    Array.isArray(price.price_components) &&
    price.price_components?.some(
      (priceComponent) =>
        priceComponent.price_display_in_journeys === 'estimated_price' &&
        priceComponent._price?.price_display_in_journeys !== 'estimated_price'
    )

  const priceDisplayInJourneys: Price['price_display_in_journeys'] =
    isPriceCompositeAndHasOnRequestComponent
      ? 'show_as_on_request'
      : isPriceCompositeShowAsStartingPrice
        ? 'show_as_starting_price'
        : isPriceCompositeShowAsEstimatedPrice
          ? 'estimated_price'
          : price.price_display_in_journeys ||
            price._price?.price_display_in_journeys

  const hasOnRequestTier = tiersDisplayModes?.some(
    (tierDisplayMode) => tierDisplayMode === 'on_request'
  )

  if (hasOnRequestTier) {
    return 'show_as_on_request'
  }

  return priceDisplayInJourneys
}

export const computePriceDisplayInJourneys = (
  price: PriceWithBlockMappings,
  quantity: number
): Price['price_display_in_journeys'] => {
  if (!price) return 'show_price'

  if (priceHasComponents(price)) {
    const displayTiers = price.price_components
      .filter((priceComponent) => hasTieredPricingModel(priceComponent))
      .flatMap((priceComponent) => {
        const { quantityToSelectTier } = computeQuantities(
          priceComponent,
          quantity,
          {
            frequency_unit: priceComponent.blockMappingData?.frequencyUnit,
            value: priceComponent.blockMappingData?.numberInput
          }
        )

        return getDisplayTiersByQuantity(
          priceComponent.tiers || [],
          quantityToSelectTier,
          priceComponent.pricing_model
        )
      })
      .filter((tier) => Boolean(tier?.display_mode))
      .map((tier) => tier?.display_mode as PriceTierDisplayMode)

    return getPriceDisplayInJourneys(price, displayTiers) || 'show_price'
  }

  if (!hasTieredPricingModel(price)) {
    return getPriceDisplayInJourneys(price) || 'show_price'
  }

  const { quantityToSelectTier } = computeQuantities(price, quantity, {
    frequency_unit: price.blockMappingData?.frequencyUnit,
    value: price.blockMappingData?.numberInput
  })

  const matchingTiers = getDisplayTiersByQuantity(
    price.tiers || [],
    quantityToSelectTier,
    price.pricing_model
  )

  const displayTiers = matchingTiers
    ?.filter((tier) => Boolean(tier.display_mode))
    ?.map((tier) => tier.display_mode as PriceTierDisplayMode)

  return getPriceDisplayInJourneys(price, displayTiers) || 'show_price'
}

/**
 * @todo Remove logic duplication between this and getPriceDisplayText
 */
export const getPricingDetailsFormatted = (
  item: Price | CompositePrice,
  quantityValue: number,
  blockMappingValue: number,
  t: TFunction,
  showTrailingDecimalZeros = true
): {
  unitAmount: string | undefined
  unitAmountWithUnit: string | undefined
  amountTotal: string | undefined
  amountSubtotal: string | undefined
  quantity: string
  tax?: string
  unit?: string
  billingPeriod?: string
  discountAmount?: string
  beforeDiscountUnitAmount?: string
  beforeDiscountAmountTotal?: string
  unitDiscountAmount?: string
} => {
  const [mainRecurrence] = extractAndSortItemRecurrences(item)
  const {
    pricing_model: pricingModel,
    unit: unitValue,
    variable_price: variablePrice
  } = item._price || {}

  const priceDisplay = getPriceDisplayInJourneys(item)

  const frequencyUnitValue = item._price?.blockMappingData?.frequencyUnit
  const unit = formatPriceUnit(unitValue, true)
  const blockMappingValueWithUnit = [
    (blockMappingValue > 1 || unit) &&
      formatNumberToLocaleString(blockMappingValue, 2),
    unit
  ]
    .filter(Boolean)
    .join(' ')
  const frequencyUnit =
    frequencyUnitValue &&
    t(`recurring.${frequencyUnitValue}`, frequencyUnitValue)
  const quantity =
    [quantityValue > 1 && quantityValue, blockMappingValueWithUnit]
      .filter(Boolean)
      .join(' x ') + (frequencyUnit ? ` ${frequencyUnit}` : '')

  if (priceDisplay === 'show_as_on_request' || !mainRecurrence) {
    return {
      unitAmount: t('show_as_on_request'),
      unitAmountWithUnit: t('show_as_on_request'),
      amountTotal: t('show_as_on_request'),
      amountSubtotal: t('show_as_on_request'),
      quantity: quantity || '1',
      ...(unit && { unit })
    }
  }

  const billingPeriod = getBillingPeriod(
    t,
    mainRecurrence.type as any,
    mainRecurrence.billing_period
  )

  const tax = Array.isArray(item._price?.tax) && item._price.tax[0]
  const taxRate = tax?.rate && `${tax.rate}%`

  let unitAmount, unitAmountWithUnit, amountTotal, amountSubtotal

  switch (pricingModel) {
    case PricingModel.tieredVolume:
    case PricingModel.tieredFlatFee:
      ;({ unitAmount, unitAmountWithUnit, amountTotal, amountSubtotal } =
        handleTieredPricing(
          item,
          blockMappingValue,
          t,
          showTrailingDecimalZeros,
          pricingModel,
          unit
        ))
      break
    case PricingModel.tieredGraduated: {
      const result = handleGraduatedPricing(
        item,
        blockMappingValue,
        t,
        showTrailingDecimalZeros,
        unit
      )

      amountTotal = result.amountTotal
      amountSubtotal = result.amountSubtotal
      unitAmount = result.unitAmount
      unitAmountWithUnit = result.unitAmountWithUnit

      break
    }
    case PricingModel.dynamicTariff:
    case PricingModel.externalGetAG:
      ;({ unitAmount, unitAmountWithUnit, amountTotal, amountSubtotal } =
        handleExternalPricing(item, t, showTrailingDecimalZeros, unit))
      break
    default:
      ;({ unitAmount, unitAmountWithUnit, amountTotal, amountSubtotal } =
        handleDefaultPricing(
          item,
          t,
          showTrailingDecimalZeros,
          unit,
          variablePrice
        ))
      break
  }

  const {
    discountAmount,
    beforeDiscountAmountTotal,
    beforeDiscountUnitAmount,
    unitDiscountAmount
  } = getDiscountInformation({
    item: mainRecurrence,
    t,
    showTrailingDecimalZeros
  })

  return {
    unitAmount,
    unitAmountWithUnit,
    amountTotal,
    amountSubtotal,
    quantity: quantity || '1',
    ...(taxRate && { tax: taxRate }),
    ...(unit && { unit }),
    ...(billingPeriod && { billingPeriod }),
    ...(discountAmount && { discountAmount }),
    ...(beforeDiscountAmountTotal && { beforeDiscountAmountTotal }),
    ...(beforeDiscountUnitAmount && { beforeDiscountUnitAmount }),
    ...(unitDiscountAmount && { unitDiscountAmount })
  }
}

// Utility to get the billing period
const getBillingPeriod = (
  t: TFunction,
  /**
   * @todo Goes unused, remove
   */
  type: PriceType | undefined,
  billingPeriodValue: BillingPeriod | undefined
): string => {
  return type && type !== 'one_time'
    ? t(getPriceType(type, billingPeriodValue), billingPeriodValue as string)
    : ''
}

// Tiered pricing handler
const handleTieredPricing = (
  item: PriceItem | CompositePriceItem,
  quantity: number,
  t: TFunction,
  showTrailingDecimalZeros: boolean,
  pricingModel: PricingModel,
  unit: string | undefined
) => {
  const [mainRecurrence] = extractAndSortItemRecurrences(item)
  const priceDisplayInJourneys = getPriceDisplayInJourneys(item)
  const maskingRules =
    priceDisplayInJourneys && priceMaskingRules[priceDisplayInJourneys]
  const isMaskedAmount = maskingRules ? maskingRules.maskItemAmount : false

  const tier = getDisplayTierByQuantity(
    item._price?.tiers || [],
    quantity,
    pricingModel,
    item._price?.is_tax_inclusive,
    item._price?.tax
  )

  const displayableTier = {
    ...tier,
    unit_amount: tier?.unit_amount_gross,
    unit_amount_decimal: tier?.unit_amount_gross_decimal
  }
  const tierDescription = getTierDescription(
    pricingModel,
    displayableTier,
    undefined,
    navigator.language,
    item.currency as Currency,
    t,
    {
      showStartsAt:
        item._price?.price_display_in_journeys === 'show_as_starting_price',
      enableSubunitDisplay: true
    }
  )

  const unitAmount = showTrailingDecimalZeros
    ? tierDescription
    : omitTrailingDoubleDecimalZeros(tierDescription || '')

  const unitAmountWithUnit = [
    unitAmount,
    pricingModel === PricingModel.tieredVolume && unit
  ]
    .filter(Boolean)
    .join('/')

  const amountTotal = getDisplayPrice(
    t,
    mainRecurrence.amount_total,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  const amountSubtotal = getDisplayPrice(
    t,
    mainRecurrence.amount_subtotal,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  return {
    unitAmount,
    unitAmountWithUnit,
    amountTotal,
    amountSubtotal
  }
}

// Graduated pricing handler
const handleGraduatedPricing = (
  item: PriceItem | CompositePriceItem,
  quantity: number,
  t: TFunction,
  showTrailingDecimalZeros: boolean,
  unit: string | undefined
) => {
  const [mainRecurrence] = extractAndSortItemRecurrences(item)
  const priceDisplayInJourneys = getPriceDisplayInJourneys(item)
  const maskingRules =
    priceDisplayInJourneys && priceMaskingRules[priceDisplayInJourneys]
  const isMaskedAmount = maskingRules ? maskingRules.maskItemAmount : false

  const tax = Array.isArray(item._price?.tax) && item._price.tax[0]
  const computedValues = computeCumulativeValue(
    item._price?.tiers,
    quantity,
    undefined,
    navigator.language,
    item._price?.unit_amount_currency as Currency,
    t,
    item._price?.is_tax_inclusive,
    {},
    tax || undefined
  )

  const unitAmount = `${t('Average', 'Average')} ${
    typeof computedValues === 'string'
      ? computedValues
      : computedValues?.average
  }`

  const unitAmountWithUnit = [unitAmount, unit].filter(Boolean).join('/')
  const amountTotal = getDisplayPrice(
    t,
    mainRecurrence.amount_total,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  const amountSubtotal = getDisplayPrice(
    t,
    mainRecurrence.amount_subtotal,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  return {
    unitAmount,
    unitAmountWithUnit,
    amountTotal,
    amountSubtotal
  }
}

// External AG pricing handler
const handleExternalPricing = (
  item: PriceItem | CompositePriceItem,
  t: TFunction,
  showTrailingDecimalZeros: boolean,
  unit: string | undefined
) => {
  const [mainRecurrence] = extractAndSortItemRecurrences(item)
  const priceDisplayInJourneys = getPriceDisplayInJourneys(item)
  const maskingRules =
    priceDisplayInJourneys && priceMaskingRules[priceDisplayInJourneys]
  const isMaskedAmount = maskingRules ? maskingRules.maskItemAmount : false

  const unitAmount = getDisplayPrice(
    t,
    item?.unit_amount_gross_decimal,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency
  )

  const unitAmountWithUnit = [
    unitAmount,
    (item._price?.get_ag?.type === 'work_price' ||
      item._price?.dynamic_tariff) &&
      unit
  ]
    .filter(Boolean)
    .join('/')

  const amountTotal = getDisplayPrice(
    t,
    mainRecurrence.amount_total,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  const amountSubtotal = getDisplayPrice(
    t,
    mainRecurrence.amount_subtotal,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  return {
    unitAmount,
    unitAmountWithUnit,
    amountTotal,
    amountSubtotal
  }
}

// Default pricing handler
const handleDefaultPricing = (
  item: PriceItem | CompositePriceItem,
  t: TFunction,
  showTrailingDecimalZeros: boolean,
  unit: string | undefined,
  variablePrice: boolean | undefined
) => {
  // Totals and Discounts should be inferred from the main recurrence
  const [mainRecurrence] = extractAndSortItemRecurrences(item)

  const priceDisplayInJourneys = getPriceDisplayInJourneys(item)
  const maskingRules =
    priceDisplayInJourneys && priceMaskingRules[priceDisplayInJourneys]
  const isMaskedAmount = maskingRules ? maskingRules.maskItemAmount : false

  const unitAmount = getDisplayPrice(
    t,
    item.unit_amount_gross_decimal,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  const unitAmountWithUnit = [unitAmount, variablePrice && unit]
    .filter(Boolean)
    .join('/')

  const amountTotal = getDisplayPrice(
    t,
    shouldShowDecimals(mainRecurrence.amount_total)
      ? mainRecurrence.amount_total_decimal
      : mainRecurrence.amount_total,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  const amountSubtotal = getDisplayPrice(
    t,
    shouldShowDecimals(mainRecurrence.amount_subtotal)
      ? mainRecurrence.amount_subtotal_decimal
      : mainRecurrence.amount_subtotal,
    isMaskedAmount ? priceDisplayInJourneys : undefined,
    showTrailingDecimalZeros,
    item.currency as Currency
  )

  return {
    unitAmount,
    unitAmountWithUnit,
    amountTotal,
    amountSubtotal
  }
}

const getDiscountInformation = ({
  item,
  t,
  showTrailingDecimalZeros
}: {
  item: PriceItem | CompositePriceItem
  t: TFunction
  showTrailingDecimalZeros: boolean
}) => {
  const discountAmount =
    item.discount_amount &&
    `${getDisplayPrice(
      t,
      shouldShowDecimals(item.discount_amount)
        ? item.discount_amount_decimal
        : item.discount_amount,
      item._price?.price_display_in_journeys,
      showTrailingDecimalZeros,
      item.currency as Currency
    )}`

  const unitDiscountAmount =
    typeof item.unit_discount_amount === 'number'
      ? getDisplayPrice(
          t,
          shouldShowDecimals(item.unit_discount_amount)
            ? /**
               * @todo It seems before_discount_unit_amount_decimal is missing from pricing lib, should be added
               */
              item.unit_discount_amount_decimal
            : item.unit_discount_amount,
          item._price?.price_display_in_journeys,
          showTrailingDecimalZeros,
          item.currency as Currency
        )
      : undefined

  const beforeDiscountAmountTotal =
    item.before_discount_amount_total &&
    `${getDisplayPrice(
      t,
      shouldShowDecimals(item.before_discount_amount_total)
        ? item.before_discount_amount_total_decimal
        : item.before_discount_amount_total,
      undefined,
      showTrailingDecimalZeros,
      item.currency as Currency
    )}`

  const beforeDiscountUnitAmount =
    typeof item.before_discount_unit_amount === 'number'
      ? getDisplayPrice(
          t,
          shouldShowDecimals(item.before_discount_unit_amount)
            ? /**
               * @todo It seems before_discount_unit_amount_decimal is missing from pricing lib, should be added
               */
              item.before_discount_unit_amount
            : item.before_discount_unit_amount,
          undefined,
          showTrailingDecimalZeros,
          item.currency as Currency
        )
      : undefined

  return {
    discountAmount,
    beforeDiscountAmountTotal,
    beforeDiscountUnitAmount,
    unitDiscountAmount
  }
}

export const formatNumberToLocaleString = (
  num: number | string | undefined,
  decimalPlaces = 10
) => {
  const parsedNumber = Number(num)

  if (isNaN(parsedNumber)) return num

  return parsedNumber.toLocaleString(navigator.language || 'de-DE', {
    maximumFractionDigits: decimalPlaces
  })
}

export const computePricingDetails = ({
  price,
  quantity = 1,
  overrides,
  externalCatalogData,
  redeemedPromos
}: {
  price: PriceWithBlockMappings
  quantity?: number
  overrides?: {
    /**
     * @todo Move blockMappings outside of overrides
     */
    blockMappings?: Price['price_mappings']
  }
  externalCatalogData?: ProductTileData['externalCatalogData']
  redeemedPromos: Array<RedeemedPromo>
}) => {
  const blockMappings = overrides?.blockMappings || getPriceMappings(price)

  const externalFeesMappings = getExternalFeesMappings(price)

  const totals = computeAggregatedAndPriceTotals(
    [
      {
        ...price,
        _immutable_pricing_details: externalCatalogData?.pricing_details,
        _price: price,
        quantity,
        ...(blockMappings.length > 0 && { price_mappings: blockMappings }),
        ...(externalFeesMappings.length > 0 && {
          external_fees_mappings: externalFeesMappings
        })
      }
    ],
    { redeemedPromos }
  )

  return {
    pricingDetails: totals,
    blockMappings,
    externalFeesMappings
  }
}

export const findByBillingPeriodAndPriceDisplayInJourneys = (
  item: Price,
  billingPeriod: BillingPeriod | undefined,
  priceDisplayInJourneys: Price['price_display_in_journeys'][]
) => {
  return (
    (item.type === 'one_time' || item.billing_period === billingPeriod) &&
    priceDisplayInJourneys.includes(item.price_display_in_journeys)
  )
}

export const checkPriceDisplayInJourneys = (
  items: PriceItemWithBlockConfiguration[],
  billingPeriod: BillingPeriod | undefined,
  priceDisplayInJourneys: Price['price_display_in_journeys'][]
) => {
  return (items || []).some((item) => {
    const flatPrice = [item?._price, ...(item?._price?.price_components || [])]

    return flatPrice.some((priceComponent: Price) =>
      findByBillingPeriodAndPriceDisplayInJourneys(
        priceComponent,
        billingPeriod,
        priceDisplayInJourneys
      )
    )
  })
}

export const getIsProvisional = (
  items: PriceItemWithBlockConfiguration[],
  billingPeriod?: BillingPeriod
) =>
  checkPriceDisplayInJourneys(items, billingPeriod, [
    'show_as_on_request',
    'show_as_starting_price'
  ])

export const getIsEstimated = (
  items: PriceItemWithBlockConfiguration[],
  billingPeriod?: BillingPeriod
) => checkPriceDisplayInJourneys(items, billingPeriod, ['estimated_price'])

export const getIsOnRequest = (
  items: PriceItemWithBlockConfiguration[],
  billingPeriod?: BillingPeriod
) => checkPriceDisplayInJourneys(items, billingPeriod, ['show_as_on_request'])

export const getIsOneTimeOnly = (recurrences: RecurrenceAmount[]): boolean =>
  !!recurrences.every(
    (recurrence) => recurrence.type === 'one_time' || !recurrence.type
  )

const capitalizePriceLabelFirstLetter = (label: string) => {
  if (!label) return label

  return label.charAt(0).toUpperCase() + label.slice(1)
}

export const getLabel = (
  recurrence: RecurrenceAmount,
  isProvisional: boolean,
  isEstimated: boolean,
  isOneTimeOnly: boolean,
  t: TFunction
) => {
  const maskLabel = isProvisional
    ? t('provisional', 'Provisional')
    : isEstimated
      ? t('estimated', 'Estimated')
      : null

  if (isOneTimeOnly && (recurrence.type === 'one_time' || !recurrence.type)) {
    const translatedLabel = maskLabel
      ? t('totalWithMask', { mask: maskLabel })
      : t('total', 'Total')

    return capitalizePriceLabelFirstLetter(translatedLabel)
  }

  const priceType = getPriceType(
    recurrence.type as Price['type'],
    recurrence.billing_period as BillingPeriod
  )

  const translatedLabel = maskLabel
    ? t(`${priceType}WithMask`, { mask: maskLabel })
    : t(priceType, priceType)

  return capitalizePriceLabelFirstLetter(translatedLabel)
}

export const getValue = (
  recurrence: RecurrenceAmount,
  t: TFunction,
  currency: Currency,
  showTrailingDecimalZeros?: boolean
) => {
  return getDisplayPrice(
    t,
    shouldShowDecimals(recurrence.amount_total)
      ? recurrence.amount_total_decimal
      : recurrence.amount_total,
    undefined,
    showTrailingDecimalZeros,
    currency
  )
}

export const getComputedMappingDataGraduated = ({
  price,
  quantity,
  showTrailingDecimalZeros,
  t
}: {
  price: Price | undefined
  quantity: number | undefined
  showTrailingDecimalZeros: boolean
  t: TFunction
}) => {
  if (!price) {
    return null
  }

  const { quantityToSelectTier } = computeQuantities(
    price,
    quantity as number,
    {
      frequency_unit: price?.blockMappingData?.frequencyUnit,
      value: price?.blockMappingData?.numberInput
    }
  )

  const tax = Array.isArray(price.tax) && price.tax[0]

  const computedValues = computeCumulativeValue(
    price?.tiers,
    quantityToSelectTier,
    price?.unit,
    navigator.language,
    price.unit_amount_currency as Currency,
    (key: string) => t(key),
    price?.is_tax_inclusive,
    {},
    tax || undefined
  )

  if (!computedValues || typeof computedValues === 'string') {
    return null
  }

  const unit = formatUnitWithSuffix(price?.unit, quantityToSelectTier, t)
  const formattedNormalizedQuantity = `${formatNumberToLocaleString(
    quantityToSelectTier,
    2
  )} ${unit}`

  const { totalWithPrecision, breakdown } = computedValues

  const formattedTotalWithPrecision = showTrailingDecimalZeros
    ? totalWithPrecision
    : omitTrailingDoubleDecimalZeros(totalWithPrecision)

  return {
    computedValues,
    quantityToSelectTier,
    formattedNormalizedQuantity,
    formattedTotalWithPrecision,
    breakdown,
    price
  }
}
