import { BodyText, Grid, SmallText } from '@epilot/journey-elements'

export type AttributeItemProps = {
  label: string
  value?: string
  slug?: string
  displayMode?: 'simple' | 'table'
}

export const SimpleAttributeItem = ({
  label,
  value,
  displayMode
}: AttributeItemProps) => {
  return displayMode === 'simple' ? (
    <BodyText>
      {label}: {value}
    </BodyText>
  ) : (
    <Grid item sm={4} xs={12}>
      <span>
        <SmallText>{(label || '').toUpperCase()}</SmallText>
      </span>
      <span>
        <BodyText>
          <strong>{value || '-'}</strong>
        </BodyText>
      </span>
    </Grid>
  )
}
