import { AuthServiceError } from './AuthServiceError'

export class InvalidChallengeError extends AuthServiceError {
  public readonly attemptsLeft: number
  constructor(attemptsLeft: number) {
    super('Failed to answer challenge', 'INVALID_CHALLENGE')
    this.name = 'InvalidChallengeError'
    this.attemptsLeft = attemptsLeft
  }
}
