import conf from '../.env-cmdrc.json'
import type { ImportMetaEnvExtended, RuntimeEnv } from '../types/env.ts'

let env: RuntimeEnv & ImportMetaEnvExtended = {
  ...import.meta.env,
  ...window.journeyAppConfig
}

/**
 * As a quick and dirty fallback for the local development build,
 * we simply inject the variables into the env after reading directly from the file system.
 */
if (process.env.NODE_ENV === 'development') {
  const envConfig = conf.local

  env = { ...env, ...envConfig }
}

if (process.env.NODE_ENV === 'staging-test') {
  const envConfig = conf.staging

  env = { ...env, ...envConfig }
}

export { env }
