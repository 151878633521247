import type { ControlProps, JsonSchema7 } from '@jsonforms/core'

export type AutoFillPIOptions = {
  isAutoFillPIEnabled?: boolean
  autoFillPISettings?: {
    relatedBlock?: string // format: stepId/blockName
  }
}

export type IconConfig = {
  name?: string
  settings?: IconSettingsData
  color?: 'primary' | 'secondary' | 'default' | 'error'
}

export type IconSettingsData = {
  fill?: boolean
  variant?: 'rounded' | 'sharp' | 'outlined'
  size?: number
  grade?: string
  weight?: string
}

export type GoogleMapsIntegrationOptions = {
  isGoogleMapsEnabled?: boolean
  isRepositioningAllowed?: boolean
}

export enum ParagraphBlockStringType {
  PLAIN_TEXT = 'plain_text',
  BASE64_MARKDOWN = 'base64_markdown',
  BASE64_LEXICAL = 'base64_lexical',
  MARKDOWN = 'markdown',
  LEXICAL = 'lexical'
}

// serialized block summary config
// DISPLAY/STEP_INDEX/ORIGINAL_NAME/CUSTOM_NAME
export type SerializedBlockSummary = `${'true' | 'false'}/${string}/${string}/${
  | string
  | undefined}`

export type SummaryField = {
  originalBlockName: string
  stepName: string
  customDisplayname?: string
  display: boolean
  stepIndex: number
  isDefaultHidden?: boolean
}

export type LayoutOptions = {
  [size in SizeKey]: GridSize | boolean
}

type SizeKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type GridSize = 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type FieldOption<T = string> = {
  label?: string
  errorMessage?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  defaultValue?: T
}

export type FieldOptionDate = Omit<
  FieldOption<Date | undefined>,
  'required'
> & {
  prefillByDays?: number // now + days -> value
  limits?: {
    minByDays?: number // now + days -> minDate
    maxByDays?: number // now + days -> maxDate
    disablePast?: boolean // disablePast
  }
  defaultTime?: string
}

export type DateLimits = {
  minByDays?: number
  maxByDays?: number
  disablePast?: boolean
}

export type FieldOptionNumber = FieldOption & {
  required?: boolean
  frequencyUnit?: string
  unit?: {
    show?: boolean
    label?: string
  }
  format?: {
    show?: boolean
    validate?: boolean
    digitsBeforeDecimalPoint?: number
    decimalPlaces?: number
    autoFormat?: boolean
  }
  range?: {
    min?: number
    max?: number
  }
}

export type EpilotControlProps = ControlProps & {
  /**
   * json schema, here it is enforced to be version 7
   */
  schema: JsonSchema7

  disabledFields?: string[]

  /**
   * Existing id of the control
   */
  id?: string
}

export enum DesignBuilderUpdateEvent {
  UPDATE_THEME = 'JourneyUpdateThemeEvent'
}

export type EventDetailType<Payload = unknown> = {
  type?: DesignBuilderUpdateEvent.UPDATE_THEME
  payload: Payload
}

export type JourneyNavigationEventPayload = {
  stepIndex: number
}
