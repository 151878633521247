import { ID_DELIMITER } from '@epilot/journey-logic-commons'
import { validate } from 'uuid'

const ID_CHARACTERS_LIMIT = 12

export enum ID_TYPE {
  STEP = 'Concorde-Step',
  BLOCK = 'Concorde-Block'
}

/**
 * Converts a UUID into a valid HTML element ID.
 */
export function uuidToHtmlId(type: ID_TYPE, uuid?: string) {
  if (!uuid) return undefined
  if (!validate(uuid)) {
    // Remove ids that are based on generic step names
    if (
      ['New step', 'Neuer Schritt'].filter((stepName) =>
        uuid.includes(stepName)
      ).length > 0
    ) {
      return undefined
    }
    const id = uuid
      .trim() // remove leading and trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/-/g, '') // remove hyphens
      .replace(/\s+/g, '_') // replace spaces with underscores
      .replace(/[^a-z0-9_]/g, '') // remove any remaining special characters
      .substring(0, ID_CHARACTERS_LIMIT)

    return `${type}${ID_DELIMITER}${id}`
  }

  // Remove hyphens from the UUID.
  const id = uuid.replace(/-/g, '').substring(0, ID_CHARACTERS_LIMIT)

  return `${type}${ID_DELIMITER}${id}`
}
