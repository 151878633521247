import { useIsMobileOrTablet } from '@epilot/concorde-elements'
import {
  AppBar,
  Toolbar,
  useTheme,
  IconButton,
  Icon,
  rgbToHex
} from '@epilot/journey-elements'
import type { Journey } from '@epilot/journey-logic-commons'
import { JOURNEY_SAVING_PROGRESS_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { memo, type CSSProperties } from 'react'

import { getStorageMode } from '../../blocks-renderers'
import { UserSessionIcon } from '../UserSessionIcon'
import { getIconColor } from '../utils'

import classes from './TopBar.module.scss'

export type TopBarProps = {
  /**
   * Logo to be shown.
   */
  logoUrl?: string
  /**
   * Back button callback.
   */
  onBack?: () => void
  /**
   * Close button callback.
   */
  onClose?: () => void
  /**
   * Whether to show the top bar or not
   */
  isTopBarEnabled: boolean
  /**
   * Whether to attach the backButton to the progress bar
   */
  isProgressBar?: boolean

  /**
   * The journey object.
   */
  journey: Journey

  /**
   * The current step index.
   */
  stepIndex?: number
  showBackButton?: boolean
}

export const TopBar = memo((props: TopBarProps) => {
  const {
    logoUrl,
    onClose,
    onBack,
    isTopBarEnabled,
    isProgressBar,
    journey,
    stepIndex
  } = props
  const theme = useTheme()
  const isMobileOrTablet = useIsMobileOrTablet()

  const topbarBackgroundColor =
    (theme?.overrides?.MuiAppBar?.colorPrimary as CSSProperties)
      ?.backgroundColor || theme.palette.primary.main

  const rawBackgroundColor = ('' + rgbToHex(topbarBackgroundColor)).slice(0, 7)

  const backgroundColor = rawBackgroundColor
    ? rawBackgroundColor.length > 7
      ? parseInt(rawBackgroundColor.slice(-2), 16) > 60
        ? rawBackgroundColor.slice(0, -2)
        : '#ffffff'
      : rawBackgroundColor
    : rgbToHex(theme.palette.primary.main).slice(0, 7)

  const iconColor = getIconColor({
    backgroundColor,
    mainColor: theme.palette.primary.main,
    rawColor: topbarBackgroundColor
  })

  if (!isTopBarEnabled && onBack) {
    if (isProgressBar) {
      return (
        // this inline style is used for inline journeys
        <IconButton onClick={onBack} style={{ position: 'static' }}>
          <Icon customColor={iconColor} name="arrow_back" />
        </IconButton>
      )
    } else if (!isMobileOrTablet) {
      return (
        // this inline style is used for inline journeys
        <IconButton onClick={onBack} style={{ position: 'static' }}>
          <Icon
            className={classes.arrow}
            customColor={theme.palette.primary.main}
            name="arrow_back"
          />
        </IconButton>
      )
    }
  }

  const sessionStorageMode = getStorageMode(
    journey.settings?.savingProgress?.mode,
    journey.settings?.accessMode
  )

  if (!isTopBarEnabled) return null

  return (
    <AppBar color="primary" elevation={1} position="static" square>
      <Toolbar className={classes.toolbar} variant="dense">
        {onBack ? (
          <IconButton onClick={onBack}>
            <Icon
              className={classes.arrow}
              customColor={iconColor}
              name="arrow_back"
            />
          </IconButton>
        ) : (
          <div className={classes.dummyDiv} /* to align the logo */ />
        )}

        {logoUrl && <img alt="Logo" className={classes.logo} src={logoUrl} />}
        {onClose ? (
          <span>
            {sessionStorageMode !== JOURNEY_SAVING_PROGRESS_MODE.NONE && (
              <UserSessionIcon
                iconColor={iconColor}
                mode={sessionStorageMode}
                stepIndex={stepIndex}
              />
            )}
            <IconButton onClick={onClose}>
              <Icon customColor={iconColor} name="close" />
            </IconButton>
          </span>
        ) : (
          <div></div>
        )}
      </Toolbar>
    </AppBar>
  )
})

TopBar.displayName = 'TopBar'
