import type { UploadFileHandlerCallback } from '@epilot/journey-logic-commons'

import { FileUploadStatus } from './types'

// TODO: Create generic factory to generate "is" maps from enums
export const isUploadStatus = (
  status: FileUploadStatus | undefined
): Record<keyof typeof FileUploadStatus, boolean> => {
  return Object.entries(FileUploadStatus).reduce(
    (acc, [key, value]) => {
      acc[key as keyof typeof FileUploadStatus] = value === status

      return acc
    },
    {} as ReturnType<typeof isUploadStatus>
  )
}

export const withUploadProgress = (
  uploadFileHandler: UploadFileHandlerCallback,
  file: File,
  onUploadProgress: (progress?: ProgressEvent) => void,
  signal?: AbortSignal
) => {
  const config = {
    onUploadProgress: onUploadProgress,
    signal
  }

  const data = new FormData()

  data.append('file', file)

  return uploadFileHandler(data, config)
}
