import { H5, LargeText, Typography, clsx } from '@epilot/journey-elements'
import type { StepExtended } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { useIsMobile } from '../../../utils/tools'
import { useWrapperStyles } from '../styles'

import classes from './StepComponent.module.scss'

const RequiredLabel = () => {
  const { t } = useTranslation()

  return (
    <Typography
      className={classes.label}
      color="textSecondary"
      variant="caption"
    >
      {'* ' + t('input_required', 'Pflichteingabe')}
    </Typography>
  )
}

export const StepHeader = ({
  step,
  hasRequiredField
}: {
  step: StepExtended
  hasRequiredField: boolean
}) => {
  const mobile = useIsMobile()
  const { wrapperStyles } = useWrapperStyles({
    maxWidth: 1256
  })

  return (
    <div className={wrapperStyles}>
      <div
        className={
          step.showStepName || step.showStepSubtitle
            ? classes.titleContainer
            : ''
        }
      >
        <div
          className={clsx(classes.labelContainer, {
            [classes.spaceBetween]: step.showStepName,
            [classes.flexEnd]: !step.showStepName
          })}
        >
          {step.showStepName && (
            <div className={mobile ? '' : classes.desktopStepNameContainer}>
              <H5 color="textPrimary" gutterBottom={!!step.showStepSubtitle}>
                <b
                  className={
                    mobile ? classes.mobileStepName : classes.desktopStepName
                  }
                >
                  {step.title === undefined ? step.name : step.title}
                </b>
              </H5>
            </div>
          )}
          {!mobile && hasRequiredField && <RequiredLabel />}
        </div>

        {step.showStepSubtitle && (
          <div
            className={
              mobile
                ? classes.mobileSubtitleContainer
                : classes.desktopSubtitleContainer
            }
          >
            <LargeText>
              <span
                className={
                  mobile ? classes.mobileSubtitle : classes.desktopSubtitle
                }
              >
                {step.subTitle}
              </span>
            </LargeText>
          </div>
        )}
      </div>
      {mobile && hasRequiredField && (
        <div className={classes.requiredLabelContainer}>
          <RequiredLabel />
        </div>
      )}
    </div>
  )
}
