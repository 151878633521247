import { Typography, Spacing } from '@epilot/concorde-elements'
import type {
  PaymentConditionItem,
  ProductDetailsData,
  File
} from '@epilot/journey-logic-commons'
import type { Coupon } from '@epilot/pricing-client'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ProductCoupon } from '../../components/ProductCoupon'
import { ProductFeatures } from '../../components/ProductFeatures'

import { MarkdownRenderer } from './components/MarkdownRenderer'
import { ProductDetailsChart } from './components/ProductDetailsChart'
import { ProductDetailsDownloads } from './components/ProductDetailsDownloads'
import { ProductDetailsImage } from './components/ProductDetailsImage'
import { ProductDetailsPaymentConditions } from './components/ProductDetailsPaymentConditions'
import { ProductDetailsPriceInformation } from './components/ProductDetailsPriceInformation'
import classes from './ProductDetailsContent.module.scss'

export type ProductDetailsContentConfig = {
  showFeatures?: boolean
  showChart?: boolean
  showPriceInformation?: boolean
  showConditions?: boolean
  showDownloads?: boolean
  showNotes?: boolean
  showLegalFootnote?: boolean
}

const defaultConfig: ProductDetailsContentConfig = {
  showFeatures: true,
  showChart: true,
  showPriceInformation: true,
  showConditions: true,
  showDownloads: true,
  showNotes: true,
  showLegalFootnote: true
}

type ProductDetailsContentProps = {
  data: ProductDetailsData
  conditions: PaymentConditionItem[]
  downloads: File[]
  config?: ProductDetailsContentConfig
}

export const ProductDetailsContent = (props: ProductDetailsContentProps) => {
  const { data, conditions, downloads, config } = props
  const { t } = useTranslation()

  const {
    product,
    coupons,
    price,
    pricingDetails,
    externalCatalogData,
    displayUnitaryAverage,
    showTrailingDecimalZeros
  } = data
  const {
    name,
    description,
    additional_notes: productAdditionalNotes
  } = product
  const { additional_information: priceAdditionalInformation } = price

  const mergedConfig = { ...defaultConfig, ...config }

  return (
    <Spacing className={classes.container} scale={4} variant="stack">
      <div className={classes.detailsGrid}>
        <div className={classes.top}>
          <div className={classNames(classes.box, classes.imageContainer)}>
            <ProductDetailsImage product={product} />
          </div>
          {name && (
            <Spacing
              alignItems={'center'}
              className={classes.heading}
              justifyContent={'flex-start'}
              scale={4}
              variant="inline"
            >
              <Typography as="h2" className={classes.productName}>
                {name}
              </Typography>
              {coupons &&
                coupons.length > 0 &&
                coupons.map((coupon: Coupon) => (
                  <ProductCoupon
                    coupon={coupon}
                    isStatic={true}
                    key={coupon._id}
                  />
                ))}
            </Spacing>
          )}
          {mergedConfig.showFeatures && (
            <div
              className={classNames(classes.box, classes.descriptionContainer)}
            >
              {description && (
                <div id="product-details-dialog-description" tabIndex={-1}>
                  <Typography as="p">{description}</Typography>
                </div>
              )}
              <ProductFeatures product={product} showIcon={true} />
            </div>
          )}
        </div>

        {mergedConfig.showPriceInformation && (
          <div
            className={classNames(
              classes.box,
              classes.priceInformationContainer
            )}
          >
            <Typography
              as="h6"
              className={classes.heading}
              id="price-information-heading"
            >
              {t(
                'product_details.headings.price_information',
                'Price Information'
              )}
            </Typography>
            {price && pricingDetails && (
              <div>
                <ProductDetailsPriceInformation
                  blockMappings={price.price_mappings}
                  displayUnitaryAverage={displayUnitaryAverage}
                  externalCatalogData={externalCatalogData}
                  item={price}
                  pricingDetails={pricingDetails}
                  showTrailingDecimalZeros={showTrailingDecimalZeros}
                />
              </div>
            )}
          </div>
        )}
        {mergedConfig.showConditions &&
          !!(conditions.length || priceAdditionalInformation) && (
            <div className={classNames(classes.box, classes.infoContainer)}>
              <Typography as="h6" className={classes.heading}>
                {t(
                  'product_details.headings.conditions_and_information',
                  'Additional Information'
                )}
              </Typography>
              {!!priceAdditionalInformation && (
                <div>
                  <Typography as="p" variant="secondary">
                    <MarkdownRenderer str={priceAdditionalInformation} />
                  </Typography>
                </div>
              )}
              {!!conditions.length && (
                <ProductDetailsPaymentConditions conditions={conditions} />
              )}
            </div>
          )}
        {mergedConfig.showDownloads && !!downloads.length && (
          <div className={classes.box}>
            <Typography as="h6" className={classes.heading}>
              {t('product_details.headings.downloads', 'Downloads')}
            </Typography>
            <ProductDetailsDownloads downloads={downloads} />
          </div>
        )}
        {mergedConfig.showNotes && productAdditionalNotes && (
          <div className={classNames(classes.box, classes.notesContainer)}>
            <MarkdownRenderer str={productAdditionalNotes} />
          </div>
        )}
      </div>
      {mergedConfig.showChart && (
        <div className={classes.chartContainer}>
          <ProductDetailsChart price={price} />
        </div>
      )}
      {mergedConfig.showLegalFootnote && product.legal_footnote && (
        <div className={classes.legalContainer}>
          <Typography as="p">
            <MarkdownRenderer str={product.legal_footnote} />
          </Typography>
        </div>
      )}
    </Spacing>
  )
}
