import { Icon, Link, Spacing } from '@epilot/concorde-elements'
import type { File } from '@epilot/journey-logic-commons'
import { getPublicUrl } from '@epilot/journey-logic-commons'

import classes from './ProductDetailsDownloads.module.scss'

type ProductDetailsDownloadsProps = {
  downloads: File[]
}

export const ProductDetailsDownloads = ({
  downloads
}: ProductDetailsDownloadsProps) => {
  return (
    <Spacing scale={4} variant="inline">
      {downloads.map((download) => (
        <Link
          className={classes.downloadLink}
          href={getPublicUrl(download)}
          key={download._id}
          target="_blank"
        >
          <Icon name="download" />
          {download.filename}
        </Link>
      ))}
    </Spacing>
  )
}
