import type { CompositePrice, Price } from '@epilot/journey-logic-commons'
import { PricingModel } from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { ComponentMappingDataGraduated } from '../ComponentMappingDataGraduated'

type PriceComponentDetailsProps = {
  component: Price | CompositePrice
  unitAmountWithUnit?: string
  unitAmount?: string
  amountTotal?: string
  displayUnitaryAverage?: boolean
  showTrailingDecimalZeros?: boolean
  quantity: number
  formattedQuantity?: string
  t: TFunction
}

export const PriceComponentDetails = ({
  component,
  unitAmount,
  unitAmountWithUnit,
  amountTotal,
  displayUnitaryAverage,
  showTrailingDecimalZeros = false,
  quantity,
  formattedQuantity,
  t
}: PriceComponentDetailsProps) => {
  const showQuantity =
    component.pricing_model !== PricingModel.externalGetAG ||
    component._price?.get_ag?.type === 'work_price'
      ? formattedQuantity
      : ''

  const showFormattedQuantity = showQuantity && formattedQuantity
  const showUnitAmount =
    showFormattedQuantity || !!component._price?.variable_price || quantity > 1

  return (
    <>
      {component.pricing_model === PricingModel.tieredGraduated ? (
        <ComponentMappingDataGraduated
          amountTotal={amountTotal}
          displayUnitaryAverage={displayUnitaryAverage ?? true}
          formattedQuantity={formattedQuantity}
          price={component._price as Price}
          quantity={quantity}
          showTrailingDecimalZeros={Boolean(showTrailingDecimalZeros)}
          t={t}
          unitAmount={unitAmount}
          unitAmountWithUnit={unitAmountWithUnit}
        />
      ) : (
        <>
          {`${showFormattedQuantity ? `${formattedQuantity} | ` : ''}
           ${showUnitAmount ? unitAmountWithUnit : ''}`}
        </>
      )}
    </>
  )
}
