export enum JourneyFeatureFlags {
  JOURNEY_DATE_REWORK = 'journey-date-rework',
  JOURNEY_ANALYTICS = 'journey-analytics',
  PRODUCT_NUMBER_INJECTION = 'journey-product-number-injection',
  JOURNEY_CONTEXT_MENTON_FF = 'journey-context-mention',
  ACTIVE_JOURNEY = 'journey-publish',
  USER_PROGRESS = 'journey-user-progress',
  INCLUDE_PRODUCT_BLOCKS_IN_SUMMARY = 'journey-builder-product-blocks-in-summary',
  DYNAMIC_TARIFFS = 'journey-market-data-chart',
  CONCORDE_SHOPPING_CART = 'journey-concorde-shopping-cart',
  CONCORDE_TRANSPARENT_PRODUCT_TILE = 'journey-transparent-product-tile',
  CUSTOM_CSS = 'journey-custom-css',
  CUSTOM_CSS_TESTING = 'journey-custom-css-override',
  CONCORDE_PRODUCT_DETAILS = 'journey-concorde-product-details',
  PREFETCH_INTERNAL_PRODUCTS = 'journey-prefetch-internal-products',
  PRICE_COMPOSITE_CONDITIONS = 'journeys-price-composite-conditions',
  HIDE_CHECKMARK = 'journey-use-new-background',
  CONCORDE_JOURNEY_LAUNCHER = 'journey-launcher-concorde',
  JOURNEY_STEP_WIDTH_CONFIGURATOR = 'journey-step-width-configurator',
  SIMPLIFIED_JOURNEY_LAUNCHER = 'journey-launcher-simplified'
}
