import { Spacing, Typography } from '@epilot/concorde-elements'
import {
  type PriceItem,
  getLineDiscountDetails
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'
import { useMemo } from 'react'

import classes from './PriceComponents.module.scss'

type CartItemDetailsLineDiscountProps = {
  item: PriceItem
  t: TFunction
  showTrailingDecimalZeros?: boolean
}

export const PriceComponentDiscount = ({
  item,
  t,
  showTrailingDecimalZeros
}: CartItemDetailsLineDiscountProps) => {
  const { discountAmountToDisplay, beforeDiscountAmountTotalToDisplay } =
    useMemo(
      () => getLineDiscountDetails(item, t, showTrailingDecimalZeros),
      [item, showTrailingDecimalZeros, t]
    )

  return (
    <Spacing variant={'inline'}>
      {beforeDiscountAmountTotalToDisplay !== undefined && (
        <Typography
          className={classes.priceBeforeDiscount}
          variant={'secondary'}
        >
          {beforeDiscountAmountTotalToDisplay}
        </Typography>
      )}
      {discountAmountToDisplay !== undefined && (
        <Typography className={classes.discount} variant={'secondary'}>
          -{discountAmountToDisplay}
        </Typography>
      )}
    </Spacing>
  )
}
