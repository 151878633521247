import { Button, Typography, Icon } from '@epilot/concorde-elements'
import { clsx } from '@epilot/journey-logic-commons'
import type { MouseEventHandler } from 'react'
import { memo } from 'react'

import classes from './NotFoundError.module.scss'
import type { NotFoundErrorProps } from './types'

export const NotFoundError = memo((props: NotFoundErrorProps) => {
  // determines whether the journey can be closed
  const canClose = props.isEmbedded && Boolean(props.onClose)

  const onClose: MouseEventHandler = () => {
    try {
      canClose && props.onClose?.()
    } catch (originalError) {
      const error = new Error('Failed to close the journey')

      error.cause = originalError

      throw error
    }
  }

  // inline styles should be ok here, this component will block all other processes
  return (
    <div
      className={clsx(
        classes.container,
        props.embedMode === 'inline'
          ? classes.inlineEmbed
          : classes.fullscreenEmbed
      )}
    >
      <Typography as="h4" className={classes.heading}>
        <Icon isFilled name="warning" size="48px" />
        {props.i18n?.heading ??
          "Oops! We can't find the page you're looking for."}
      </Typography>
      <Typography>
        {props.i18n?.content ??
          'Looks like you have followed a broken link or entered a URL that does not exist on this site'}
      </Typography>
      {canClose && (
        // shown only on full-screen mode, doesn't make sense for inline embeds
        <Button color="inherit" onClick={onClose} variant="outlined">
          {props.i18n?.close ?? 'Close'}
        </Button>
      )}
    </div>
  )
})

NotFoundError.displayName = 'NotFoundError'
