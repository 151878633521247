import { Icon, Spacing, Typography } from '@epilot/concorde-elements'
import type {
  Coupon,
  PriceWithBlockMappings
} from '@epilot/journey-logic-commons'
import { formatCoupon } from '@epilot/journey-logic-commons'
import classNames from 'classnames'
import type { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './ProductCoupon.module.scss'

export type ProductCouponProps = {
  coupon: Coupon
  isStatic?: boolean
  isCompact?: boolean
  price?: PriceWithBlockMappings
}

export const ProductCoupon = ({
  isStatic,
  isCompact,
  coupon,
  price
}: ProductCouponProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        'Concorde-ProductCoupon',
        classes.couponContainer,
        coupon.category === 'cashback'
          ? classes.couponCashback
          : classes.couponDiscount,
        isStatic && classes.couponNotPositioned,
        isCompact && classes.couponCompact
      )}
    >
      <Spacing
        alignItems="center"
        justifyContent="left"
        scale={2}
        variant="inline"
      >
        {coupon.category === 'discount' && coupon.type === 'percentage' && (
          <Icon color="white" isFilled name="sell" size="16" />
        )}
        <Typography
          className={classes.discountText}
          title={formatCoupon(coupon, t, price)}
        >
          {formatCoupon(coupon, t, price)}
        </Typography>
      </Spacing>
    </div>
  )
}

export const ProductCouponWrapper = ({
  className,
  ...props
}: ComponentProps<'div'>) => (
  <div
    className={classNames(
      'Concorde-ProductTile__CouponWrapper',
      classes.container,
      className
    )}
    {...props}
  />
)
