import { getClient } from '@epilot/submission-client'
import type { Client, SubmissionNonce } from '@epilot/submission-client'
import { useMemo } from 'react'

import { env } from '../../utils/config'

let client: Client

export const getSubmissionClient = (orgId?: string, token?: string): Client => {
  if (!client) {
    client = getClient()
    client.defaults.baseURL = env('REACT_APP_JOURNEYS_SUBMISSION_API')

    if (orgId) {
      client.defaults.headers['X-Ivy-Org-ID'] = orgId
    }

    if (token) {
      client.defaults.headers['Authorization'] = `Bearer ${token}`
    }
  }

  return client
}

export const useSubmissionClient = () =>
  useMemo(() => getSubmissionClient(), [])

export type { SubmissionNonce }
