import type {
  CompositePrice,
  JourneyCart,
  JourneyCartItem,
  JourneyCartItemProduct,
  JourneyContextValue,
  OptionalPriceComponentBlockMappingMetadata,
  Price,
  PriceInputMappings
} from '@epilot/journey-logic-commons'

/**
 * Seems to be functionally similar to `getPriceMappings` from `@epilot/journey-logic-commons`
 * @todo Reduce code duplication by reusing the existing function
 */
const getPriceMappingsFromSelectedPrice = (
  selectedPrice?: CompositePrice | Price
): PriceInputMappings => {
  if (selectedPrice?.is_composite_price) {
    return selectedPrice.price_components
      ?.map(
        (priceComponent) =>
          typeof priceComponent.blockMappingData.numberInput === 'number' && {
            price_id: priceComponent._id,
            value: priceComponent.blockMappingData.numberInput,
            frequency_unit: priceComponent.blockMappingData.frequencyUnit
          }
      )
      .filter(Boolean)
  }

  return typeof selectedPrice?.blockMappingData?.numberInput === 'number'
    ? [
        {
          price_id: selectedPrice?._id,
          value: selectedPrice?.blockMappingData.numberInput,
          frequency_unit: selectedPrice?.blockMappingData.frequencyUnit
        }
      ]
    : []
}

const getExternalPriceData = (product: JourneyCartItemProduct) => {
  const selectedPrice = product.selectionMetadata?.selectedPrice

  if (!selectedPrice?.getag_price && !selectedPrice?.dynamic_tariff_price) {
    return {}
  }

  const { getag_price, dynamic_tariff_price } = selectedPrice

  return {
    ...(getag_price && { external_fees_metadata: getag_price }),
    ...(dynamic_tariff_price && {
      external_price_metadata: dynamic_tariff_price
    })
  }
}

const toCartItem = (
  item: JourneyCartItem,
  omittedPriceComponents?: ReadonlyArray<OptionalPriceComponentBlockMappingMetadata>
): JourneyCartItem => {
  const priceMappings = getPriceMappingsFromSelectedPrice(
    item.product.selectionMetadata?.selectedPrice
  )
  const { external_fees_metadata, external_price_metadata } =
    getExternalPriceData(item.product)

  const selectedPriceComponentIds =
    item.product.selectionMetadata?.selectedPrice?.price_components
      ?.filter(
        (priceComponent) =>
          !omittedPriceComponents?.some(
            ({ productId, priceId, priceComponentId }) =>
              productId === item.product.selectedProductId &&
              priceId === item.product.selectedPriceId &&
              priceComponentId === priceComponent._id
          )
      )
      .map((priceComponent) => priceComponent._id)

  const result = {
    ...item,
    ...(priceMappings.length && { price_mappings: priceMappings }),
    ...(external_fees_metadata && {
      external_fees_metadata: external_fees_metadata
    }),
    ...(external_price_metadata && {
      external_price_metadata: external_price_metadata
    }),
    ...(selectedPriceComponentIds?.length && {
      selected_price_component_ids: selectedPriceComponentIds
    })
  }

  return result
}

export const toCartItems = (
  journeyCart: JourneyCart,
  omittedPriceComponentsPerStep?: JourneyContextValue['omittedPriceComponentsPerStep']
): JourneyCartItem[] => {
  // Loop through multiple product selection steps
  return Object.entries(journeyCart).flatMap(([stepId, items]) => {
    if (!items) {
      return []
    }

    const omittedPriceComponents = omittedPriceComponentsPerStep?.[stepId]

    // if option "select one product each" set, cart will be an array of items
    return Array.isArray(items)
      ? items.map((item) => toCartItem(item, omittedPriceComponents))
      : [toCartItem(items, omittedPriceComponents)]
  })
}
