import type { TFunction } from 'i18next'

import type { COUNTRY_CODE } from '../../types/next'
import type { UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

import { getValuesFromBlockState } from './utils'

type BlockState = {
  countryCode?: COUNTRY_CODE
  zipCode?: string
  city?: string
  suburb?: string
  streetName?: string
  houseNumber?: string
  extention?: string
  salutation?: string
  title?: string
  firstName?: string
  lastName?: string
  companyName?: string
}

const optionalAddressBlockProperties = [
  'companyName',
  'salutation',
  'title',
  'firstName',
  'lastName',
  'streetName',
  'houseNumber',
  'extention',
  'suburb'
] as const

export const addressBlockProperties = [
  'countryCode',
  'zipCode',
  'city',
  ...optionalAddressBlockProperties
] as const

export type AddressBlockProperties = (typeof addressBlockProperties)[number]

export const getVariablesFromAddressBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: AddressBlockProperties[] = []

  optionalAddressBlockProperties.forEach((property) => {
    if (uiSchema.options?.fields?.[property] !== undefined) {
      properties.push(property)
    }
  })

  properties.push('zipCode', 'city', 'countryCode')

  return {
    isAccessedByIndex: false,
    properties: properties
  }
}

export const getValuesFromAddressBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<AddressBlockProperties, string>> =
    getValuesFromBlockState(
      blockState as BlockState,
      addressBlockProperties,
      (value, property) =>
        property === 'salutation'
          ? t(`salutation.options.${value.replace(/\./g, '·')}`, value)
          : value
    )

  return data
}
