import { CUSTOMER_TYPE } from '@epilot/journey-logic-commons'
import type {
  ContactControlOptions,
  ContactControlOptionsDto,
  LegacyPersonalInformationControlOptions
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLegacyDto = (
  obj: any
  // type any here is just a type of GridProps from MUI
): obj is LegacyPersonalInformationControlOptions<any> =>
  obj && obj['fieldsOrder']

export const mapToContactOptions = (
  // type any here is just a type of GridProps from MUI
  dto: ContactControlOptionsDto<any>,
  translate?: TFunction,
  skipFields?: string[],
  disabledFields?: string[]
): ContactControlOptions | { error: string } => {
  const fields: ContactControlOptions['fields'] = {}
  const t = translate || ((value: string) => value)

  if (!dto) return { error: 'Incoming DTO is not set' }

  // the following function is intercepting the flow to map the legacy dto to the new dto
  // it will also mark field as disabled if it is part of the disabledFields array
  if (!isLegacyDto(dto)) {
    if (disabledFields) {
      const { fields } = dto

      ;(Object.keys(fields) as Array<keyof typeof fields>).forEach(
        (fieldName) => {
          Object.assign(fields, {
            [fieldName]: {
              ...fields[fieldName],
              ...(disabledFields?.includes(fieldName) ? { disabled: true } : {})
            }
          })
        }
      )

      return { ...dto, fields }
    }

    return dto
  }

  const fieldsDto =
    dto.fieldsOrder && Array.isArray(dto.fieldsOrder) ? dto.fieldsOrder : []

  try {
    fieldsDto.forEach((item) => {
      const fieldName = item.name

      if (!fieldName) throw 'DTO Mapping -> field name does not exist'

      if (skipFields && skipFields.includes(fieldName)) return

      let placeholder: string | undefined = undefined
      let showIf: { customerType: CUSTOMER_TYPE } | undefined = undefined
      let genderType: 'GENDER3' | undefined = undefined
      let defaultValue: string | undefined
      let options: { value: string; label: string }[] | undefined

      const label = dto.labels?.[fieldName]
      const errorMessage = dto.errorMessages?.[fieldName]
      const required = dto.requiredFields?.[fieldName]

      if (
        fieldName !== 'salutation' &&
        fieldName !== 'title' &&
        fieldName !== 'agreement'
      ) {
        placeholder = dto.placeHolders ? dto.placeHolders[fieldName] : undefined
      }

      if (
        fieldName === 'customerType' ||
        fieldName === 'companyName' ||
        fieldName === 'registryCourt' ||
        fieldName === 'registerNumber'
      ) {
        showIf =
          dto.showCondition?.[fieldName] &&
          dto.showCondition[fieldName] !== 'Always'
            ? { customerType: CUSTOMER_TYPE.BUSINESS }
            : undefined
      }

      if (
        fieldName === 'salutation' &&
        dto.salutationType === 'Mr/Mrs/Diverse'
      ) {
        genderType = 'GENDER3'
      }

      // check if there is a custom default selection, or custom labels
      if (fieldName === 'customerType') {
        if (dto.defaultCustomerTypeAndLabelOptions) {
          if (dto.defaultCustomerTypeAndLabelOptions.defaultCustomerSelection) {
            defaultValue =
              dto.defaultCustomerTypeAndLabelOptions.defaultCustomerSelection
          }
          if (dto.defaultCustomerTypeAndLabelOptions.customerTypeLabelOptions) {
            const { customerTypeLabelOptions } =
              dto.defaultCustomerTypeAndLabelOptions

            if (
              customerTypeLabelOptions.businessLabel ||
              customerTypeLabelOptions.privateLabel
            ) {
              options = [
                {
                  value: 'Private',
                  label: customerTypeLabelOptions.privateLabel || t('Private')
                },
                {
                  value: 'Business',
                  label: customerTypeLabelOptions.businessLabel || t('Business')
                }
              ]
            }
          }
        }
      }

      Object.assign(fields, {
        [fieldName]: {
          ...(label && { label }),
          ...(errorMessage && { errorMessage }),
          ...(placeholder && { placeholder }),
          ...(required && { required }),
          ...(showIf && { showIf }),
          ...(genderType && { genderType }),
          ...(defaultValue && { defaultValue }),
          ...(options && { options }),
          ...(disabledFields?.includes(fieldName) ? { disabled: true } : {})
        }
      })
    })
  } catch (error) {
    // if any error in mapping, return it here
    if (error instanceof Error) {
      return { error: error.message }
    } else {
      return { error: `${error}` }
    }
  }

  return { fields }
}
