import { PricingModel } from '@epilot/pricing'
import type {
  SpotMarketDataFrequency,
  HistoricMarketPricesResult
} from '@epilot/pricing-client'
import {
  subMonths,
  subYears,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  format
} from 'date-fns'
import { type Options as HighChartOptions } from 'highcharts'
import type { TFunction } from 'i18next'
import { unique } from 'radashi'

import { getPricingClient } from '../../clients'
import type {
  ChartType,
  ChartResolution,
  Price,
  PriceWithBlockMappings,
  ChartConfiguration
} from '../types'

import { isCompositePrice } from './'

export const isRealtimePrice = (price: PriceWithBlockMappings) => {
  const priceItems = isCompositePrice(price)
    ? ((price.price_components ?? []) as Price[])
    : [price]

  return priceItems.some(
    (item) =>
      item.pricing_model === PricingModel.dynamicTariff &&
      item.dynamic_tariff?.mode === 'day_ahead_market' &&
      item.dynamic_tariff.interval === 'hourly'
  )
}

export const getAvailableChartsFromPrice = (price: PriceWithBlockMappings) => {
  if (isCompositePrice(price)) {
    return unique(
      ((price.price_components ?? []) as Price[]).flatMap(getCharts)
    )
  }

  return getCharts(price)
}

const getCharts = (price: Price): ChartType[] => {
  if (price.pricing_model === PricingModel.dynamicTariff) {
    if (
      (price.dynamic_tariff?.mode === 'day_ahead_market' &&
        price.dynamic_tariff.interval === 'hourly') ||
      price.dynamic_tariff?.mode === 'manual'
    ) {
      return ['last_12_months', 'last_30_days', 'today']
    } else {
      return ['last_12_months']
    }
  }

  return []
}

export function getDateRange(
  chartType: ChartType,
  resolution: ChartResolution
): {
  firstDay: string
  lastDay: string
  frequency: SpotMarketDataFrequency
} {
  const now = new Date()
  let firstDay: Date
  let lastDay: Date
  let frequency: SpotMarketDataFrequency

  switch (chartType) {
    case 'previous_year': {
      const previousYear = subYears(now, 1)

      firstDay = startOfYear(previousYear)
      lastDay = endOfYear(previousYear)
      break
    }
    case 'last_12_months': {
      firstDay = startOfMonth(subMonths(now, 12))
      lastDay = endOfMonth(subMonths(now, 1))
      break
    }
    case 'previous_month': {
      const previousMonth = subMonths(now, 1)

      firstDay = startOfMonth(previousMonth)
      lastDay = endOfMonth(previousMonth)
      break
    }
    case 'last_30_days': {
      const yesterday = subDays(now, 1)

      firstDay = subDays(yesterday, 29)
      lastDay = yesterday
      break
    }
    case 'yesterday': {
      const yesterday = subDays(now, 1)

      firstDay = yesterday
      lastDay = yesterday
      break
    }
    case 'today':
    default: {
      firstDay = now
      lastDay = now
      break
    }
  }

  switch (resolution) {
    case 'monthly':
      frequency = 'P1M'
      break
    case 'daily':
      frequency = 'P1D'
      break
    case 'hourly':
      frequency = 'PT1H'
      break
    default:
      frequency = 'PT15M'
  }

  return {
    firstDay: format(firstDay, 'yyyy-MM-dd'),
    lastDay: format(lastDay, 'yyyy-MM-dd'),
    frequency
  }
}

export const priceChartConfigurations: Record<ChartType, ChartConfiguration> = {
  previous_year: {
    label: 'price_chart.labels.previous_year',
    label_fallback: 'Previous Year',
    short_label: 'price_chart.labels.previous_year_short',
    short_label_fallback: 'Year',
    resolution: 'monthly'
  },
  last_12_months: {
    label: 'price_chart.labels.last_12_month',
    label_fallback: 'Last 12 Months',
    short_label: 'price_chart.labels.last_12_month_short',
    short_label_fallback: '12 Months',
    resolution: 'monthly'
  },
  previous_month: {
    label: 'price_chart.labels.previous_month',
    label_fallback: 'Previous Month',
    short_label: 'price_chart.labels.previous_month_short',
    short_label_fallback: 'Month',
    resolution: 'daily'
  },
  last_30_days: {
    label: 'price_chart.labels.last_30_days',
    label_fallback: 'Last 30 Days',
    short_label: 'price_chart.labels.last_30_days_short',
    short_label_fallback: '30 Days',
    resolution: 'daily'
  },
  yesterday: {
    label: 'price_chart.labels.yesterday',
    label_fallback: 'Yesterday',
    short_label: 'price_chart.labels.yesterday',
    short_label_fallback: 'Yesterday',
    resolution: 'hourly'
  },
  today: {
    label: 'price_chart.labels.today',
    label_fallback: 'Today',
    short_label: 'price_chart.labels.today',
    short_label_fallback: 'Today',
    resolution: 'hourly'
  }
}

export const buildChartOptions = (
  chart: ChartType,
  data: { timestamp: string; unit_amount: number }[],
  t: TFunction,
  isUsingNewDesign: boolean
): { label: string; short_label?: string; options: HighChartOptions } => {
  const config = priceChartConfigurations[chart]

  return {
    label: t(config.label, config.label_fallback),
    short_label: t(config.short_label, config.short_label_fallback),
    options: chartOptions({
      resolution: config.resolution,
      data: data,
      t,
      isUsingNewDesign
    })
  }
}

const baseOptions = (
  average: number,
  isHourly: boolean,
  isUsingNewDesign: boolean
): HighChartOptions => ({
  time: {
    timezone: 'Europe/Berlin'
  },
  colors: ['var(--concorde-primary-color)', 'var(--concorde-secondary-color)'],
  chart: {
    ...(isUsingNewDesign && {
      backgroundColor: 'var(--concorde-surface-background)'
    }),
    style: {
      fontFamily: 'var(--concorde-font-family)',
      fontSize: 'var(--concorde-text-base)'
    },
    animation: {
      duration: 500
    },
    spacingBottom: 20,
    reflow: true,
    spacing: [10, 30, 10, 0]
  },
  title: {
    text: ''
  },
  credits: {
    text: '',
    href: '',
    style: {
      cursor: 'default'
    },
    position: {
      align: 'center',
      y: 0
    }
  },
  xAxis: {
    crosshair: false,
    type: 'datetime',
    dateTimeLabelFormats: {
      day: isHourly ? '%[HM]' : '%[eb]'
    },
    ...(isUsingNewDesign && {
      labels: {
        style: {
          color: 'var(--concorde-primary-text)',
          fontSize: 'var(--concorde-text-xs)'
        }
      },
      lineColor: 'var(--concorde-secondary-text)',
      tickColor: 'var(--concorde-secondary-text)'
    })
  },
  yAxis: {
    labels: {
      format: '{value}',
      ...(isUsingNewDesign && {
        style: {
          color: 'var(--concorde-primary-text)',
          fontSize: 'var(--concorde-text-xs)'
        }
      })
    },
    title: {
      text: 'ct/kWh',
      ...(isUsingNewDesign && {
        style: {
          color: 'var(--concorde-secondary-text)',
          fontSize: 'var(--concorde-text-xs)'
        }
      })
    },
    plotLines: [
      {
        width: 2,
        value: average,
        dashStyle: 'Dot',
        ...(isUsingNewDesign && {
          color: 'rgba(var(--concorde-primary-color-rgb), 0.5)'
        }),
        zIndex: 5
      }
    ],
    ...(isUsingNewDesign && {
      // --concorde-outline-rgb = 116, 119, 127
      gridLineColor: 'rgba(116, 119, 127, 0.5)'
    })
  },
  plotOptions: {
    line: {
      step: 'left'
    },
    series: {
      marker: {
        enabled: false
      },
      animation: {
        duration: 1000
      }
    }
  },
  legend: {
    enabled: false
  },
  ...(isUsingNewDesign && {
    tooltip: {
      backgroundColor: 'var(--concorde-secondary-background)',
      borderColor: 'var(--concorde-outline)',
      style: {
        color: 'var(--concorde-primary-text)'
      }
    }
  }),
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 750
        },
        chartOptions: {
          yAxis: {
            title: {
              text: undefined
            }
          },
          chart: {
            spacingRight: 0
          }
        }
      }
    ]
  }
})

const chartOptions = ({
  resolution,
  data,
  t,
  isUsingNewDesign
}: {
  resolution: ChartResolution
  data: { timestamp: string; unit_amount: number }[]
  t: TFunction
  isUsingNewDesign: boolean
}): HighChartOptions => {
  const average =
    data.reduce((acc, { unit_amount }) => acc + unit_amount, 0) / data.length

  const series = data.map((entry) => [entry.timestamp, entry.unit_amount])

  return {
    ...baseOptions(average, resolution === 'hourly', isUsingNewDesign),
    series: [
      {
        type: resolution === 'monthly' ? 'column' : 'line',
        name:
          resolution !== 'hourly'
            ? t('price_chart.average_marketprice', 'Average Market Price')
            : t('price_chart.marketprice', 'Market Price'),
        data: series,
        tooltip: {
          valueSuffix: ' ct/kWh',
          valueDecimals: 1
        },
        ...(isUsingNewDesign && {
          borderColor: 'var(--concorde-primary-color)'
        })
      }
    ]
  }
}

type ChartOptionsType = Record<ChartType, any>

export const createPriceChartQueries = (
  charts: ChartType[],
  PRICING_API_URL: string,
  publicToken: string
) => {
  const client = getPricingClient(PRICING_API_URL, undefined, publicToken)

  return charts.map((chart) => {
    const { firstDay, lastDay, frequency } = getDateRange(
      chart,
      priceChartConfigurations[chart].resolution
    )

    return {
      queryKey: ['historicMarketPrices', frequency, firstDay, lastDay],
      queryFn: async () => {
        const { data } = await client.$historicMarketPrices({
          market: 'day_ahead',
          bidding_zone: 'DE-LU',
          from: firstDay,
          to: lastDay,
          frequency: frequency
        })

        return data
      },
      staleTime: 5 * 60 * 1000,
      select: (data: any) => ({ chart: chart, data: data })
    }
  })
}

export const processPriceChartData = (
  data: { chart: ChartType; data: HistoricMarketPricesResult }[],
  pending: boolean,
  t: TFunction,
  isUsingNewDesign: boolean = true
): ChartOptionsType => {
  if (pending) {
    return {} as ChartOptionsType
  }

  const chartOptions: ChartOptionsType = {} as ChartOptionsType

  data.forEach((result) => {
    const { chart, data } = result

    chartOptions[chart] = buildChartOptions(
      chart,
      data.prices ?? [],
      t,
      isUsingNewDesign
    )
  })

  return chartOptions
}
