import type { COUNTRY_CODE } from '../../types/next'
import type { UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

import { getValuesFromBlockState } from './utils'

type BlockState = {
  countryCode?: COUNTRY_CODE
  zipCode?: string
  city?: string
  streetName?: string
  streetNumber?: string
}

export const availabilityBlockProperties = [
  'countryCode',
  'zipCode',
  'city',
  'streetName',
  'streetNumber'
] as const

export type AvailabilityBlockProperties =
  (typeof availabilityBlockProperties)[number]

export const getVariablesFromAvailabilityBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: AvailabilityBlockProperties[] = [
    'countryCode',
    'zipCode',
    'city'
  ]

  if (
    uiSchema.options?.postalCodeAvailabilityFields?.streetName ||
    uiSchema.options?.productAvailabilityServiceFields?.streetName
  ) {
    properties.push('streetName')
  }

  if (
    uiSchema.options?.postalCodeAvailabilityFields?.streetNumber ||
    uiSchema.options?.productAvailabilityServiceFields?.streetNumber
  ) {
    properties.push('streetNumber')
  }

  return {
    isAccessedByIndex: false,
    properties: properties
  }
}

export const getValuesFromAvailabilityBlock = ({
  state: blockState
}: {
  state: unknown
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<AvailabilityBlockProperties, string>> =
    getValuesFromBlockState(
      blockState as BlockState,
      availabilityBlockProperties
    )

  return data
}
