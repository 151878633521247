import { getClient } from '@epilot/file-client'
import {
  blockController,
  RUNTIME_ENTITY,
  controlsForDisplay,
  CONTROL_NAME,
  isNestedUiSchema,
  isLayoutUiSchema,
  JOURNEY_ACCESS_MODE
} from '@epilot/journey-logic-commons'
import type {
  Journey,
  Step,
  UiSchema,
  ConsentData,
  StepState,
  DisplayConditionsStatus,
  CompositePrice,
  Price,
  Coupon,
  JourneyCartItem,
  ContextData
} from '@epilot/journey-logic-commons'
import type { RedeemedPromo } from '@epilot/pricing-client'
import type { Components } from '@epilot/submission-client'
import type { AxiosRequestConfig, Method } from 'axios'
import axios from 'axios'
import { once } from 'radashi'

import type {
  BaseEntity,
  DynamicMeterReadingFormValues,
  MeterReadingFormValues
} from '../blocks-renderers'
import { getPortalToken } from '../blocks-renderers'
import { getShadowFormDataJSON } from '../shadow-form/shadow-form'
import { env } from '../utils/config'
import { journeyErrorMessageKeyMap } from '../utils/journeyErrorUtils'
import { generateTimestamp } from '../utils/timestampUtils'
import { TRACE_KEYS, getTraceId } from '../utils/trace'
import type { FileToPersist } from '../utils/types'

import { getDataDogSessionId } from './datadog'

type s3File = {
  s3ref: {
    bucket: string
    key: string
  }
}

type BlobFile = {
  blob: string
  blockName: string
  stepIndex: number
  stepId: string
}

export async function axiosCallApi<D = unknown, R = unknown>(options: {
  method: Method
  url: string
  path: string
  data?: D
  params?: Record<string, unknown>
  customHeaders?: Record<string, string>
  bearerToken?: string
  authToken?: string
}) {
  const {
    method,
    path,
    url,
    data,
    params = {},
    customHeaders = {},
    bearerToken,
    authToken
  } = options

  const config: AxiosRequestConfig<D> = {
    method: method,
    url: url + path,
    data,
    params,
    headers: {
      'Content-Type': 'application/json',
      ...customHeaders,
      ...(authToken
        ? {
            Authorization: `Bearer ${authToken}`
          }
        : bearerToken
          ? { Authorization: `Bearer ${bearerToken}` }
          : {})
    }
  }

  return axios(config).then(
    (response) => response.data as R,
    (error) => {
      return Promise.reject(error)
    }
  )
}

/**
 *
 * @param steps the steps configuration
 * @param data the actual journey data of the steps
 * @param overwrites this one is used to unify the data of specific control, inside this object, the key is the control type while the value of the key is the value that should be replaced with
 * @returns cleaned data
 */
export function cleanDisplayDataFromJourney(
  steps: Step[],
  data: Record<string, unknown>[],
  overwrites?: Record<string, unknown>
): Record<string, unknown>[] {
  const overwriteKeys = overwrites ? Object.keys(overwrites) : []

  steps.forEach((step, stepIndex) => {
    const uischema = step.uischema

    let elements = isLayoutUiSchema(uischema) ? uischema.elements : []

    // check if it is a complex layout
    // if so, then flatten the arrays
    if (isNestedUiSchema(uischema)) {
      elements = uischema.elements.reduce(
        (a, b) => a.concat(b),
        [] as UiSchema[]
      )
    }

    for (let i = 0; i < elements.length; i++) {
      const ele = elements[i]

      // exclude the source if in the list and target if also in the list
      if (
        ele.type &&
        controlsForDisplay.indexOf(ele.type as CONTROL_NAME) !== -1 &&
        ele.options?.ctaButton?.actionType !== 'SubmitAndGoNext'
      ) {
        const sKey = ele.scope.split('/')
        const name = sKey[sKey.length - 1]
        const newData = { ...data[stepIndex] }

        delete newData[name]
        data[stepIndex] = newData
      }

      // deal with overwrites
      // exclude the source if in the list and target if also in the list
      if (
        ele.type &&
        ele.type === CONTROL_NAME.CONSENTS_CONTROL &&
        overwrites &&
        overwriteKeys.indexOf(ele.type) !== -1
      ) {
        const sKey = ele.scope.split('/')
        const name = sKey[sKey.length - 1]
        const newData = { ...data[stepIndex] }

        newData[name] = overwrites[ele.type]
        data[stepIndex] = newData
      }
    }
  })

  return data
}

export type OptIn = Components.Schemas.OptIn
export type OptInArray = Components.Schemas.SubmissionPayload['opt_ins']

const getSubmissionType = (runtimeEntities?: string[]) => {
  if (
    runtimeEntities?.includes(RUNTIME_ENTITY.OPPORTUNITY) &&
    runtimeEntities?.includes(RUNTIME_ENTITY.ORDER)
  ) {
    return 'quote_request'
  }

  if (runtimeEntities?.includes(RUNTIME_ENTITY.OPPORTUNITY)) {
    return 'general_request'
  }

  if (runtimeEntities?.includes(RUNTIME_ENTITY.ORDER)) {
    return 'direct_sale'
  }

  if (runtimeEntities?.length === 0) {
    return 'other'
  }
}

type EmptyObject = Record<string, never>

type NonEmptyProperties<T> = {
  [K in keyof T as T[K] extends undefined | EmptyObject ? never : K]: T[K]
}

const isEmptyObject = (value: unknown): value is EmptyObject =>
  Boolean(value && typeof value === 'object' && Object.keys(value).length === 0)

/**
 * Strips empty values from an object, including undefined values and empty objects. Returns a new object and supports chaining.
 * This operation is not recursive and works only on the first level of the object.
 *
 * @param obj The object to strip empty values from
 * @returns A new object with empty values stripped
 */
const stripEmptyValues = <T extends Record<string, unknown>>(
  obj: T
): NonEmptyProperties<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => !(value === undefined || isEmptyObject(value))
    )
  ) as NonEmptyProperties<T>

const compactPrice = (price: Price | CompositePrice | undefined) =>
  price
    ? stripEmptyValues({
        _id: price._id,
        unit_amount: price.unit_amount,
        unit_amount_currency: price.unit_amount_currency,
        unit_amount_decimal: price.unit_amount_decimal,
        unit: price.unit,
        is_composite_price: price.is_composite_price,
        is_tax_inclusive: price.is_tax_inclusive,
        variable_price: price.variable_price,
        pricing_model: price.pricing_model,
        billing_period: price.billing_period,
        type: price.type,
        billing_duration: price.billing_duration,
        blockMappingData: price.blockMappingData,
        ...(Array.isArray(price._coupons) && {
          _coupons: price._coupons.map(compactCoupon)
        }),
        ...(price.tax?.[0] && {
          tax: [
            stripEmptyValues({
              _id: price.tax[0]._id,
              rate: price.tax[0].rate,
              behavior: price.tax[0].behavior
            })
          ]
        }),
        ...(price.tiers && { tiers: price.tiers }),
        ...(Array.isArray(price.price_components) && {
          price_components: price.price_components.map(compactPrice)
        })
      })
    : undefined

const compactCoupon = (coupon: Coupon) => stripEmptyValues(coupon)

type CompositePriceWithPriceComponentArray = Omit<
  CompositePrice,
  'price_components'
> & { price_components?: Array<Price> }

const isCompositePriceWithPriceComponentArray = (
  price: Price | CompositePrice
): price is CompositePriceWithPriceComponentArray =>
  Boolean(price.is_composite_price) && Array.isArray(price.price_components)

const extractSelectedCompositePriceComponentsCouponsFromPrice = (
  price: CompositePriceWithPriceComponentArray
): { [componentId: string]: Array<Coupon> } =>
  Object.fromEntries(
    (price.price_components ?? []).map(({ _id, _coupons }) => [
      _id,
      (_coupons ?? []).map(compactCoupon)
    ])
  )

/**
 * @todo Make this logic immutable
 */
const compactSelectionMetadata = (
  productMetadata?: JourneyCartItem['product']
) => {
  if (productMetadata?.selectionMetadata) {
    const metadata = productMetadata.selectionMetadata

    productMetadata.selectionMetadata = {
      ...(metadata.selectedPrice && {
        selectedPrice: compactPrice(metadata.selectedPrice),
        ...(isCompositePriceWithPriceComponentArray(metadata.selectedPrice) && {
          selectedCompositePriceComponentsCoupons:
            extractSelectedCompositePriceComponentsCouponsFromPrice(
              metadata.selectedPrice
            )
        })
      }),
      ...(metadata.selectedProduct && {
        selectedProduct: stripEmptyValues({
          _id: metadata.selectedProduct._id,
          name: metadata.selectedProduct.name,
          ...(metadata.selectedProduct.code && {
            code: metadata.selectedProduct.code
          })
        })
      }),
      ...(metadata.selectedCoupons && {
        selectedCoupons: metadata.selectedCoupons.map((coupon) =>
          compactCoupon(coupon)
        )
      }),
      ...(metadata.selectedExternalCatalogData && {
        selectedExternalCatalogData: metadata.selectedExternalCatalogData
      })
    }
  }
}

/**
 * Compacts the pricing data in the steps removing unnecessary data.
 * This method creates a safe copy of the data before compacting it.
 * @todo Make immutable
 */
export const compactStepsPricingData = (steps: Record<string, unknown>[]) => {
  if (!steps) {
    return
  }

  const stepsCopy = structuredClone(steps)

  stepsCopy
    .flatMap(Object.values)
    .filter(
      (blockValue) =>
        Array.isArray(blockValue) ||
        blockValue?.['product']?.['selectionMetadata']
    )
    .map((blockValue) =>
      Array.isArray(blockValue) ? blockValue : [blockValue]
    )
    .flatMap((blockValue) => blockValue)
    .map((block) => (block as JourneyCartItem)?.product)
    .forEach(compactSelectionMetadata)

  return stepsCopy
}

/**
 * Compacts the pricing data in the lineItems removing unnecessary data.
 * This method creates a safe copy of the data before compacting it.
 * @todo Make immutable
 */
export const compactLineItemsData = (lineItems: JourneyCartItem[]) => {
  const lineItemsCopy = structuredClone(lineItems)

  lineItemsCopy.forEach((lineItem) =>
    compactSelectionMetadata(lineItem.product)
  )

  return lineItemsCopy
}

// TODO: Add version or commit hash to submission file (or the context data)
export const convertToSubmission = ({
  journey,
  stepsState,
  fileEntities,
  cartItems,
  billingAddressValue,
  contactAddressValue,
  additionalAddressesValue,
  paymentValue,
  optIns,
  consents,
  contextData,
  meterReadings,
  displayConditionsStatus,
  historyIndexes,
  fetchedEntities,
  redeemedPromoCodes
}: {
  journey: Journey
  stepsState: StepState[]
  fileEntities?: FileToPersist[]
  cartItems: JourneyCartItem[]
  billingAddressValue?: unknown
  contactAddressValue?: unknown
  additionalAddressesValue?: unknown[]
  paymentValue?: unknown
  optIns?: OptInArray
  consents?: Record<string, ConsentData>
  contextData?: ContextData
  meterReadings: MeterReadingFormValues[] | DynamicMeterReadingFormValues[]
  displayConditionsStatus: DisplayConditionsStatus
  historyIndexes: number[]
  fetchedEntities: Record<string, BaseEntity>
  redeemedPromoCodes: Array<RedeemedPromo>
}): Components.Schemas.SubmissionPayload => {
  const runtimeEntities = journey.settings?.runtimeEntities

  const entitiesDict = {}

  Object.keys(fetchedEntities).forEach((key) => {
    entitiesDict[key] = fetchedEntities[key]['_id']
  })

  return {
    organization_id: journey.organizationId,
    source_type: 'journey',
    source_id: journey.journeyId,
    entities: [
      {
        steps: preventNullStepStates(compactStepsPricingData(stepsState)),
        _schema: 'submission',
        mapped_entities: {
          // contextData?.initial_submission_id could come from the journey_token (jwt) and passed to the context
          // TODO: write a test for this
          ...(contextData?.initial_submission_id
            ? {
                $relation: [
                  { entity_id: contextData?.initial_submission_id, _tags: [] }
                ]
              }
            : undefined)
        },
        line_items: compactLineItemsData(cartItems),
        billing_address: billingAddressValue,
        delivery_address: contactAddressValue,
        additional_addresses: additionalAddressesValue,
        payment_method: paymentValue,
        journey_name: journey.name,
        journey_context: {
          ...contextData,
          ...entitiesDict,
          fetched_entities: fetchedEntities,
          // Add in journey/session ids to make the submission fully traceable
          journey_session_id: getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID),
          datadog_session_id: getDataDogSessionId(),
          // Add in the app version
          journey_app_version: env('VERSION')
        },
        submission_type: getSubmissionType(runtimeEntities),
        runtime_entities:
          runtimeEntities && runtimeEntities?.length > 0
            ? runtimeEntities
            : undefined,
        files:
          fileEntities && fileEntities?.length > 0 ? fileEntities : undefined,
        consents:
          consents && Object.keys(consents).length > 0 ? consents : undefined,
        meterReadings: meterReadings?.length > 0 ? meterReadings : undefined,
        redeemedPromoCodes,
        _displayConditionsStatus: displayConditionsStatus,
        _historyIndexes: historyIndexes,
        nonce: contextData?.nonce
      }
    ],
    opt_ins: optIns
  }
}

/**
 * WARNING: This is a temporary fix to prevent Step state from being null, therefore
 * failing the submission API validation, effectively causing the submission to fail.
 *
 * See more in {@link https://e-pilot.atlassian.net/browse/STABLE360-6410}
 *
 * @deprecated should be removed after the underlying issue is fixed
 */
export const preventNullStepStates = (
  states?: (StepState | null | undefined)[]
): StepState[] => {
  const logOutOnce = once(() => {})

  return (
    states?.map((state) => {
      if (!state) {
        logOutOnce()
        // eslint-disable-next-line no-console
        console.warn(
          'Prevented Step state from being null or undefined in submission'
        )

        return {}
      }

      return state
    }) ?? []
  )
}

export async function submitJourney(
  journeySubmissionData: Components.Schemas.SubmissionPayload,
  journey: Journey
): Promise<{ errorKey: string } | void> {
  // Search for signatures
  const signatureBlocks = blockController.findBlocks(journey.steps, {
    type: 'DigitalSignatureControl'
  })
  const steps = journeySubmissionData.entities[0].steps
  const signatures: BlobFile[] = []

  for (const block of signatureBlocks) {
    if (steps[block.stepIndex][block.name]) {
      signatures.push({
        blob: steps[block.stepIndex][block.name],
        blockName: block.name,
        stepIndex: block.stepIndex,
        stepId: block.stepId
      })
      delete steps[block.stepIndex][block.name]
    }
  }

  const s3signatures = (
    await Promise.all(
      signatures
        .map(async (signature) => {
          const filename = `signature_step${Number(signature.stepIndex) + 1}_${
            signature.blockName
          }_${generateTimestamp()}.png`
          const file = await fetch(signature.blob)
            .then((r) => r.blob())
            .then(
              (blobFile) =>
                new File([blobFile], filename, { type: 'image/png' })
            )

          const s3Data = await uploadFileToS3({
            file: file,
            filename: filename,
            orgId: journeySubmissionData.organization_id,
            type: 'image/png'
          })

          // dont add to array if missing s3 reference
          if (!s3Data) {
            return
          }

          const relationTag = `_hidden_ ${signature.stepId} - ${signature.blockName}`

          return { ...s3Data, relation_tags: [relationTag] }
        })
        .filter(Boolean)
    )
  ).filter(Boolean) as s3File[]

  // return error if not all signatures were uploaded
  if (s3signatures.length !== signatures.length) {
    return { errorKey: journeyErrorMessageKeyMap.SubmissionNetworkError }
  }

  // Add signatures to files
  const existingFiles = journeySubmissionData.entities[0].files || []

  const allFiles = [...existingFiles, ...s3signatures]

  journeySubmissionData.entities[0].files =
    allFiles.length > 0 ? allFiles : undefined

  // Upload Shadow Form to s3
  const shadowFormS3Data = await uploadShadowForm(journeySubmissionData)

  if (shadowFormS3Data) {
    // Update Submission to include shadow form data
    journeySubmissionData.entities[0]._fallback = shadowFormS3Data
  }

  const authToken = await getPortalToken()
  const shouldSendToken =
    authToken !== null &&
    authToken &&
    journey.settings?.accessMode === JOURNEY_ACCESS_MODE.PRIVATE

  try {
    await axiosCallApi<Components.Schemas.SubmissionPayload>({
      url: env('REACT_APP_JOURNEYS_SUBMISSION_API'),
      method: 'POST',
      path: '/v1/submission/submissions',
      data: journeySubmissionData,
      bearerToken: journey.settings?.publicToken,
      authToken: shouldSendToken ? authToken : undefined
    })
  } catch (err) {
    if (isNetworkError(err)) {
      console.error(`Warning when submitting journey - Network error.`, err)

      return { errorKey: journeyErrorMessageKeyMap.SubmissionNetworkError }
    }
    console.error(`Error when submitting journey`, err)
  }
}

const uploadShadowForm = async (
  submissionPayload: Components.Schemas.SubmissionPayload
) => {
  try {
    const shadowFormData = getShadowFormDataJSON(submissionPayload)

    const buffer = Buffer.from(shadowFormData)
    const filename = `${generateTimestamp()}-fallback.json`

    const shadowFormS3Data = uploadFileToS3(
      {
        file: buffer,
        filename: filename,
        orgId: submissionPayload.organization_id,
        type: 'application/json'
      },
      0 // No retries for shadow form upload
    )

    return shadowFormS3Data
  } catch (originalError) {
    const error = new Error('Error generating fallback file')

    error.cause = originalError

    // eslint-disable-next-line no-console
    console.error(error, { submissionPayload })

    return undefined
  }
}

/**
 * Uploads a file to S3 and returns the S3 reference.
 * Retries the upload if a network error occurs.
 */
const uploadFileToS3 = async (
  params: {
    file: Buffer | File
    filename: string
    orgId: string
    type: string
  },
  retryCount = 5,
  initialRetryDelay = 200
) => {
  // Set the maximum number of retries
  try {
    const client = getClient()

    client.defaults.headers['x-ivy-org-id'] = params.orgId
    client.defaults.baseURL = env('REACT_APP_FILE_API_URL')

    const response = await client.uploadFilePublic(
      {},
      {
        filename: params.filename,
        mime_type: params.type
      }
    )

    const { data } = response

    await axios({
      method: 'PUT',
      url: data.upload_url,
      data: params.file,
      headers: {
        'Content-Type': params.type
      }
    })

    if (!data.s3ref) {
      return
    }

    return {
      s3ref: data.s3ref
    }
  } catch (e) {
    // Retry if it's a network error and retry count is greater than 0
    if (isNetworkError(e) && retryCount > 0) {
      console.error(
        `Network error occurred. Retrying upload (${retryCount} retries left)`
      )

      // Calculate the retry delay dynamically based on the retry count
      const retryDelay = initialRetryDelay + 1000 * (5 - retryCount) // Increase delay by 1000ms per retry

      // Wait for the retry delay
      await new Promise((resolve) => setTimeout(resolve, retryDelay))

      // Retry the upload
      return await uploadFileToS3(params, retryCount - 1, initialRetryDelay) // Pass initialRetryDelay to the recursive call
    } else {
      console.error(`Error uploading file ${params.filename} to s3`, e)
    }
  }
}

/**
 * Checks if the error is a network error.
 */
const isNetworkError = (error: unknown) =>
  error instanceof Error && error.message === 'Network Error'
