import {
  computeAggregatedAndPriceTotals,
  processOrderTableData
} from '@epilot/pricing'
import type { TFunction } from 'i18next'

import { getExternalFeesMappings, getPriceMappings } from '../../utils'
import type { JourneyContextValue, UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

export const shoppingCartBlockProperties = ['~~table_order_items'] as const
export type ShoppingCartBlockProperties =
  (typeof shoppingCartBlockProperties)[number]

export const getVariablesFromShoppingCartBlock = (
  _uiSchema: UiSchema
): BlockVariableConfig => {
  return {
    isAccessedByIndex: true,
    length: 1,
    properties: ['~~table_order_items']
  }
}

export const getValuesFromShoppingCartBlock = ({
  context,
  t
}: {
  context: JourneyContextValue
  t: TFunction
}) => {
  if (!context) {
    return {
      order: {}
    }
  }

  const { _shoppingCart } = context || {}

  const shoppingCartItems = Object.values(_shoppingCart ?? {})
    .flatMap((value) => value)
    .filter(Boolean)
    .map((item) => {
      const price = item.product?.selectionMetadata?.selectedPrice

      const blockMappings = getPriceMappings(price)
      const externalFeesMappings = getExternalFeesMappings(price)

      return {
        ...price,
        is_composite_price: price?.is_composite_price,
        price_components: price?.price_components,
        quantity: item.quantity || 1,
        _price: price,
        _product: item.product?.selectionMetadata?.selectedProduct,
        _coupons: item.product.selectionMetadata?.selectedCoupons,
        blockConfiguration: item.product?.selectionMetadata?.blockConfiguration,
        ...(blockMappings.length && { price_mappings: blockMappings }),
        ...(externalFeesMappings.length && {
          external_fees_mappings: externalFeesMappings
        })
      }
    })

  const otherTotals = computeAggregatedAndPriceTotals(shoppingCartItems, {
    redeemedPromos: context.redeemedPromoCodes
  })

  const input = {
    line_items: otherTotals.items || [],
    total_details: otherTotals.total_details
  }

  const result = processOrderTableData(input, {
    t,
    language: navigator.language
  })

  const { products, total_details } = result

  return {
    order: {
      products,
      total_details
    }
  }
}
