import type { FileStatusNotificationProps } from '@epilot/journey-logic-commons'

import { Paper } from '../'

export function FileStatusNotification({
  message,
  icon
}: FileStatusNotificationProps<React.ReactNode>) {
  return (
    <Paper
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20
      }}
    >
      {message}
      {icon}
    </Paper>
  )
}
