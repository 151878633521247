import type { Paths } from '@epilot/submission-client'
import { useEffect, useCallback, useState } from 'react'

import { getSubmissionClient } from '../../blocks-renderers/clients/submission-client'

export async function getNonceById(
  nonce: string
): Promise<Paths.GetNonce.Responses.$200> {
  try {
    const client = getSubmissionClient()

    const { data } = await client.getNonce({
      nonce_id: nonce
    })

    return data
  } catch (originalError) {
    const error = new Error(`Error when getting nonce by id ${nonce}`)

    error.cause = originalError

    throw error
  }
}

export const useCheckNonce = (nonce?: string) => {
  const [isNonceUsed, setIsNonceUsed] = useState<boolean | null>(null)

  const checkNonce = useCallback(async (nonce: string) => {
    try {
      const response = await getNonceById(nonce)

      setIsNonceUsed(!!response.exists)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (nonce) {
      checkNonce(nonce)
    }
  }, [nonce, checkNonce])

  return { isNonceUsed }
}
