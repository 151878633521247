import { memo, type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { NotFoundError } from './NotFoundError'

type Props = Omit<ComponentProps<typeof NotFoundError>, 'i18n'>

export const JourneyAlreadySubmittedError = memo((props: Props) => {
  const { t } = useTranslation()

  return (
    <NotFoundError
      i18n={{
        heading: t('This Journey was submitted already'),
        content: t(
          'Please contact support in case there are further questions'
        ),
        close: t('Close')
      }}
      {...props}
    />
  )
})

JourneyAlreadySubmittedError.displayName = 'ExpiredJourneyTokenError'
