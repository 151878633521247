import { LinearProgress, useIsMobileOrTablet } from '@epilot/concorde-elements'
import { clsx } from '@epilot/journey-logic-commons'
import { memo } from 'react'

import type { JourneyStepperProps } from '../../../../components/JourneyStepper'

import { DesktopStepper, MobileStepper } from './components'
import classes from './JourneyStepper.module.scss'

export const JourneyStepper = memo(
  ({
    currentStepIndex: currentStepIndexFull,
    steps,
    onChangeStepIndex,
    isProgressBar = true,
    remainingSteps,
    stack: fullStack
  }: JourneyStepperProps) => {
    // non injected stack
    const stack = fullStack.filter((step) => !step.isInjected)
    const injectedStepsCount = fullStack.length - stack.length
    // currentStepIndex now comes from the non injected steps
    const currentStepIndex = currentStepIndexFull - injectedStepsCount

    const currentStep = steps[currentStepIndex] || {}
    const maxSteps = steps.length - injectedStepsCount
    const isMobileOrTablet = useIsMobileOrTablet()

    const handleNavigation = (nextIndex: number) => {
      if (nextIndex >= 0 && nextIndex < maxSteps) {
        onChangeStepIndex(nextIndex)
      }
    }

    const onStepClick = (index: number) =>
      currentStepIndex > index
        ? onChangeStepIndex(index + injectedStepsCount)
        : undefined

    // if not linear or if linear + maxSteps higher than limit -> Progressbar
    if (isProgressBar) {
      return (
        <LinearProgress
          className={classes.progressBar}
          height={8}
          max={remainingSteps}
          value={stack.length - 1}
        />
      )
    }

    // if isMobile || isTablet -> MobileStepper
    if (isMobileOrTablet) {
      return (
        <MobileStepper
          activeStep={currentStepIndex}
          onBack={() => handleNavigation(currentStepIndex - 1)}
          showBackButton={currentStepIndex > 0}
          steps={maxSteps}
        />
      )
    }

    // else -> DesktopStepper
    return (
      <div className={clsx(classes.wrapper, classes.concordeLayout)}>
        <DesktopStepper
          currentStepIndex={currentStepIndex}
          injectedStepsCount={injectedStepsCount}
          onStepClick={onStepClick}
          showStepperLabels={Boolean(currentStep.showStepperLabels)}
          steps={steps}
        />
      </div>
    )
  }
)

JourneyStepper.displayName = 'JourneyStepper'
