import './types/global.d'
import 'iframe-resizer/js/iframeResizer.contentWindow'
import './index.css'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'
import { ReactQueryProvider } from './blocks-renderers'
import { ConfigCheckWrapper } from './components/ConfigCheckWrapper'
import JourneyLauncherContextProvider from './components/JourneyLauncher/JourneyLauncherContextProvider'
import { SpinnerPage } from './components/SpinnerPage'
import { AnalyticsProvider } from './context/AnalyticsContext'
import { DesignBuilderContextProvider } from './context/DesignBuilderContext'
import { instance18n } from './locales/i18n'
import { initDatadog } from './services/datadog'
import { createShadowFormElement } from './shadow-form/shadow-form'
import { STAGE, TIME_TO_FIRST_RENDER_METRIC, VERSION } from './utils/config'
import { debug } from './utils/debug'
import { startTimer } from './utils/timers'
import { TRACE_KEYS, generateTraceId } from './utils/trace'

debug('Journey App started', { STAGE, VERSION })

/**
 * WARNING: This should be always called and should happen before anything else,
 * thus making sure we can trace all sessions, including those that fail early.
 *
 * NOTE: This is supposed to be disabled for the test/local environments.
 */
initDatadog({
  disabled: ['test', 'local'].includes(STAGE)
})

/**
 * Generates an unique trace id for this App's session.
 *
 * NOTE: This should be called before anything else, otherwise we might loose the ability
 * to trace the session to underlying errors/resources.
 */
generateTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)

/**
 * Marks the beginning of times :)
 *
 * We wan't this metric to include the time spent in all operations that preceed
 * the rendering of the first step, thus we should start it as soon as possible.
 */
startTimer(
  TIME_TO_FIRST_RENDER_METRIC,
  'Time spent by the Journey App up until the first render, \
    which is when the user is able to see the actual content of the first step.'
)

/**
 * Render App
 */
ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider>
      <BrowserRouter>
        <ConfigCheckWrapper>
          <Suspense fallback={<SpinnerPage />}>
            <DesignBuilderContextProvider>
              <I18nextProvider i18n={instance18n}>
                <ReactQueryProvider>
                  <JourneyLauncherContextProvider>
                    <App />
                  </JourneyLauncherContextProvider>
                </ReactQueryProvider>
              </I18nextProvider>
            </DesignBuilderContextProvider>
          </Suspense>
        </ConfigCheckWrapper>
      </BrowserRouter>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

createShadowFormElement()
