import type { ComponentType } from 'react'
import { forwardRef } from 'react'
import type { NumberFormatValues } from 'react-number-format'
import { NumericFormat } from 'react-number-format'

import { TextField } from './TextField'
import type { NumberTextFieldProps, TextFieldProps } from './types'
import { getLocaleNumberFormat } from './utils'

export const NumberTextField = forwardRef<
  HTMLInputElement,
  NumberTextFieldProps
>((props, ref) => {
  const { locale, isFormattingEnabled, onChange, value, ...rest } = props
  const { groupSeparator, decimalSeparator } = getLocaleNumberFormat(locale)

  return (
    <NumericFormat
      allowLeadingZeros
      customInput={
        TextField as ComponentType<Omit<TextFieldProps, 'size' | 'color'>>
      }
      decimalSeparator={decimalSeparator}
      getInputRef={ref}
      onValueChange={(values: NumberFormatValues) => {
        onChange?.(values?.value)
      }}
      thousandSeparator={isFormattingEnabled ? groupSeparator : undefined}
      value={value}
      {...rest}
    />
  )
})

NumberTextField.displayName = 'NumberTextField'
