import type {
  CompositePriceItem,
  ExternalCatalogData,
  PriceItem,
  PriceWithBlockMappings
} from '@epilot/journey-logic-commons'
import {
  shouldDisplayTaxIndexes,
  extractPriceTaxes,
  extractAndSortItemRecurrences
} from '@epilot/journey-logic-commons'
import type { Currency, PricingDetails } from '@epilot/pricing'
import { useMemo } from 'react'

import { PriceComponents } from '../../../components/PriceComponents'

import { ProductDetailsCashback } from './ProductDetailsCashback'
import classes from './ProductDetailsPriceInformation.module.scss'
import { ProductDetailsPriceInformationTaxes } from './ProductDetailsPriceInformationTaxes'

type ProductDetailsPriceInfoProps = {
  item: PriceWithBlockMappings
  pricingDetails: PricingDetails
  showTrailingDecimalZeros?: boolean
  displayPriceComponents?: boolean
  displayUnitaryAverage?: boolean
  externalCatalogData?: ExternalCatalogData
  blockMappings: PriceItem['price_mappings']
}

export const ProductDetailsPriceInformation = ({
  showTrailingDecimalZeros,
  displayPriceComponents,
  item,
  pricingDetails
}: ProductDetailsPriceInfoProps) => {
  const { items = [] } = pricingDetails

  // There's always only one item in the items array
  const priceItem = items[0]

  const priceComponents =
    priceItem.is_composite_price &&
    (priceItem as CompositePriceItem)?.item_components
      ? (priceItem as CompositePriceItem)?.item_components
      : ([] as PriceItem[])

  /* In case every component uses the same tax, the indexes should be omitted */
  const displayTaxIndexes = useMemo(
    () => shouldDisplayTaxIndexes(pricingDetails.items ?? []),
    [pricingDetails.items]
  )

  const taxes = useMemo(
    () => extractPriceTaxes(priceItem._price),
    [priceItem._price]
  )

  const sortedRecurrenceDetails = extractAndSortItemRecurrences(priceItem)

  const hasOnlyOnetimeRecurrences = sortedRecurrenceDetails.every(
    (recurrence) => recurrence.type === 'one_time' || !recurrence.type
  )

  const cashbacks = pricingDetails.total_details?.breakdown?.cashbacks ?? []

  return (
    <div className={classes.priceInformation}>
      <PriceComponents
        animate={true}
        components={
          priceComponents?.length ? priceComponents : [priceItem as PriceItem]
        }
        displayTaxIndexes={displayTaxIndexes}
        displayUnitaryAverage={displayPriceComponents}
        hasOnlyOnetimeRecurrences={hasOnlyOnetimeRecurrences}
        inTheBox={false}
        item={priceItem}
        pricingDetails={pricingDetails}
        quantity={item.quantity ?? 1}
        recurrenceDetails={sortedRecurrenceDetails}
        showTrailingDecimalZeros={showTrailingDecimalZeros}
        taxes={taxes}
      />
      {cashbacks.map((cashback, i) => (
        <ProductDetailsCashback
          cashback={cashback}
          currency={priceItem.currency as Currency}
          key={`cashback-${cashback.cashback_period}-${i}`}
          showTrailingDecimalZeros={showTrailingDecimalZeros}
        />
      ))}
      {!!taxes.length && (
        <ProductDetailsPriceInformationTaxes
          displayIndexes={displayTaxIndexes}
          taxes={taxes}
        />
      )}
    </div>
  )
}
