import { sort } from 'radashi'

import { isCompositePrice } from '../price'
import type {
  Price,
  Tax,
  TaxAmount,
  PriceItem,
  CompositePriceItem,
  PriceWithBlockMappings
} from '../types'

export const getTaxIndexes = (
  componentTaxes: TaxAmount[] | undefined,
  isComponentPriceTaxInclusive: boolean | undefined,
  taxes: (Tax & { inclusive: boolean })[]
): number[] => {
  const sanitizedComponentTaxes =
    componentTaxes?.map(({ tax }) => tax).filter((tax): tax is Tax => !!tax) ??
    []

  return sanitizedComponentTaxes
    .map(({ _id }) =>
      taxes.findIndex(
        (tax) =>
          tax._id === _id && tax.inclusive === isComponentPriceTaxInclusive
      )
    )
    .filter((index) => index >= 0)
    .map((index) => index + 1)
}

export const shouldDisplayTaxIndexes = (
  priceItems: (PriceItem | CompositePriceItem)[]
): boolean => {
  return !priceItems?.every((item) => {
    /* If it's a simple price, we should always omit the tax indexes */
    if (!item.is_composite_price || !('item_components' in item)) return true

    const [firstComponentTaxIds, ...otherComponentTaxIds] =
      item.item_components?.map(
        (component) =>
          component.taxes
            ?.map(({ tax: { _id } = {} }) => _id)
            .filter((v): v is string => !!v) ?? []
      ) ?? []

    /* Check if every component uses the same taxes */
    return otherComponentTaxIds.every(
      (ids) =>
        ids.length === firstComponentTaxIds.length &&
        ids.every((tax) => firstComponentTaxIds.includes(tax))
    )
  })
}

export type TaxWithTaxInclusivityBehaviour = Tax & {
  inclusive: boolean
  itemIdx?: string
}

/**
 * @private Only needed for extractPriceTaxes
 */
const extractTaxesFromPrice = (
  price: Price
): TaxWithTaxInclusivityBehaviour[] => {
  const taxes = price.tax ?? []

  if (!isPriceTaxATaxArray(taxes)) {
    return []
  }

  return taxes.map<TaxWithTaxInclusivityBehaviour>((tax) => ({
    ...tax,
    inclusive: !!price.is_tax_inclusive
  }))
}

/**
 * @private Only needed for isPriceTaxATaxArray
 */
const isPriceTaxATaxArray = (tax: Price['tax']): tax is Tax[] =>
  !!tax && !('$relation' in tax)

export const deduplicatedTaxesWithInclusivityBehaviour = (
  taxes: TaxWithTaxInclusivityBehaviour[]
): TaxWithTaxInclusivityBehaviour[] => {
  const taxMap = new Map<string, TaxWithTaxInclusivityBehaviour>()

  taxes.forEach((tax) => {
    const key = `${tax._id}${String(tax.inclusive)}`

    if (taxMap.has(key)) {
      const existingTax = taxMap.get(key)!

      existingTax.itemIdx = [
        ...(existingTax.itemIdx ? existingTax.itemIdx.split(', ') : []),
        ...(tax.itemIdx ? tax.itemIdx.split(', ') : [])
      ]
        .filter((v, i, a) => a.indexOf(v) === i) // Ensure uniqueness
        .join(', ')
    } else {
      taxMap.set(key, { ...tax })
    }
  })

  return Array.from(taxMap.values())
}

export const extractPriceTaxes = (
  price: PriceWithBlockMappings | undefined,
  index?: number
): TaxWithTaxInclusivityBehaviour[] => {
  if (!price) {
    return []
  }

  let taxes: TaxWithTaxInclusivityBehaviour[]

  if (isCompositePrice(price)) {
    taxes = ((price.price_components ?? []) as Price[])
      .map(extractTaxesFromPrice)
      .flatMap((taxes) => taxes)
      .filter((tax) => tax)
  } else {
    taxes = extractTaxesFromPrice(price)
  }

  const deduplicatedTaxes =
    deduplicatedTaxesWithInclusivityBehaviour(taxes)?.map((tax) => {
      return {
        ...tax,
        ...(index !== undefined && {
          itemIdx: String(index + 1)
        })
      }
    }) ?? []

  return sort(deduplicatedTaxes, (tax) => tax.rate)
}
