import type { UISchemaElement } from '@jsonforms/core'

import { CONTROL_NAME } from './blocks'

export const blockClassnames: Record<CONTROL_NAME, string> = {
  [CONTROL_NAME.ACCOUNT_CONTROL]: 'Concorde-Account-Block',
  [CONTROL_NAME.ACTION_BAR_CONTROL]: 'Concorde-ActionBar-Block',
  [CONTROL_NAME.ADDRESS_BLOCK]: 'Concorde-Address-Block',
  [CONTROL_NAME.APP_CONTROL]: 'Concorde-App-Block',
  [CONTROL_NAME.AUTH_CONTROL]: 'Concorde-LoginRegistration-Block',
  [CONTROL_NAME.AVAILABILITY_CHECK]: 'Concorde-AvailabilityCheck-Block',
  [CONTROL_NAME.BOOLEAN_CONTROL]: 'Concorde-BinaryInput-Block',
  [CONTROL_NAME.CONFIRMATION_MESSAGE_CONTROL]:
    'Concorde-SuccessConfirmation-Block',
  [CONTROL_NAME.CONTACT_CONTROL]: 'Concorde-Contact-Block',
  [CONTROL_NAME.CONSENTS_CONTROL]: 'Concorde-Consents-Block',
  [CONTROL_NAME.CROSS_SELL_PRODUCT_SELECTION_CONTROL]:
    'Concorde-Products-Block',
  [CONTROL_NAME.CUSTOM_BLOCK_CONTROL]: 'Concorde-Custom-Block',
  [CONTROL_NAME.DATE_PICKER]: 'Concorde-Date-Block',
  [CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL]: 'Concorde-DigitalSignature-Block',
  [CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL]: 'Concorde-AttributeEditor-Block',
  [CONTROL_NAME.ENTITY_LOOKUP_CONTROL]: 'Concorde-EntityFinder-Block',
  [CONTROL_NAME.ENUM_CONTROL]: 'Concorde-SingleChoice-Block',
  [CONTROL_NAME.IMAGE_CONTROL]: 'Concorde-Image-Block',
  [CONTROL_NAME.INPUT_CALCULATOR_CONTROL]: 'Concorde-InputCalculator-Block',
  [CONTROL_NAME.JOURNEY_LAUNCHER_CONTROL]: 'Concorde-JourneyLauncher-Block',
  [CONTROL_NAME.LABEL_CONTROL]: 'Concorde-Paragraph-Block',
  [CONTROL_NAME.METER_READING_CONTROL]: 'Concorde-MeterReading-Block',
  [CONTROL_NAME.METER_READING_DYNAMIC_CONTROL]: 'Concorde-MeterReading-Block',
  [CONTROL_NAME.MULTICHOICE_CONTROL]: 'Concorde-MultipleChoice-Block',
  [CONTROL_NAME.NUMBER_INPUT_CONTROL]: 'Concorde-NumberInput-Block',
  [CONTROL_NAME.PAYMENT_CONTROL]: 'Concorde-PaymentMethod-Block',
  [CONTROL_NAME.PDF_SUMMARY_CONTROL]: 'Concorde-PdfUserInputSummary-Block',
  [CONTROL_NAME.PERSONAL_INFORMATION_CONTROL]:
    'Concorde-PersonalInformation-Block',
  [CONTROL_NAME.PREVIOUS_PROVIDER_CONTROL]: 'Concorde-PreviousProvider-Block',
  [CONTROL_NAME.PRODUCT_CATEGORY_CONTROL]: 'Concorde-ProductCategories-Block',
  [CONTROL_NAME.PRODUCT_SELECTION_CONTROL]: 'Concorde-Products-Block',
  [CONTROL_NAME.PV_ROOF_PLANNER_CONTROL]: 'Concorde-PVRooftopNavigator-Block',
  [CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL]: 'Concorde-HyperlinkButton-Block',
  [CONTROL_NAME.SHOP_CART_CONTROL]: 'Concorde-ShoppingCart-Block',
  [CONTROL_NAME.SUMMARY_CONTROL]: 'Concorde-UserInputSummary-Block',
  [CONTROL_NAME.TEXTFIELD_CONTROL]: 'Concorde-TextInput-Block',
  [CONTROL_NAME.UPLOAD_CONTROL]: 'Concorde-Upload-Block',
  [CONTROL_NAME.GROUP_LAYOUT]: 'Concorde-Group-Layout',
  [CONTROL_NAME.THANK_YOU_CONTROL]: '',
  [CONTROL_NAME.CONTROL]: ''
}

export function getBlockClassname(type: CONTROL_NAME, child?: UISchemaElement) {
  if (!type) return ''

  if (type === CONTROL_NAME.CONTROL) {
    if (child?.options?.options) {
      return blockClassnames[CONTROL_NAME.ENUM_CONTROL]
    }

    if (child?.options?.plainTextLabel !== undefined) {
      return blockClassnames[CONTROL_NAME.BOOLEAN_CONTROL]
    }

    return blockClassnames[CONTROL_NAME.TEXTFIELD_CONTROL]
  }

  return blockClassnames[type]
}
