import type { ContactControlOptions } from './sharedControlOptionsTypes'

type ParsableDate = string | Date | null | undefined

export type LegacyPerson = {
  salutation?: string
  title?: string
  customerType?: string
  companyName?: string
  registryCourt?: string
  registerNumber?: string
  firstName?: string
  lastName?: string
  email?: string
  telephone?: string
  birthDate?: string
  agreement?: boolean
}

type CustomerTypeAndCompany = {
  customerType?: CUSTOMER_TYPE
  companyName?: string
  registryCourt?: string
  registerNumber?: string
}

export type Contact = {
  salutation?: string | null
  title?: string | null
  firstName?: string
  lastName?: string
  birthDate?: ParsableDate
  email?: string
  telephone?: string | null
  customerType?: CUSTOMER_TYPE
  companyName?: string
  registryCourt?: string
  registerNumber?: string
}

export type ContactControlOptionsDto<GridProps> =
  | LegacyPersonalInformationControlOptions<GridProps>
  | ContactControlOptions

export type LegacyPersonalInformationControlOptions<GridProps> = {
  fieldsOrder?: FieldOrder<GridProps>[]

  labels?: {
    [S in keyof LegacyPerson]: string
  }
  errorMessages?: {
    [S in keyof LegacyPerson]: string
  }
  placeHolders?: {
    [S in keyof Omit<
      LegacyPerson,
      'agreement' | 'title' | 'salutation'
    >]: string
  }
  requiredFields?: {
    [S in keyof LegacyPerson]: boolean
  }
  showCondition?: {
    [S in keyof CustomerTypeAndCompany]: string
  }
  salutationType?: GENDER_TYPE
  defaultCustomerTypeAndLabelOptions?: DefaultCustomerTypeAndLabelOptions
}

type FieldOrder<GridProps> = {
  gridItemProps?: GridProps
  name: keyof LegacyPerson | ''
  groupName?: string
}

export enum GENDER_TYPE {
  GENDER3 = 'Mr/Mrs/Diverse',
  GENDER2 = 'Mr/Mrs'
}

export const SALUTATION_OPTIONS = [
  'Mr.',
  'Ms. / Mrs.',
  'Other',
  'Company',
  'Contact Person',
  'Spouse',
  'Family',
  'Ownership',
  'Assembly'
]

export enum CUSTOMER_TYPE {
  PRIVATE = 'Private',
  BUSINESS = 'Business'
}

export type DefaultCustomerTypeAndLabelOptions = {
  defaultCustomerSelection?: string
  customerTypeLabelOptions?: LabelOptions
}

type LabelOptions = {
  privateLabel?: string
  businessLabel?: string
}
