import { Spacing, Typography } from '@epilot/concorde-elements'
import { formatPrice } from '@epilot/journey-logic-commons'
import type { CashbackAmount } from '@epilot/journey-logic-commons'
import type { Currency } from '@epilot/pricing'
import { useTranslation } from 'react-i18next'

import classes from './ProductDetailsCashback.module.scss'

type ProductDetailsCashbackProps = {
  cashback: CashbackAmount
  currency: Currency
  showTrailingDecimalZeros?: boolean
}

export const ProductDetailsCashback = ({
  cashback,
  currency,
  showTrailingDecimalZeros
}: ProductDetailsCashbackProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  if (
    typeof cashback.amount_total === 'undefined' ||
    !cashback.cashback_period
  ) {
    return null
  }

  const formattedPrice = formatPrice(
    -cashback.amount_total,
    currency,
    showTrailingDecimalZeros
  )

  const formattedCashbackPeriod = t(
    `cashback_period.${cashback.cashback_period}`
  )

  return (
    <Spacing
      className={classes.productDetailsCashback}
      justifyContent={'space-between'}
      scale={4}
      variant="inline"
    >
      <Typography variant={'primary'}>
        <strong>{t('coupon.cashback')}</strong>
      </Typography>
      <Spacing justifyContent="flex-end" scale={4} variant="inline">
        <Typography variant={'primary'}>
          <strong>
            {formattedPrice} {formattedCashbackPeriod}
          </strong>
        </Typography>
      </Spacing>
    </Spacing>
  )
}
