import type {
  TabButtonCssProperties,
  TabButtonTab
} from '@epilot/concorde-elements'
import {
  useTheme,
  colorToRgba,
  Divider,
  TabButtonGroup
} from '@epilot/concorde-elements'
import { clsx } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import controlClasses from '../../concorde-renderers/renderers/JourneyLauncherControl/ConcordeJourneyLauncherControl.module.scss'
import { useWrapperStyles } from '../JourneyPage/styles'

import tabsClasses from './ConcordeJourneyLauncherTabs.module.scss'
import type { BaseJourneyLauncherTabsProps } from './types'

export const ConcordeJourneyLauncherTabs = ({
  children,
  isFocusedOnJourney,
  linkedJourneys,
  showPaper,
  selectedJourneyId,
  setSelectedJourneyId
}: BaseJourneyLauncherTabsProps) => {
  const theme = useTheme()
  /* Should move theme computation up to the parent component */
  const styles = useWrapperStyles({ maxWidth: 1256 })

  const containerStyles = useMemo<TabButtonCssProperties>(() => {
    const background = theme.palette.primary
    const { r, g, b } = colorToRgba(background)

    return {
      '--concorde-tab-button-color-components': `${r}, ${g}, ${b}`,
      '--concorde-tab-button-text-color': background
    }
  }, [theme.palette.primary])

  const tabs = useMemo(
    () =>
      linkedJourneys.map<TabButtonTab>(({ id, label, icon }) => ({
        id,
        label,
        icon: icon ?? undefined
      })),
    [linkedJourneys]
  )

  return (
    <div
      className={clsx(
        tabsClasses.outerWrapper,
        !isFocusedOnJourney && tabsClasses.outerWrapperFocused
      )}
    >
      <div
        className={
          !isFocusedOnJourney
            ? clsx(
                controlClasses.container,
                showPaper && controlClasses.containerPaper,
                styles.wrapperStyles,
                tabsClasses.wrapperOverride
              )
            : undefined
        }
      >
        <TabButtonGroup
          activeTabId={selectedJourneyId}
          onTabChange={setSelectedJourneyId}
          style={
            isFocusedOnJourney
              ? { display: 'none', ...containerStyles }
              : containerStyles
          }
          tabs={tabs}
        />

        <Divider
          className={controlClasses.divider}
          style={isFocusedOnJourney ? { display: 'none' } : undefined}
        />
        {children}
      </div>
    </div>
  )
}
