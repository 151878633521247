import { max } from 'radashi'

import { isCompositePrice, isTruthy } from '../../utils'
import type {
  Price,
  CompositePrice,
  BillingDurationUnit,
  PaymentConditionItem,
  PaymentConditionItemBase,
  PaymentConditionType
} from '../types'

type PaymentConditionItemWithOptionalUnit = PaymentConditionItemBase & {
  unit?: BillingDurationUnit | null
}

/**
 * Normalizes a time frequency to days, so that different time frequencies can be compared.
 */
const normalizeFrequency = ({ amount, unit }: PaymentConditionItem): number => {
  switch (unit) {
    case 'weeks':
      return amount * 7
    case 'months':
      return amount * 30
    case 'years':
      return amount * 365
    default:
      return 0
  }
}

const isPaymentConditionItem = (
  item: PaymentConditionItemWithOptionalUnit
): item is PaymentConditionItem =>
  Boolean(
    typeof item.amount === 'number' && !Number.isNaN(item.amount) && item.unit
  )

const extractConditionAmount = (value: unknown): number =>
  typeof value === 'number' ? value : Number(value || undefined)

export const extractPaymentConditionItems = (
  priceComponents: (Price | CompositePrice)[],
  enableCompositePriceConditions: boolean
): PaymentConditionItem[] => {
  const billingDurationItems = extractConditionItemsFrom(
    priceComponents,
    'billing_duration',
    enableCompositePriceConditions
  )
  const noticeTimeItems = extractConditionItemsFrom(
    priceComponents,
    'notice_time',
    enableCompositePriceConditions
  )
  const terminationTimeItems = extractConditionItemsFrom(
    priceComponents,
    'termination_time',
    enableCompositePriceConditions
  )
  const renewalDurationItems = extractConditionItemsFrom(
    priceComponents,
    'renewal_duration',
    enableCompositePriceConditions
  )

  return [
    max(billingDurationItems, normalizeFrequency),
    max(noticeTimeItems, normalizeFrequency),
    max(terminationTimeItems, normalizeFrequency),
    max(renewalDurationItems, normalizeFrequency)
  ].filter(isTruthy)
}

const extractConditionItemsFrom = (
  priceComponents: (Price | CompositePrice)[],
  type: PaymentConditionType,
  enableCompositePriceConditions: boolean
) => {
  if (enableCompositePriceConditions && isCompositePrice(priceComponents[0])) {
    return [toPaymentConditionItem(priceComponents[0], type)].filter(
      isPaymentConditionItem
    )
  }

  const conditionItems = priceComponents
    .map<PaymentConditionItemWithOptionalUnit>((priceComponent) =>
      toPaymentConditionItem(priceComponent, type)
    )
    .filter(isPaymentConditionItem)

  return conditionItems
}

const toPaymentConditionItem = (
  priceComponent: Price | CompositePrice,
  type: PaymentConditionType
): PaymentConditionItemWithOptionalUnit => ({
  type,
  amount: extractConditionAmount(priceComponent[`${type}_amount`]),
  unit: priceComponent[`${type}_unit`]
})

export const PAYMENT_CONDITION_LABEL_TRANSLATION_KEYS: Record<
  PaymentConditionType,
  string
> = {
  billing_duration: 'product_details.payment_conditions.billing_duration',
  notice_time: 'product_details.payment_conditions.notice_time',
  termination_time: 'product_details.payment_conditions.termination_time',
  renewal_duration: 'product_details.payment_conditions.renewal_duration'
}

export const PAYMENT_CONDITION_LABEL_TRANSLATION_DEFAULTS: Record<
  PaymentConditionType,
  string
> = {
  billing_duration: 'Billing Duration',
  notice_time: 'Notice Time',
  termination_time: 'Termination Time',
  renewal_duration: 'Renewal Duration'
}
