import { CONTROL_NAME } from '../../../utils/blocks/types'
import type { UpBlockHandler } from '../types'

import { up as upAccount } from './accountControl'
import { up as upActionBar } from './actionBarControl'
import { up as upAddress } from './addressControl'
import { up as upApp } from './appControl'
import { up as upAuth } from './authControl'
import { up as upAvailabilityCheck } from './availabilityCheckControl'
import { up as upConsents } from './consentsControl'
import { up as upDateInput } from './dateInputControl'
import { up as upEntityAttribute } from './entityAttributeControl'
import { up as upEntityFinder } from './entityFinderControl'
import { up as upGroupLayout } from './groupLayoutControl'
import { up as upHyperlink } from './hyperlinkControl'
import { up as upImage } from './imageControl'
import { up as upInputCalculator } from './inputCalculatorControl'
import { up as upMeterReading } from './meterReadingControl'
import { up as upMultipleChoice } from './multipleChoiceControl'
import { up as upNumberInput } from './numberInputControl'
import { up as upParagraph } from './paragraphControl'
import { up as upPaymentMethod } from './paymentMethodControl'
import { up as upPersonalInformation } from './personalInformationControl'
import { up as upPreviousProvider } from './previousProviderControl'
import { up as upPVRooftopNavigator } from './pvRootopNavigatorControl'
import { up as upShoppingCart } from './shoppingCartControl'
import { up as upSingleChoice } from './singleChoiceControl'
import { up as upSucessMessage } from './successMessageControl'
import { up as upSummary } from './summaryControl'
import { up as upUpload } from './uploadControl'

// TODO: Support all block types
// @ts-expect-error until all blocks are supported
const upHandlerMap: Record<CONTROL_NAME, UpBlockHandler> = {
  [CONTROL_NAME.ACTION_BAR_CONTROL]: upActionBar,
  [CONTROL_NAME.AUTH_CONTROL]: upAuth,
  [CONTROL_NAME.AVAILABILITY_CHECK]: upAvailabilityCheck,
  [CONTROL_NAME.ACCOUNT_CONTROL]: upAccount,
  [CONTROL_NAME.CONSENTS_CONTROL]: upConsents,
  [CONTROL_NAME.DATE_PICKER]: upDateInput,
  [CONTROL_NAME.CONFIRMATION_MESSAGE_CONTROL]: upSucessMessage,
  [CONTROL_NAME.CONTROL]: upSingleChoice,
  [CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL]: upEntityAttribute,
  [CONTROL_NAME.GROUP_LAYOUT]: upGroupLayout,
  [CONTROL_NAME.IMAGE_CONTROL]: upImage,
  [CONTROL_NAME.LABEL_CONTROL]: upParagraph,
  [CONTROL_NAME.MULTICHOICE_CONTROL]: upMultipleChoice,
  [CONTROL_NAME.NUMBER_INPUT_CONTROL]: upNumberInput,
  [CONTROL_NAME.PAYMENT_CONTROL]: upPaymentMethod,
  [CONTROL_NAME.PERSONAL_INFORMATION_CONTROL]: upPersonalInformation,
  [CONTROL_NAME.SECONDARY_ACTION_BAR_CONTROL]: upHyperlink,
  [CONTROL_NAME.SHOP_CART_CONTROL]: upShoppingCart,
  [CONTROL_NAME.SUMMARY_CONTROL]: upSummary,
  [CONTROL_NAME.UPLOAD_CONTROL]: upUpload,
  [CONTROL_NAME.ADDRESS_BLOCK]: upAddress,
  [CONTROL_NAME.INPUT_CALCULATOR_CONTROL]: upInputCalculator,
  [CONTROL_NAME.APP_CONTROL]: upApp,
  [CONTROL_NAME.PV_ROOF_PLANNER_CONTROL]: upPVRooftopNavigator,
  [CONTROL_NAME.ENTITY_LOOKUP_CONTROL]: upEntityFinder,
  [CONTROL_NAME.PREVIOUS_PROVIDER_CONTROL]: upPreviousProvider,
  [CONTROL_NAME.METER_READING_CONTROL]: upMeterReading,
  [CONTROL_NAME.METER_READING_DYNAMIC_CONTROL]: upMeterReading
}

export const upBlockControl = (
  name: string,
  ...args: Parameters<UpBlockHandler>
) => {
  const up = upHandlerMap[name as CONTROL_NAME]

  if (typeof up !== 'function') {
    throw new Error(`Unsupported block type: ${name}`)
  }

  return up(...args)
}
