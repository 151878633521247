import type { TFunction } from 'i18next'
import { unique } from 'radashi'

import {
  ConsentTopics,
  type ConsentData,
  type ConsentTopic
} from '../../consents/types'
import type { UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

type BlockState = Record<ConsentTopic | string, ConsentData>

type CustomTopics = `customTopics.[${string}]`

export const consentsBlockProperties = [
  'generalTerms',
  'dataPrivacy',
  'cancellationPolicy',
  'emailMarketing',
  'smsMarketing',
  'phoneCallMarketing',
  'printMarketing'
] as const

export type ConsentsBlockProperties =
  | (typeof consentsBlockProperties)[number]
  | CustomTopics

const topicsToProperties: Record<ConsentTopic, ConsentsBlockProperties> = {
  GTC: 'generalTerms',
  DATA_PRIVACY: 'dataPrivacy',
  CANCELLATION_POLICY: 'cancellationPolicy',
  EMAIL_MARKETING: 'emailMarketing',
  SMS_MARKETING: 'smsMarketing',
  PHONE_CALL_MARKETING: 'phoneCallMarketing',
  PRINT_MARKETING: 'printMarketing'
}

export const getVariablesFromConsentsBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: ConsentsBlockProperties[] = []
  const customTopics: CustomTopics[] = []

  if (
    uiSchema.options &&
    'configs' in uiSchema.options &&
    Array.isArray(uiSchema.options.configs)
  ) {
    uiSchema.options.configs.forEach((config) => {
      if (config.topics) {
        config.topics.forEach((topic: string) => {
          if (topic in ConsentTopics) {
            properties.push(topicsToProperties[topic as ConsentTopic])
          } else {
            customTopics.push(`customTopics.[${topic}]`)
          }
        })
      }
    })
  }

  return {
    isAccessedByIndex: false,
    properties: unique([...properties, ...customTopics])
  }
}

export const getValuesFromConsentsBlock = ({
  state: blockState,
  t
}: {
  state: unknown
  t: TFunction
}) => {
  if (!blockState) {
    return {}
  }

  const data: Record<string, string | Record<string, string>> = {}
  const customTopics: Record<string, string> = {}
  const blockStateData = blockState as BlockState

  // Process all standard consent topics
  Object.keys(ConsentTopics).forEach((topic) => {
    const topicKey = topic as ConsentTopic
    const propertyName = topicsToProperties[topicKey]

    data[propertyName] = blockStateData[topicKey]?.agreed ? t('Yes') : t('No')
  })

  // Process any custom topics from blockState that aren't in ConsentTopics
  Object.keys(blockStateData).forEach((key) => {
    if (key === '_isValid' || key in ConsentTopics) return

    customTopics[key] = blockStateData[key]?.agreed ? t('Yes') : t('No')
  })

  if (Object.keys(customTopics).length > 0) {
    data['customTopics'] = customTopics
  }

  return data
}
