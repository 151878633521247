import { numericFormatter } from 'react-number-format'

export function getLocaleNumberFormat(localeValue?: string): {
  groupSeparator: string
  decimalSeparator: string
} {
  const locale = localeValue || window.navigator.language

  const number = 1000.1
  const parts = new Intl.NumberFormat(locale).formatToParts(number)

  let groupSeparator = ''
  let decimalSeparator = ''

  for (const part of parts) {
    if (part.type === 'group') {
      groupSeparator = part.value
    }
    if (part.type === 'decimal') {
      decimalSeparator = part.value
    }
  }

  return { groupSeparator, decimalSeparator }
}

export function formatValueLocale({
  groupSeparator,
  decimalSeparator,
  isFormattingEnabled
}: {
  groupSeparator: string
  decimalSeparator: string
  isFormattingEnabled?: boolean
}) {
  return function formatValue(value: string) {
    if (!value) return ''
    const formatted = numericFormatter(value, {
      thousandSeparator: isFormattingEnabled ? groupSeparator : false,
      decimalSeparator,
      allowLeadingZeros: true
    })

    return formatted
  }
}
