import { ConcordeJourneyLauncherTabs } from './ConcordeJourneyLauncherTabs'
import { LegacyJourneyLauncherTabs } from './LegacyJourneyLauncherTabs'
import type { BaseJourneyLauncherTabsProps } from './types'

type JourneyLauncherTabsProps = BaseJourneyLauncherTabsProps & {
  shouldUseConcordeJourneyLauncherTabs: boolean
}

export const JourneyLauncherTabs = ({
  shouldUseConcordeJourneyLauncherTabs,
  ...props
}: JourneyLauncherTabsProps) =>
  shouldUseConcordeJourneyLauncherTabs ? (
    <ConcordeJourneyLauncherTabs {...props} />
  ) : (
    <LegacyJourneyLauncherTabs {...props} />
  )
