import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'

import { useGetJourneyParams } from '../hooks/useGetJourneyParams'
import { instance18n } from '../locales/i18n'
import { getTraceId, TRACE_KEYS } from '../utils/trace'

import { JourneyNotFoundError } from './JourneyNotFoundError'
import { publishCloseJourneyMessage } from './JourneyPage/embedJourneyPublishers'
import { dispatchExitEvent } from './JourneyPage/eventsDispatchers'

type ConfigCheckWrapper = React.PropsWithChildren<unknown>

export function ConfigCheckWrapper({ children }: ConfigCheckWrapper) {
  const { mode, isEmbedded } = useGetJourneyParams()

  const isEnvMissing =
    !['development', 'staging-test'].includes(process.env.NODE_ENV ?? '') &&
    (!window.journeyAppConfig ||
      Object.keys(window.journeyAppConfig).length === 0)

  useEffect(() => {
    if (isEnvMissing) {
      // eslint-disable-next-line no-console
      console.error(
        'Journey App config is missing',
        getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)
      )
    }
  }, [isEnvMissing])

  if (isEnvMissing) {
    return (
      <I18nextProvider i18n={instance18n}>
        <JourneyNotFoundError
          embedMode={mode}
          isEmbedded={isEmbedded}
          onClose={() => {
            dispatchExitEvent()
            publishCloseJourneyMessage({
              journeyId: undefined,
              isDirty: false
            })
          }}
        />
      </I18nextProvider>
    )
  }

  return <>{children}</>
}
