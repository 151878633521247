import { Icon, useIsMobileOrTablet } from '@epilot/concorde-elements'
import type {
  Design,
  EmbedOptions,
  HistoryStack,
  Journey,
  StepState,
  ValueError
} from '@epilot/journey-logic-commons'
import { clsx, getOptimizedFileIdURL } from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { useJourneyContext } from '../../../blocks-renderers'
import {
  JourneyStepper as ConcordeJourneyStepper,
  TopBar as ConcordeTopBar
} from '../../../concorde-renderers'
import { ID_TYPE, uuidToHtmlId } from '../../../concorde-renderers/utils'
import useUpdateTheme from '../../../hooks/useUpdateTheme'
import { env, STEPPER_DESKTOP_LIMIT } from '../../../utils/config'
import { DisplayJson } from '../../DisplayJson'
import type { JourneyStepperProps } from '../../JourneyStepper'
import { JourneyStepper } from '../../JourneyStepper'
import { TopBar } from '../../TopBar'

import classes from './LinearJourney.module.scss'
import type { StepComponentProps } from './StepComponent'
import { StepComponent } from './StepComponent'

export type LinearJourneyProps = {
  activeJourneyEnabled: boolean
  isFocusedOnJourney: boolean
  journey: Journey
  currentStepIndex: number
  debug?: boolean
  onNavigateToStep: JourneyStepperProps['onChangeStepIndex']
  onGoBack: () => void
  onCloseButtonClicked: () => void
  onChange: StepComponentProps['onChange']
  stepsState: StepState[]
  stepsErrors: ValueError[][]
  showTopBar?: boolean
  showCloseButton?: boolean
  isLinearJourney?: boolean
  submitJourneySuccess?: boolean
  remainingSteps?: number
  stack: HistoryStack[]
  mode: EmbedOptions['mode']
  isParentLauncherJourney?: boolean
  parentJourneyId?: string
  isPreview?: boolean
}

export const LinearJourney = ({
  activeJourneyEnabled,
  isFocusedOnJourney,
  journey,
  currentStepIndex,
  debug,
  onNavigateToStep,
  onGoBack,
  onCloseButtonClicked,
  onChange,
  stepsState,
  showTopBar = true,
  isLinearJourney,
  remainingSteps,
  stack,
  submitJourneySuccess,
  mode,
  showCloseButton,
  stepsErrors,
  isParentLauncherJourney,
  parentJourneyId,
  isPreview
}: LinearJourneyProps) => {
  const { context } = useJourneyContext()

  const { logo: designBuiderLogoUrl } = useUpdateTheme()
  const useNewDesign = context?.journey.settings?.useNewDesign
  const { t } = useTranslation()

  // Stepper type
  const isMobileOrTablet = useIsMobileOrTablet()

  const currentStep = journey.steps[currentStepIndex]

  const isTopBarEnabled = mode === 'inline' ? showTopBar : true

  const isProgressBar =
    isLinearJourney === false ||
    (isLinearJourney && journey.steps.length > STEPPER_DESKTOP_LIMIT)

  const isTopBarBackButton = isProgressBar || !isMobileOrTablet

  // use the logo that is passed from Design Builder if it exists
  // otherwise use the logo from the journey
  const logoUrl = designBuiderLogoUrl
    ? designBuiderLogoUrl
    : getLogoUrl(journey.design)

  const data = stepsState[currentStepIndex] ?? {}

  const showInactiveJourneyWarning =
    activeJourneyEnabled && journey?.settings?.isActive === false && !isPreview

  const stepId = currentStep?.stepId

  const topBarProps = {
    isProgressBar,
    isTopBarEnabled,
    journey,
    logoUrl,
    onBack:
      !submitJourneySuccess && isTopBarBackButton && currentStepIndex > 0
        ? onGoBack
        : undefined,
    onClose:
      mode === 'inline' &&
      !(isParentLauncherJourney || parentJourneyId) &&
      showCloseButton !== true
        ? undefined
        : onCloseButtonClicked,

    stepIndex: currentStepIndex
  }

  const stepperProps = {
    currentStepIndex,
    isProgressBar,
    onChangeStepIndex: onNavigateToStep,
    remainingSteps,
    stack,
    steps: journey.steps
  }

  return (
    <div
      className={clsx(
        useNewDesign && 'Concorde-Step',
        useNewDesign ? classes.concordeStepContainer : undefined,
        showInactiveJourneyWarning && classes.inactiveStatusContainer
      )}
      id={useNewDesign ? uuidToHtmlId(ID_TYPE.STEP, stepId) : undefined}
    >
      {isFocusedOnJourney &&
        (useNewDesign ? (
          <ConcordeTopBar
            {...topBarProps}
            showBackButton={currentStepIndex > 0}
          />
        ) : (
          <TopBar {...topBarProps} />
        ))}

      {showInactiveJourneyWarning && (
        <div className={classes.inactiveStatusInfo}>
          <Icon color="black" name="warning" />
          &nbsp;&nbsp;
          <span>
            {t(
              'inactiveJourneyStatusWarningMessage',
              'Inactive journey, activate it in the journey builder to use it outside a test environment'
            )}
          </span>
        </div>
      )}

      {currentStep?.showStepper && isFocusedOnJourney && (
        <>
          {useNewDesign ? (
            <ConcordeJourneyStepper {...stepperProps} />
          ) : (
            <JourneyStepper {...stepperProps} />
          )}
        </>
      )}

      {currentStep && (
        <StepComponent
          data={data}
          debug={debug}
          key={currentStep.stepId} // ref: https://e-pilot.atlassian.net/browse/STABLE360-3452
          onChange={onChange}
          step={{ ...currentStep, stepIndex: currentStepIndex }}
        />
      )}

      {debug && (
        <>
          <DisplayJson data={stepsState[currentStepIndex]} />
          <DisplayJson data={stepsErrors[currentStepIndex]} />
        </>
      )}
    </div>
  )
}

LinearJourney.displayName = 'LinearJourney'

const LOGO_HEIGHT = '30'

function getLogoUrl(design?: Design) {
  if (!design) {
    return ''
  }

  if (design.orgId && design.fileId) {
    return getOptimizedFileIdURL(
      env('REACT_APP_IMAGE_PREVIEW_API_URL'),
      design.orgId,
      design.fileId,
      undefined,
      LOGO_HEIGHT
    )
  }

  return design.logoUrl
}
