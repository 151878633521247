import type { RedeemedPromo } from '@epilot/pricing-client'
/**
 * @todo Understand why root export is not working
 */
import { useCallback, useEffect } from 'react'

import { useJourneyContext } from '../../../../../../blocks-renderers/providers/JourneyContext'

const DEFAULT_REDEEMED_PROMO_CODES = []

export const usePromoCodeValidationState = () => {
  const {
    context: { redeemedPromoCodes: _redeemedPromoCodes },
    updateContext
  } = useJourneyContext()

  /**
   * redeemedPromoCodes should never be undefined, but for a safe rollout
   * we'll handle the case where it is undefined, log an error and use [] as fallback
   * @todo Remove this once we're sure that there's no scenario in prod where it's undefined
   */
  useEffect(() => {
    if (_redeemedPromoCodes === undefined) {
      console.error(
        new Error('redeemedPromoCodes is not set on the journey context')
      )
    }
  }, [_redeemedPromoCodes])

  const redeemedPromos = _redeemedPromoCodes ?? DEFAULT_REDEEMED_PROMO_CODES

  const setRedeemedPromoCodes = useCallback(
    (promoCodes: Array<RedeemedPromo>) =>
      updateContext((prev) => ({ ...prev, redeemedPromoCodes: promoCodes })),
    [updateContext]
  )

  return {
    redeemedPromos,
    setRedeemedPromoCodes
  }
}
