import type { Journey } from '@epilot/journey-logic-commons'
import { JourneyFeatureFlags as FeatureFlags } from '@epilot/journey-logic-commons'
import { useEffect } from 'react'

import { useFlags } from '../../providers'

export const useCustomCSS = (journey: Journey, customCss?: string) => {
  const flags = useFlags()
  // Enable custom css only if the flag is enabled and the journey has the new design
  const isCustomCSSEnabled =
    (flags[FeatureFlags.CUSTOM_CSS] ||
      flags[FeatureFlags.CUSTOM_CSS_TESTING]) &&
    journey.settings?.useNewDesign
  const id = journey.journeyId || journey.name

  // Add custom css to the head
  useEffect(() => {
    if (!isCustomCSSEnabled || !customCss || !id) {
      return
    }

    const styleId = `custom-css-${id}`
    let styleTag = document.getElementById(styleId)

    if (!styleTag) {
      styleTag = document.createElement('style')
      styleTag.id = styleId
      styleTag.innerHTML = customCss

      document.head.appendChild(styleTag)
    } else {
      styleTag.innerHTML = customCss
    }

    // If new nodes are added to the head, re-append the style tag
    const observer = new MutationObserver(() => {
      if (document.head.lastChild !== styleTag) {
        document.head.removeChild(styleTag)
        document.head.appendChild(styleTag)
      }
    })

    // Observe the last child
    observer.observe(document.head, { childList: true })

    return () => {
      if (styleTag) {
        styleTag.remove()
      }
      if (observer) {
        observer.disconnect()
      }
    }
  }, [customCss, id, isCustomCSSEnabled])
}
