import { Spacing, Typography } from '@epilot/concorde-elements'
import type {
  BillingPeriod,
  Price,
  RecurrenceAmount
} from '@epilot/journey-logic-commons'
import {
  getDisplayPrice,
  getPriceType,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import { DEFAULT_CURRENCY } from '@epilot/pricing'
import type { TFunction } from 'i18next'

type PriceComponentsTotalsRecurrenceProps = {
  recurrence: RecurrenceAmount
  showTrailingDecimalZeros?: boolean
  t: TFunction
  quantity: number
  priceDisplayInJourneys: Price['price_display_in_journeys']
  currency?: string
  shouldHideRecurrence?: boolean
}

export const PriceComponentsTotalsRecurrence = ({
  recurrence,
  priceDisplayInJourneys,
  showTrailingDecimalZeros,
  t,
  quantity,
  currency = DEFAULT_CURRENCY,
  shouldHideRecurrence
}: PriceComponentsTotalsRecurrenceProps) => {
  const amountToDisplay = shouldShowDecimals(recurrence.amount_total)
    ? recurrence.amount_total_decimal
    : recurrence.amount_total

  return (
    <Spacing scale={2} variant="inline">
      {quantity > 1 && (
        <Typography data-testid="product-details-recurrence-quantity">
          {`(${quantity} * ${getDisplayPrice(
            t,
            recurrence.unit_amount_gross,
            priceDisplayInJourneys,
            showTrailingDecimalZeros,
            currency
          )})`}
        </Typography>
      )}{' '}
      {!shouldHideRecurrence && (
        <span>
          {t(
            getPriceType(
              recurrence.type as Price['type'],
              recurrence.billing_period as BillingPeriod
            )
          )}{' '}
        </span>
      )}
      <strong data-testid="product-details-recurrence-amount">
        {getDisplayPrice(
          t,
          amountToDisplay,
          priceDisplayInJourneys,
          showTrailingDecimalZeros,
          currency
        )}
      </strong>
    </Spacing>
  )
}
