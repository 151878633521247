import { List as MuiList, ListItem as MuiListItem } from '@material-ui/core'

import type { ListProps, ListItemProps } from './types'

export {
  ListItemText as ListItemContent,
  ListItemIcon as ListItemPrefix,
  ListItemSecondaryAction as ListItemSufix,
  ListSubheader as ListSubheader
} from '@material-ui/core'

export function List(props: ListProps) {
  return <MuiList component="ul" disablePadding={true} {...props} />
}

export function ListItem(props: ListItemProps) {
  const { button = false, ...rest } = props

  // Using `any` as a workaround for a ts issue: https://github.com/mui-org/material-ui/issues/14971
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <MuiListItem button={button as any} {...rest} />
}
