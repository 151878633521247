import type { EpilotTheme } from '@epilot/journey-elements'
import type { Journey } from '@epilot/journey-logic-commons'
import {
  assignIdsToAllSteps,
  isLinearJourney,
  journeyStorage,
  logicStringsToObjects,
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE
} from '@epilot/journey-logic-commons'
import { useCallback, useEffect, useMemo, useReducer } from 'react'

import { publishEmbeddedJourneyMessage } from '../../blocks-renderers'
import { getJourney } from '../../services/journey-service'
import { isLocalOriginEventAllowed, env } from '../../utils/config'
import { setManifest as setPwaManifest } from '../../utils/setManifest'
import { useCheckNonce } from '../useCheckNonce'
import { useGetJourneyParams } from '../useGetJourneyParams'
import type { DataInjectionOptions } from '../useMessageHandler/types'

import {
  initialReducerState,
  reducer,
  setConfigJourneyErrorAction,
  setConfigJourneySuccessAction,
  setLoadingJourneyAction,
  setMultipleJourneyStateAction,
  useDocumentLanguage
} from './utils'

export const useGetJourney = ({
  journeyIdOverride
}: {
  journeyIdOverride?: string
}) => {
  const [configState, dispatchConfigStateChange] = useReducer(
    reducer,
    initialReducerState
  )
  const {
    journeyId: paramJourneyId,
    preview,
    debug: debugParam,
    topBar: topBarParam,
    mode: modeParam,
    contextData,
    journeyLanguage,
    isEmbedded,
    dataInjectionOptions,
    error
  } = useGetJourneyParams()

  const journeyId = journeyIdOverride ?? paramJourneyId

  useDocumentLanguage(journeyLanguage)

  useEffect(() => {
    if (preview) {
      publishEmbeddedJourneyMessage(
        EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.ASSETS_READY,
        ''
      )
    }
  }, [preview])

  const { isNonceUsed } = useCheckNonce(contextData?.nonce)

  const handleMessage = (event: MessageEvent) => {
    /**
     * @todo originalEvent is not a property of MessageEvent,
     * it should be removed (was probably taken from example
     * in which function is passed to jQuery's on())
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const origin = event.origin || (event as any).originalEvent.origin

    if (
      origin !== env('REACT_APP_JOURNEY_BUILDER_URL') &&
      !isLocalOriginEventAllowed(origin)
    ) {
      return
    }
    const data = event.data

    if (
      typeof data == 'object' &&
      data !== null &&
      data?.detail?.eventCaller === 'JournyBuilder'
    ) {
      // Do something with event.data.value;
      const {
        stepIndex,
        journey,
        debug,
        initialState,
        blocksDisplaySettings,
        token
      } = data.detail.payload

      if (journey) {
        const decodedData = decodeURIComponent(atob(journey))

        if (token) {
          journeyStorage.setItem('token', token)
        }

        // only parse data if not undefined, or if not string of undefined (due to decoding)
        const journeyConfig =
          !decodedData || decodedData === 'undefined'
            ? undefined
            : JSON.parse(decodedData)

        dispatchConfigStateChange(
          setMultipleJourneyStateAction({
            journeyConfig,
            isLoading: false,
            debug,
            stepIndex,
            ...(Array.isArray(initialState) && { initialState: initialState }),
            blocksDisplaySettings
          })
        )
      }
    }
  }

  const loadJourney = useCallback(
    async (id: string | null) => {
      if (!id) {
        return void dispatchConfigStateChange(setLoadingJourneyAction(false))
      }

      try {
        dispatchConfigStateChange(setLoadingJourneyAction(true))
        const journey = await getJourney(id)

        dispatchConfigStateChange(setConfigJourneySuccessAction(journey))
      } catch (originalError) {
        const error = new Error('Failed to fetch the Journey')

        error.cause = originalError
        // eslint-disable-next-line no-console
        console.error(error)

        dispatchConfigStateChange(setConfigJourneyErrorAction())
      }
    },
    [dispatchConfigStateChange]
  )

  useEffect(() => {
    // load journey if no nonce provided, or if nonce is provided and is not used
    if (!contextData?.nonce || isNonceUsed === false) {
      loadJourney(journeyId)
    }
  }, [journeyId, loadJourney, contextData?.nonce, isNonceUsed])

  useEffect(() => {
    const journeyConfig = configState?.journeyConfig

    if (journeyConfig?.name) {
      setPwaManifest(
        journeyConfig.name,
        journeyConfig.design?.logoUrl,
        (journeyConfig.design?.theme as EpilotTheme)?.palette?.primary?.main,
        (journeyConfig.design?.theme as EpilotTheme)?.palette?.background
          ?.default
      )
    }
  }, [configState?.journeyConfig])

  /**
   * @todo Refactor useGetJourney to use react-query rather than emitting events
   */
  useEffect(() => {
    window.addEventListener('message', handleMessage, false)

    return () => window.removeEventListener('message', handleMessage)
  }, [])

  const setJourney = useCallback(
    (newJourney: Journey) =>
      dispatchConfigStateChange(setConfigJourneySuccessAction(newJourney)),
    [dispatchConfigStateChange]
  )

  const journey = useMemo(() => {
    const data = configState.journeyConfig

    return data?.steps ? assignIdsToAllSteps(data) : data
  }, [configState.journeyConfig])

  // WARNING: expensive operation, shouldn't run on every render
  const isLinear = useMemo(
    () =>
      isLinearJourney(journey?.steps, logicStringsToObjects(journey?.logics)),
    [journey?.steps, journey?.logics]
  )

  // decode base64 string dataInjectionOptions
  const dataInjectionOptionsParam: DataInjectionOptions | undefined =
    useMemo(() => {
      try {
        if (dataInjectionOptions) {
          return JSON.parse(atob(dataInjectionOptions))
        }
      } catch {
        return undefined
      }
    }, [dataInjectionOptions])

  return {
    isLoading: configState.isLoading,
    debug: debugParam || configState.debug,
    stepIndex: configState.stepIndex ? Number(configState.stepIndex) : 0,
    journey,
    error,
    initialState: configState.initialState,
    preview,
    journeyId,
    contextData,
    isLinearJourney: isLinear,
    dispatch: dispatchConfigStateChange,
    setJourney,
    topBarParam,
    modeParam,
    journeyLanguage,
    isEmbedded,
    dataInjectionOptionsParam: dataInjectionOptionsParam,
    isNonceUsed
  }
}
