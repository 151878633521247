import type { BaseEntity } from '@epilot/entity-client'
import type { PropsWithChildren } from 'react'

import type { AuthServiceError } from '../../services/auth-service/errors/AuthServiceError'

export type AuthenticatedUserContextProps = PropsWithChildren<{}>

export type AuthenticatedUserContextValues = {
  /** Current auth step */
  authStep: AuthStep
  /** Current auth status */
  authStatus: AuthStatus
  /** Signed in user, if any */
  user?: AuthenticatedUser
  /** Any error returned by the service API calls */
  getLastError: () => AuthServiceError | Error | undefined
  /** Whether service API calls are in progress */
  isPending: boolean
  signIn: (email: string) => Promise<AuthStep | void>
  verifySignIn: (oneTimePassword: string) => Promise<void>
  signOut: () => Promise<void>
  restart: () => void
}

/**
 * Currently this maps to a generic contact entity, but should change
 * in the future and become a more specific type.
 */
export type AuthenticatedUser = {
  /**
   * Email address associated with the authenticated user account.
   *
   * This comes directly from the user's JWT token and might differ from the
   * contact entity's email address.
   */
  email?: string
  /**
   * Associated contact entity, which contains additional information about the user
   * like name, address, etc.
   */
  contact?: BaseEntity
}

export enum AuthStatus {
  /**
   * Auth logic and context are not yet ready
   *
   * Recommended to show a loading indicator during this state and block
   * UI interactions that depend on the auth layer being ready.
   */
  CONFIGURING = 'configuring',
  /** User is authenticated */
  AUTHENTICATED = 'authenticated',
  /** User is currently NOT authenticated */
  UNAUTHENTICATED = 'unauthenticated'
}

export enum AuthStep {
  IDLE = 'idle',
  SIGN_IN = 'sign in',
  SIGN_IN_CHALLENGE = 'sign in challenge',
  SIGN_UP = 'sign up',
  SIGN_OUT = 'sign out'
}
