import { Link } from '@epilot/concorde-elements'
import ReactMarkdown from 'react-markdown'

// replace <p> with <span> and <a> with <Link> etc
function getComponentsMapping() {
  return {
    p: function ParagraphComponent({ ...props }) {
      return <span {...props} />
    },
    a: function LinkComponent({ ...props }) {
      return <Link target="_blank" {...props} />
    }
  }
}

type MarkdownRendererProps = {
  str: string
}

export const MarkdownRenderer = ({ str }: MarkdownRendererProps) => {
  return (
    <ReactMarkdown components={getComponentsMapping()}>{str}</ReactMarkdown>
  )
}
