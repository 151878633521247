import type { EpilotControlProps } from '@epilot/journey-logic-commons'

export type AuthControlSkeletonProps = Pick<EpilotControlProps, 'uischema'>

export enum AUTH_FLOW {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  SKIP = 'skip'
}

interface IAuthControlExports {
  /**
   * The authentication flow taken by the end-user.
   * If skipped, consider {@link AUTH_FLOW.SKIP}.
   */
  flow?: AUTH_FLOW
  /**
   * Email address entered by the user.
   * Used in sign in and sign up flows.
   */
  email?: string
  /**
   * One time password entered by the user.
   * Used in the sign in flow.
   *
   * Can be empty if the user was previously signed in and their session was automatically
   * recovered from the Auth token.
   */
  oneTimePassword?: string
}

export interface AuthControlSkipped extends IAuthControlExports {
  flow: AUTH_FLOW.SKIP
  email?: never
  oneTimePassword?: never
}

export interface AuthControlSignInFilled extends IAuthControlExports {
  flow: AUTH_FLOW.SIGN_IN
  /** Can be empty if still restoring from auth token */
  email?: string
  /** Can be empty if restored from existing Auth token */
  oneTimePassword?: string
}

export interface AuthControlSignUpFilled extends IAuthControlExports {
  flow: AUTH_FLOW.SIGN_UP
  email: string
  oneTimePassword?: never
}

export type AuthControlFilled =
  | AuthControlSignInFilled
  | AuthControlSignUpFilled

export type AuthExports = AuthControlSkipped | AuthControlFilled
