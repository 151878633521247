import type { Config } from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'
import { createContext, useContext } from 'react'

// Create a context for the configuration with a default empty object
const ConfigContext = createContext<Config | undefined>(undefined)

// Custom hook to use the Config context
export const useConfig = (): Config => {
  const context = useContext(ConfigContext)

  if (!context) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}

type ConfigProviderProps = Omit<
  ComponentProps<typeof ConfigContext.Provider>,
  'value'
> & { config: Config }

// Provider component to wrap your app and provide the config
export const ConfigProvider = ({ config, ...props }: ConfigProviderProps) => (
  <ConfigContext.Provider {...props} value={config} />
)
