import { DEFAULT_PRODUCT_IMAGE_BASE64, Image } from '@epilot/concorde-elements'
import type { Product } from '@epilot/journey-logic-commons'

import { useConfig } from '../../../../../blocks-renderers/providers/ConfigContext'
import { useOptimizedProductImages } from '../../../../utils/hooks'

import classes from './ProductDetailsImage.module.scss'

type ProductDetailsImageProps = {
  product: Product
}

export const ProductDetailsImage = ({ product }: ProductDetailsImageProps) => {
  const { IMAGE_PREVIEW_API_URL } = useConfig()
  const optimizedImages = useOptimizedProductImages(
    product,
    IMAGE_PREVIEW_API_URL
  )

  if (optimizedImages.length === 0 || !IMAGE_PREVIEW_API_URL) {
    return (
      <div className={classes.imageWrapper}>
        <Image
          alt="Box within a gray background representing an imageless product."
          className={classes.image}
          src={DEFAULT_PRODUCT_IMAGE_BASE64}
        />
      </div>
    )
  } else {
    const image = optimizedImages[0]

    return (
      <div className={classes.imageWrapper}>
        <Image alt={image.filename} className={classes.image} src={image.src} />
      </div>
    )
  }
}
