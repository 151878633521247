import { Auth } from '@aws-amplify/auth'
import { journeyStorage } from '@epilot/journey-logic-commons'

import { getCustomerPortalClient } from '../../clients'

import { InvalidChallengeError } from './errors/FailedChallengeError'
import { NotAuthorizedError } from './errors/NotAuthorizedError'
import { UnexpectedAuthError } from './errors/UnexpectedAuthError'
import { UserNotConfirmedError } from './errors/UserNotConfirmedError'
import { UserNotFoundError } from './errors/UserNotFoundError'

export const configure = (userPoolId: string, userPoolClientId: string) => {
  Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
    region: 'eu-central-1',
    userPoolId: userPoolId,
    userPoolWebClientId: userPoolClientId
  })
}

export const getPortalConfig = async (
  apiUrl: string,
  organizationId: string
) => {
  return getCustomerPortalClient(apiUrl).getPublicPortalConfig({
    origin: 'END_CUSTOMER_PORTAL',
    org_id: organizationId
  })
}

export const startSignIn = async (email: string) => {
  try {
    const response = await Auth.signIn(email, undefined, {
      challengeType: 'JOURNEY_SIGN_IN_INITIATED'
    })

    if (
      response?.challengeName === 'CUSTOM_CHALLENGE' &&
      response?.challengeParam?.challengeType ===
        'PROVIDE_OTP_TO_SIGN_IN_FROM_JOURNEY'
    ) {
      return response
    }

    throw new Error('Unexpected response')
  } catch (originalError) {
    let error: Error

    if (
      originalError instanceof Error &&
      originalError.name === 'NotAuthorizedException'
    ) {
      error = new UserNotFoundError()
    } else if (
      originalError instanceof Error &&
      originalError.name === 'UserNotConfirmedException'
    ) {
      error = new UserNotConfirmedError()
    } else {
      error = new UnexpectedAuthError('Failed to start sign in')
    }

    error.cause = originalError

    throw error
  }
}

export const answerSignInChallenge = async (
  session: Awaited<ReturnType<typeof startSignIn>>,
  oneTimePassword: string
) => {
  try {
    const response = await Auth.sendCustomChallengeAnswer(
      session,
      oneTimePassword,
      {
        challengeType: 'JOURNEY_SIGN_IN_OTP_SENT'
      }
    )

    if (
      response.challengeName === 'CUSTOM_CHALLENGE' &&
      response.challengeParam?.challengeType ===
        'PROVIDE_OTP_TO_SIGN_IN_FROM_JOURNEY'
    ) {
      const attemptsLeft = parseInt(response.challengeParam?.attemptsLeft, 10)

      throw new InvalidChallengeError(isNaN(attemptsLeft) ? 0 : attemptsLeft)
    }

    const currentSession = await Auth.currentSession()

    const cognitoToken = currentSession.getIdToken()
    const token = cognitoToken.getJwtToken()

    if (token) {
      journeyStorage.setItem('token', token)
    }

    return response
  } catch (originalError) {
    let error: Error

    if (originalError instanceof InvalidChallengeError) {
      throw originalError // rethrow
    }

    if (
      originalError instanceof Error &&
      originalError.name === 'NotAuthorizedException'
    ) {
      error = new NotAuthorizedError()
    } else if (
      originalError instanceof Error &&
      originalError.name === 'UserNotConfirmedException'
    ) {
      error = new UserNotConfirmedError()
    } else {
      error = new UnexpectedAuthError('Failed to answer sign in challenge')
    }

    error.cause = originalError

    throw error
  }
}

export const signOut = async () => {
  try {
    journeyStorage.removeItem('token')
    await Auth.signOut()
  } catch {
    // fail gracefully
  }
}
