import type {
  DisplayBlockLogic,
  DisplayConditionsStatus,
  Journey,
  StepState
} from '@epilot/journey-logic-commons'
import {
  LogicTriggerEventName,
  isConditionTrue
} from '@epilot/journey-logic-commons'

import { changeBlockVisibilityState } from '../../utils/changeBlockVisibilityState'

export const applyDisplayLogics = async (
  journey: Journey,
  displayLogics: DisplayBlockLogic[][],
  stepsState: StepState[],
  stepIndex: number,
  triggerEvent: LogicTriggerEventName,
  displayConditionsStatus: DisplayConditionsStatus,
  triggerBlockNames?: string[] // If undefined / no triggers, apply every display logic
) => {
  const stepDisplayLogics = displayLogics[stepIndex]

  const displayLogicsToApply = triggerEvent
    ? stepDisplayLogics?.filter((logic) => {
        if (triggerEvent === LogicTriggerEventName.VALUE_CHANGE) {
          return (
            logic.conditionsRelatedToStep[0].event === triggerEvent &&
            (triggerBlockNames?.includes(logic.blockName) || !triggerBlockNames)
          )
        }

        // we don't support other events (by default we have this logic type only to support value change)
        return false
      }) || []
    : []

  let logicsCount = displayLogicsToApply.length
  let updatedStepsState = [...stepsState]
  const stepState = updatedStepsState[stepIndex] || {}

  for (const logic of displayLogicsToApply) {
    const actions = logic.actionsIncludesDisplay
    const conditions = logic.conditionsRelatedToStep
    const blockName = logic.blockName

    // Note: If a block value isn't set (equals `undefined` or `null`), we consider it as false
    // so that it matches conditions asserting false/falsy values down the road
    // This is especially important for checkboxes that are considered `null` when not checked,
    // and should match against "equal false" conditions
    const blockState =
      stepState[blockName] == undefined ? false : stepState[blockName]

    if (blockName && conditions && conditions.length > 0 && actions) {
      const display = await getDisplay(
        conditions[0],
        blockState,
        actions[0].conditionResult
      )

      const targetStepIndex = actions[0].targetStepIndex
      const targetBlockName = actions[0].targetBlockName

      const extraBlockNames = changeBlockVisibilityState(
        { ...journey.steps[targetStepIndex], stepIndex: targetStepIndex },
        targetBlockName,
        display
      )

      if (!display) {
        if (!updatedStepsState[targetStepIndex]) {
          updatedStepsState[targetStepIndex] = {}
        }
        updatedStepsState[targetStepIndex][targetBlockName] = undefined
        if (extraBlockNames) {
          for (const extraBlockName of extraBlockNames) {
            updatedStepsState[targetStepIndex][extraBlockName] = undefined
          }
        }

        const recursiveInfo = await applyDisplayLogics(
          journey,
          displayLogics,
          updatedStepsState,
          targetStepIndex,
          triggerEvent,
          displayConditionsStatus,
          [targetBlockName]
        )

        logicsCount = logicsCount + recursiveInfo.logicsCount
        updatedStepsState = recursiveInfo.updatedStepsState
      }

      registerDisplayConditionStatus(
        displayConditionsStatus,
        targetStepIndex,
        targetBlockName,
        stepIndex,
        blockName,
        display
      )
    }
  }

  return {
    logicsCount: displayLogicsToApply.length,
    updatedStepsState: updatedStepsState
  }
}

/**
 * Register the display condition status.
 */
const registerDisplayConditionStatus = (
  displayConditionsStatus: DisplayConditionsStatus,
  targetStepIndex: number,
  targetBlockName: string,
  triggerStepIndex: number,
  triggerBlockName: string,
  displayValue: boolean
) => {
  displayConditionsStatus[`${targetStepIndex}/${targetBlockName}`] = {
    display: displayValue,
    trigger: `${triggerStepIndex}/${triggerBlockName}`
  }
}

const getDisplay = async (condition, blockState, conditionResult) => {
  return (await isConditionTrue(condition, blockState)) === conditionResult
    ? true
    : false
}
