export function formatNumberInUserLocale(value: string, useGrouping?: boolean) {
  const cleanedValue = value.replace(/[^\d,.]/g, '')
  const number = parseFloat(cleanedValue.replace(',', '.'))

  if (isNaN(number)) return value

  if (!window.navigator.language)
    return new Intl.NumberFormat('de-DE', {
      useGrouping: !!useGrouping
    }).format(number)

  return new Intl.NumberFormat(window.navigator.language || 'de-DE', {
    useGrouping: !!useGrouping
  }).format(number)
}
