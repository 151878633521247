import { transformMuiThemeToConcordeTheme } from '@epilot/concorde-elements'
import type { Journey } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import type useUpdateTheme from '../../hooks/useUpdateTheme'

import { decodeTheme, useJourneyThemeTransformed } from './utils'

export const useThemes = ({
  journey,
  activeJourney,
  designBuilderTheme,
  designBuilderDesignTokens
}: {
  journey: Journey
  activeJourney: Journey
  designBuilderTheme: ReturnType<typeof useUpdateTheme>['theme']
  designBuilderDesignTokens: ReturnType<typeof useUpdateTheme>['designTokens']
}) => {
  const decodedTheme = useMemo(
    () => decodeTheme(designBuilderTheme),
    [designBuilderTheme]
  )

  const theme = useJourneyThemeTransformed(
    journey,
    decodedTheme ?? activeJourney.design?.theme
  )

  const designTokens =
    designBuilderDesignTokens ?? activeJourney.design?.designTokens

  const transformedTheme = useMemo(
    () => transformMuiThemeToConcordeTheme(theme),
    [theme]
  )

  return { theme, transformedTheme, designTokens }
}
