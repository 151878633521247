import type { StepExtended, StepState } from '@epilot/journey-logic-commons'
import type { JsonFormsReactProps } from '@jsonforms/react'
import React, { Suspense, useMemo } from 'react'

import { useJourneyContext } from '../../../blocks-renderers/providers'
import { SpinnerPage } from '../../SpinnerPage'
import { protectAgainstRequiredNoUischema } from '../utils'

import { StepHeader } from './StepHeader'

const StepContent = React.lazy(() => import('./StepContent'))

export type StepComponentProps = {
  data: StepState
  debug?: boolean
  onChange: JsonFormsReactProps['onChange']
  step: StepExtended
}

export const StepComponent = ({
  data,
  debug,
  onChange,
  step
}: StepComponentProps) => {
  // clean up the step from any potential required blocks that are not in the uischema
  const requiredBlocks = step.schema?.required
  const uischema = step.uischema

  const cleanedRequired = useMemo(
    () => protectAgainstRequiredNoUischema(uischema, requiredBlocks),
    [uischema, requiredBlocks]
  )

  const schema = useMemo(() => {
    if (cleanedRequired.length !== requiredBlocks?.length) {
      return { ...step.schema, required: cleanedRequired }
    }

    return step.schema
  }, [cleanedRequired, requiredBlocks, step.schema])

  const hasRequiredField = Boolean(
    schema?.required && schema.required.length > 0
  )

  const { context } = useJourneyContext()
  const useNewDesign = context.journey.settings?.useNewDesign

  return (
    <>
      {!useNewDesign && (
        <StepHeader hasRequiredField={hasRequiredField} step={step} />
      )}

      <Suspense fallback={<SpinnerPage />}>
        <StepContent
          data={data}
          debug={debug}
          onChange={onChange}
          schema={schema}
          step={step}
          uischema={uischema}
        />
      </Suspense>
    </>
  )
}

StepComponent.displayName = 'StepComponent'
