import { Spacing } from '@epilot/concorde-elements'
import type { PaymentConditionItem } from '@epilot/journey-logic-commons'

import { ProductDetailsPaymentConditionsItem } from './ProductDetailsPaymentConditionsItem'

type ProductDetailsPaymentConditionsProps = {
  conditions: PaymentConditionItem[]
}

export const ProductDetailsPaymentConditions = ({
  conditions
}: ProductDetailsPaymentConditionsProps) => {
  return (
    <Spacing scale={2} variant="stack">
      {conditions.map((condition, index) => (
        <ProductDetailsPaymentConditionsItem
          item={condition}
          key={`payment_condition_${index}`}
        />
      ))}
    </Spacing>
  )
}
