import { getClient } from '@epilot/pricing-client'

export const getPricingClient = (
  apiUrl: string,
  orgId?: string,
  token?: string
) => {
  const client = getClient()

  client.defaults.baseURL = apiUrl

  if (orgId) {
    client.defaults.headers['X-Ivy-Org-ID'] = orgId
  }

  if (token) {
    client.defaults.headers['Authorization'] = `Bearer ${token}`
  }

  return client
}
