import type { Theme } from '@epilot/journey-elements'
import {
  makeStyles,
  createStyles,
  DefaultEpilotTheme
} from '@epilot/journey-elements'
import type { CSSProperties } from 'react'

export const useTabContentStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1)
  },
  paper: {
    borderRadius:
      theme.shape?.borderRadius !== undefined
        ? `min(${theme.shape.borderRadius}px, 20px)`
        : '4px'
  }
}))

export const useWrapperStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      position: 'relative',
      padding: theme.spacing(4),
      paddingBottom: 0,
      maxWidth: 1256,
      margin: '0 auto',
      [theme.breakpoints.between('xs', 'md')]: {
        maxWidth: 936
      },
      width: '100%'
    },
    container: {
      display: 'flex'
    },
    spaceBetween: {
      justifyContent: 'space-between'
    },
    flexEnd: {
      justifyContent: 'flex-end'
    },
    stepNameContainer: {
      [theme.breakpoints.up('sm')]: {
        width: '70%'
      }
    },
    subtitle: {
      textAlign: 'justify',
      [theme.breakpoints.up('sm')]: {
        width: '70%'
      }
    },
    labelContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    label: {
      marginLeft: theme.spacing(3)
    }
  })
})

export const useLauncherTabsStyles = makeStyles((theme) => ({
  tabLabel: {
    lineHeight: 1.25,
    'overflow-y': 'hidden',
    'text-overflow': 'ellipsis',
    textAlign: 'left',
    /* Ensures text doesn't go over two lines, on webkit browsers only */
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  tabs: {
    background: 'transparent'
  },
  tab: {
    minHeight: '24px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '30px'
    },
    '& > span': {
      flexDirection: 'row',
      gap: '0.75rem'
    },
    cursor: 'pointer',
    borderRadius: '8px',
    border: '1px solid #6F7C8A',
    opacity: 0.8,
    background: '#FCFEFF',
    [theme.breakpoints.up('sm')]: {
      border: 'none',
      boxShadow: theme.shadows[1],
      marginTop: '1px',
      borderRadius: '8px 8px 0px 0px',
      background: '#FCFEFF'
    }
  },
  tabSelected: (props: { ctaColor?: string; ctaText?: string }) => ({
    background: `${props?.ctaColor ?? 'white'} !important`,
    color: props?.ctaText ?? DefaultEpilotTheme.palette?.info.contrastText,
    border: 'none',
    opacity: 1,
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      background: 'white !important',
      color: '#172B4D'
    }
  }),
  tabContainer: {
    padding: '1rem 0rem',
    [theme.breakpoints.up('sm')]: {
      padding: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap'
      }
    },
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      margin: '0px'
    },
    '& .MuiTabs-flexContainer': {
      gap: 10
    },
    '& .MuiTab-labelIcon': {
      minHeight: 'unset'
    }
  },
  tabContentWrapper: {
    background: (theme?.overrides?.MuiPaper?.root as CSSProperties)
      ?.backgroundColor,
    borderRadius: '0px 8px 8px 8px'
  },
  tabContentWrapperShadow: {
    boxShadow: theme.shadows[1]
  },
  showOnLg: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginBottom: '0px'
    }
  },
  divider: {
    display: 'block',
    color: '#C5D0DB',
    margin: '0px',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  noMargin: {
    margin: 0
  },
  journeyLauncherWrapper: {
    width: '100%'
  },
  journeyLauncherWrapperInitialState: {
    maxWidth:
      'var(--concorde-custom-layout-max-width, var(--concorde-layout-max-width, 1256px))',
    padding: 20,
    paddingRight: 44,
    paddingLeft: 44,
    marginRight: 9,
    marginLeft: 9
  }
}))

export const useEmptyLauncherStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: 'inline-block',
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px 0 30px 0',
    width: '100%'
  },
  textContainer: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.typography.body1.color
  },
  icon: {
    maxWidth: '120px',
    width: '100%',
    height: 'auto'
  }
}))
