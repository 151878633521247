import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { usePortalConfig } from '../../hooks/usePortalConfig'
import { useJourneyContext } from '../../providers/JourneyContext'
import {
  configure,
  answerSignInChallenge,
  startSignIn,
  signOut as _signOut
} from '../../services/auth-service'
import { AuthServiceError } from '../../services/auth-service/errors/AuthServiceError'
import { UserNotFoundError } from '../../services/auth-service/errors/UserNotFoundError'
import { getEmailFromToken, getPortalToken, usePortalCheck } from '../../utils'

import { AuthStatus, AuthStep } from './types'
import type {
  AuthenticatedUserContextValues,
  AuthenticatedUserContextProps
} from './types'

export const AuthenticatedUserContext =
  createContext<AuthenticatedUserContextValues>({
    authStep: AuthStep.IDLE,
    authStatus: AuthStatus.CONFIGURING,
    isPending: false,
    getLastError: () => undefined,
    restart: () => void 0,
    signIn: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
    verifySignIn: () => Promise.resolve()
  })

export const AuthenticatedUserProvider = (
  props: AuthenticatedUserContextProps
) => {
  const { updateContext, context } = useJourneyContext()
  const { isECPPortal } = usePortalCheck()
  const configQuery = usePortalConfig({
    organizationId: context.journey?.organizationId
  })
  const [isPending, setIsPending] = useState<boolean>(false)
  const [authenticatedUserEmail, setAuthenticatedUserEmail] = useState<string>()
  const [authStep, setAuthStep] = useState<AuthStep>(AuthStep.IDLE)
  const [authStatus, setAuthStatus] = useState<AuthStatus>(
    AuthStatus.CONFIGURING
  )
  const errorRef = useRef<AuthServiceError>()
  const [session, setSession] =
    useState<Awaited<ReturnType<typeof startSignIn>>>()

  const signIn = useCallback<AuthenticatedUserContextValues['signIn']>(
    async (email) => {
      try {
        setAuthStep(AuthStep.SIGN_IN)
        setIsPending(true)
        errorRef.current = undefined
        setSession(undefined)

        const newSession = await startSignIn(email.toLowerCase().trim())
        const next = AuthStep.SIGN_IN_CHALLENGE

        setAuthStep(next)
        setSession(newSession)

        return next
      } catch (error) {
        if (error instanceof UserNotFoundError) {
          const next = AuthStep.SIGN_UP

          setAuthStep(next)

          return next
        }

        if (error instanceof AuthServiceError) {
          errorRef.current = error
        }

        throw error // rethrow
      } finally {
        setIsPending(false)
      }
    },
    []
  )

  const verifySignIn = useCallback<
    AuthenticatedUserContextValues['verifySignIn']
  >(
    async (code) => {
      try {
        setIsPending(true)
        errorRef.current = undefined

        const response = await answerSignInChallenge(session, code)

        // TODO: can we rely on checking the username? Can we rely on only one state?
        if (response?.username) {
          setAuthStep(AuthStep.IDLE)
          setAuthStatus(AuthStatus.AUTHENTICATED)
          updateContext((prev) => ({ ...prev, isAuthenticated: true }))
          setAuthenticatedUserEmail(response.username)
        }
      } catch (error) {
        if (error instanceof AuthServiceError) {
          errorRef.current = error
        }

        throw error // rethrow
      } finally {
        setIsPending(false)
      }
    },
    [session, updateContext]
  )

  const signOut = useCallback<
    AuthenticatedUserContextValues['signOut']
  >(async () => {
    try {
      await _signOut()
    } catch (originalError) {
      const error = new Error('Failed to sign out')

      error.cause = originalError

      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      window.location.reload() // refresh the page
    }
  }, [])

  const restart = useCallback(() => {
    if (AuthStatus.UNAUTHENTICATED) {
      setAuthStep(AuthStep.IDLE)
      errorRef.current = undefined
    }
  }, [])

  const authenticatedUser = useMemo<
    AuthenticatedUserContextValues['user']
  >(() => {
    if (authStatus === AuthStatus.AUTHENTICATED) {
      return {
        email: authenticatedUserEmail,
        contact: context._contextEntitiesData?._logged_in_contact
      }
    }

    return undefined
  }, [
    authStatus,
    authenticatedUserEmail,
    context._contextEntitiesData?._logged_in_contact
  ])

  // Check if the user is already authenticated
  useEffect(() => {
    async function checkAuth() {
      if (isECPPortal) {
        const token = await getPortalToken()

        if (token) {
          setAuthStatus(AuthStatus.AUTHENTICATED)
          updateContext((prev) => ({ ...prev, isAuthenticated: true }))
          setAuthenticatedUserEmail(getEmailFromToken(token))

          return
        }
      }

      setAuthStatus(AuthStatus.UNAUTHENTICATED)
    }

    if (authStatus === AuthStatus.CONFIGURING) {
      checkAuth()
    }
  })

  /**
   * Sets up the auth service whenever the cognito details change.
   * Expected to run just once.
   */
  useEffect(() => {
    const [userPoolId, clientPoolId] = [
      configQuery.data?.cognito_details?.cognito_user_pool_id,
      configQuery.data?.cognito_details?.cognito_user_pool_client_id
    ]

    if (userPoolId && clientPoolId) {
      configure(userPoolId, clientPoolId)
    }
  }, [
    configQuery.data?.cognito_details?.cognito_user_pool_id,
    configQuery.data?.cognito_details?.cognito_user_pool_client_id
  ])

  const values = useMemo<AuthenticatedUserContextValues>(
    () => ({
      authStep,
      authStatus,
      isPending: configQuery.isLoading || isPending,
      user: authenticatedUser,
      getLastError: () => errorRef.current,
      signIn,
      verifySignIn,
      signOut,
      restart
    }),
    [
      authStep,
      authStatus,
      authenticatedUser,
      configQuery.isLoading,
      isPending,
      signIn,
      verifySignIn,
      signOut,
      restart
    ]
  )

  return (
    <AuthenticatedUserContext.Provider value={values}>
      {props.children}
    </AuthenticatedUserContext.Provider>
  )
}
