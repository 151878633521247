import type { TaxWithTaxInclusivityBehaviour } from '@epilot/journey-logic-commons'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './ProductDetailsPriceInformationTaxes.module.scss'

type ProductDetailsPriceInformationTaxesProps = {
  taxes: TaxWithTaxInclusivityBehaviour[]
  displayIndexes: boolean
}

export const ProductDetailsPriceInformationTaxes = ({
  taxes,
  displayIndexes
}: ProductDetailsPriceInformationTaxesProps) => {
  const { t } = useTranslation()

  return (
    <div className={classes.taxesRow}>
      {taxes.map((tax, index, list) => (
        <Fragment key={`${tax._id}-${index}`}>
          {tax.inclusive &&
            t('product_details.tax_rate_inclusive', 'inclusive')}{' '}
          {tax.rate}%
          {displayIndexes &&
            (tax.itemIdx ? (
              <sup>{tax.itemIdx}</sup>
            ) : (
              <sup>{index + 1}</sup>
            ))}{' '}
          {t('product_details.tax_name', 'VAT')}
          {index === list.length - 1
            ? ''
            : index === list.length - 2
              ? ` ${t('and', 'and')} `
              : ', '}
        </Fragment>
      ))}
    </div>
  )
}
