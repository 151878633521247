import type { FilesTableProps } from '@epilot/journey-logic-commons'
import Delete from '@epilot360/icons/react/Delete'

import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  useTheme,
  makeStyles,
  ElevatedIconButton
} from '../'

const useStyle = makeStyles((theme) => ({
  iconCell: {
    width: 40
  },
  nameCell: {
    maxWidth: '0', // Enables the cell to respect the table width
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  paper: {
    borderRadius:
      theme.shape?.borderRadius !== undefined
        ? `min(${theme.shape.borderRadius}px, 20px)`
        : '4px'
  }
}))

export function FilesTable({ labels, files, onDeleteFile }: FilesTableProps) {
  const theme = useTheme()
  const styles = useStyle()

  return (
    <TableContainer
      className={styles.paper}
      component={Paper}
      data-testid="uploaded-files-table"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{labels.fileName}</TableCell>

            <TableCell
              component="th"
              key="actions"
              padding="normal"
            ></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {files?.map((file) => (
            <TableRow
              data-testid={`uploaded-file-row-${file.file_name}`}
              key={[
                file.s3ref?.bucket,
                file.s3ref?.key,
                file.original_name
              ].join(',')}
            >
              <TableCell className={styles.nameCell} component="th" scope="row">
                {file.file_name}
              </TableCell>
              <TableCell align="right" className={styles.iconCell}>
                <ElevatedIconButton
                  onClick={() => void onDeleteFile?.(file)}
                  variant="circle"
                >
                  <Delete
                    fill={theme.palette.secondary.main}
                    size={23}
                    variant="filled"
                  />
                </ElevatedIconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
