import {
  Divider,
  Icon,
  Tab,
  TabContext,
  TabList,
  useTheme
} from '@epilot/journey-elements'
import { clsx } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { EmptyJourneyLauncher } from '../../blocks-renderers/components/JourneyLauncherTabs/EmptyJourneyLauncher'
import { useLauncherTabsStyles } from '../../blocks-renderers/components/JourneyLauncherTabs/styles'

import type { BaseJourneyLauncherTabsProps } from './types'

export const LegacyJourneyLauncherTabs = ({
  children,
  isFocusedOnJourney,
  linkedJourneys,
  showPaper,
  selectedJourneyId,
  setSelectedJourneyId
}: BaseJourneyLauncherTabsProps) => {
  const theme = useTheme()
  const classes = useLauncherTabsStyles({
    ctaColor: theme?.palette?.primary?.main,
    ctaText: theme?.palette?.primary?.contrastText
  })

  const tabs = useMemo(
    () =>
      linkedJourneys.map(({ id, label, icon }) => ({
        id,
        label,
        icon: icon ?? undefined
      })),
    [linkedJourneys]
  )

  if (!selectedJourneyId) {
    return <EmptyJourneyLauncher />
  }

  return (
    <div
      className={clsx(
        classes.journeyLauncherWrapper,
        !isFocusedOnJourney && classes.journeyLauncherWrapperInitialState
      )}
    >
      <TabContext value={selectedJourneyId}>
        <div
          className={classes.tabContainer}
          style={isFocusedOnJourney ? { display: 'none' } : undefined}
        >
          <TabList
            TabIndicatorProps={{ style: { display: 'none' } }}
            className={classes.tabs}
            scrollButtons="auto"
            variant="scrollable"
          >
            {tabs.map(({ id, label, icon }) => (
              <Tab
                className={clsx(
                  classes.tab,
                  id === selectedJourneyId && classes.tabSelected
                )}
                icon={
                  icon ? (
                    <Icon
                      className={[classes.showOnLg, classes.noMargin].join(' ')}
                      fontSize="small"
                      name={icon}
                    />
                  ) : undefined
                }
                key={id}
                label={<span className={classes.tabLabel}>{label}</span>}
                onClick={() => setSelectedJourneyId(id)}
                value={id}
              />
            ))}
          </TabList>
        </div>
        {!showPaper && <Divider className={classes.divider} />}
        <div
          className={clsx(
            ...(!isFocusedOnJourney
              ? [classes.tabContentWrapper, classes.tabContentWrapperShadow]
              : [])
          )}
        >
          {children}
        </div>
      </TabContext>
    </div>
  )
}
