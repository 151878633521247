import type { Design, Step, UiSchema } from '../utils'
import { isLayoutUiSchema, isNestedUiSchema } from '../utils'

type LauncherUiSchemaElement = UiSchema & {
  type: 'JourneyLauncherControl'
}

export const extractLauncherUiSchemaElements = (steps: Step[]) =>
  steps
    .flatMap<UiSchema | undefined>(({ uischema }) => {
      if (isNestedUiSchema(uischema)) {
        return uischema.elements?.flatMap((element) => element)
      } else if (isLayoutUiSchema(uischema)) {
        return uischema.elements
      } else {
        return uischema
      }
    })
    .filter(
      (element): element is LauncherUiSchemaElement =>
        element?.type === 'JourneyLauncherControl'
    )

/**
 * Prefer alternative ways of checking whether a journey is a launcher journey,
 * as this operation can be expensive on large journeys.
 */
export const isLauncherJourney = (steps: Step[] = []): boolean => {
  const launcherUiSchemaElements = extractLauncherUiSchemaElements(steps)

  return launcherUiSchemaElements.length > 0
}

export type LinkedJourney = {
  id: string
  label: string
  icon: any | null
  design?: Design
}

export const extractLinkedJourneys = (steps: Step[] = []): LinkedJourney[] => {
  const launcherUiSchemaElements = extractLauncherUiSchemaElements(steps)

  return launcherUiSchemaElements[0]?.options?.linkedJourneys
}
