import type { Journey as DesignClientJourney } from '@epilot/design-client'
import type { TimeFrequency } from '@epilot/pricing'
import type { ControlElement, JsonSchema7, Layout } from '@jsonforms/core'

import type { MappingConfig } from '../../mapping-sources/types'
import type {
  JOURNEY_EMBED_MODE,
  JOURNEY_STATUS,
  JOURNEY_SAVING_PROGRESS_MODE
} from '../../types/next'
import type { BlockLocatorDetails } from '../blocks'
import type { AutomationFlow } from '../getFlowForJourney'

import type { Logic } from './objectTypes'
import type { LogicString } from './stringTypes'

type JourneyDesignTokens = DesignClientJourney['design_tokens']

/** JOURNEY TYPES */

export type Journey = {
  version?: number
  revisions?: number
  journeyId: string
  organizationId: string
  brandId: string
  name: string
  steps: Step[]
  createdAt: string
  deletedAt?: string
  createdBy: string
  design?: Design
  settings?: Settings
  lastModifiedAt: string
  tags?: string[]
  categories?: string[]
  filters?: string[]
  logics?: LogicString[]
  contextSchema?: ContextItem[]
  // Deprecated?
  rules?: Rule[]
  featureFlags?: Record<string, unknown>
  journeyAnalyticsEnabled?: boolean
}

export type Step = {
  name: string
  stepId: string
  schema: JsonSchema7
  uischema?: UiSchema
} & StepSettings

export type UiSchema = BlockUiSchema | StackUiSchema | NestedUiSchema

export type BlockUiSchema = Omit<ControlElement, 'type'> & {
  readonly id?: string
  type: string
  /** Used by Paragraph blocks to store its rich text */
  text?: string
}

export type StackUiSchema = Omit<ControlElement, 'type'> & {
  type: 'MainLinearLayout'
  elements: UiSchema[]
}

export type GroupUiSchema = Omit<ControlElement, 'type'> & {
  type: 'GroupLayout'
  elements: UiSchema[]
}

export type LayoutUiSchema = StackUiSchema | GroupUiSchema

export type NestedUiSchema = Omit<ControlElement, 'type'> & {
  type: 'MainContentCartLayout'
  elements: UiSchema[][]
}

export type GridLayoutUiSchema = Omit<ControlElement, 'type'> & {
  type: 'GridLayout'
  elements: UiSchema[]
}

/**
 * @private Do not export, makes a soft check on whether arg conforms to shape
 */
const _isAnyUiSchema = (arg: unknown): arg is BlockUiSchema =>
  Boolean(
    arg &&
      typeof arg === 'object' &&
      'type' in arg &&
      typeof arg.type === 'string'
  )

/**
 * @todo Type typeguard more narrowly
 */
export const isBlockUiSchema = (arg: unknown): arg is BlockUiSchema =>
  _isAnyUiSchema(arg) && !isUiSchemaWithElements(arg)

export const isLayoutUiSchema = (
  arg: unknown
): arg is StackUiSchema | GroupUiSchema =>
  isStackUiSchema(arg) || isGroupUiSchema(arg) || isGridLayoutUiSchema(arg)

export const isStackUiSchema = (arg: unknown): arg is StackUiSchema =>
  _isAnyUiSchema(arg) && arg.type === 'MainLinearLayout'

export const isGroupUiSchema = (arg: unknown): arg is GroupUiSchema =>
  _isAnyUiSchema(arg) && arg.type === 'GroupLayout'

export const isNestedUiSchema = (arg: unknown): arg is NestedUiSchema =>
  _isAnyUiSchema(arg) && arg.type === 'MainContentCartLayout'

export const isGridLayoutUiSchema = (arg: unknown): arg is GridLayoutUiSchema =>
  _isAnyUiSchema(arg) && arg.type === 'GridLayout'

export const isUiSchemaWithElements = (
  arg: unknown
): arg is NestedUiSchema | StackUiSchema =>
  isLayoutUiSchema(arg) || isNestedUiSchema(arg)

export type StepSettings = {
  showStepName?: boolean | null
  title?: string | null // step title, defaults to step name
  subTitle?: string | null
  showStepSubtitle?: boolean | null
  showStepper?: boolean | null
  showStepperLabels?: boolean | null
  hideNextButton?: boolean | null
  onNextHooks?: WebHook[]
  maxWidth?: STEP_MAX_WIDTH_OPTIONS
}

export type JSONConfiguration = {
  name: string
  schema?: {
    [key: string]: JsonSchema7
  }
  uischema?: UiSchema
  required?: boolean
  journeyMeta?: { [key: string]: any }
  _operations?: Operation[]
  _mappingConfig?: MappingConfig
  _automationConfig?: AutomationFlow
}

export type Operation = {
  type: 'logic'
  action: 'REMOVE' | 'EDIT'
  searchValue: (block: BlockLocatorDetails) => string | undefined
  isMatching: (conditionValue: string, value: string | undefined) => boolean
  setValue?: (
    logic: Logic,
    blockCurrent: BlockLocatorDetails
  ) => { updatedLogic: Logic; updatedValue: string }
  removeValue?: (
    logic: Logic,
    blockCurrent: BlockLocatorDetails
  ) => { updatedLogic: Logic; updatedValue: string }
}

export type WebHook = {
  name: string
  hook: (oldState: any) => any
}

export const isLayout = (value: unknown): value is Layout => {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null &&
    'elements' in value
  )
}

export type StepState = {
  [blockName: string]:
    | {
        _isValid?: boolean
        [key: string]: unknown
      }
    | unknown
}

export type EmbedOptions = {
  /**
   * mode the journey is run in
   * @default full-screen
   */
  mode: JOURNEY_EMBED_MODE
  /**
   * width of journey if run in inline mode
   * @default 100%
   */
  width: string
  /**
   * whether top bar should be shown or not
   * @default true
   */
  topBar: boolean
  /**
   * button config if run in full screen mode
   * @default text: show content
   * @default align: left
   */
  button: {
    text: string
    align: 'left' | 'center' | 'right'
  }
  /**
   * Whether to scroll to the top on navigation or not
   */
  scrollToTop?: boolean
  /**
   * Language the journey runs in
   */
  lang?: 'de' | 'fr' | 'en'
}

export type Settings = {
  entityId?: string
  designId?: string
  description?: string
  templateId?: string
  automationId?: string
  safeModeAutomation?: boolean
  mappingsAutomationId?: string
  runtimeEntities?: EntitiesRunTime[]
  targetedCustomer?: TargetedCustomer
  /**
   * Determines the embedding behavior
   *
   * WARNING: This should become ephemeral and allow the same Journey to be embedded differently in different places,
   * therefore it is expected to be removed in the future.
   *
   * @see {@link https://e-pilot.atlassian.net/browse/STABLE360-7109}
   * @deprecated should be ephemeral, please stop using it
   */
  embedOptions?: EmbedOptions
  organizationSettings?: {
    [key: string]: boolean
  }
  accessMode?: JourneyAccessMode
  publicToken?: string
  entityTags?: string[]
  filePurposes?: string[]
  /**
   * @deprecated use `addressSuggestionsFileId` instead
   */
  addressSuggestionsFileUrl?: string
  addressSuggestionsFileId?: string
  useNewDesign?: boolean
  status?: JOURNEY_STATUS
  isActive?: boolean
  canary?: boolean
  savingProgress?: {
    mode: JOURNEY_SAVING_PROGRESS_MODE
    supportedRevision?: number
  }
  /**
   * Whether third party cookie should be enabled
   *
   * If disabled, tools like DataDog should be set up in a way that doesn't rely on third party cookies
   * or are entirely disabled to comply with this setting.
   *
   * This is currently useful to avoid having to have a Cookie Consent banner for Journeys
   * to comply with GDPR regulations.
   *
   * @default true
   */
  thirdPartyCookies?: boolean
}

export const JOURNEY_ACCESS_MODE = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE'
}
export type JourneyAccessMode =
  (typeof JOURNEY_ACCESS_MODE)[keyof typeof JOURNEY_ACCESS_MODE]

export type DesignTokens = JourneyDesignTokens

export type Design = {
  logoUrl?: string
  theme: {
    [key: string]: unknown
  }
  designTokens?: DesignTokens
  orgId?: string
  fileId?: string
}

export const TARGETED_CUSTOMERS = {
  BUSINESS: 'BUSINESS',
  PRIVATE: 'PRIVATE'
}

export type TargetedCustomer = 'BUSINESS' | 'PRIVATE'

/**
 * @deprecated please use the UNITS from {@link src/types/next/enums.ts}
 */
export enum MeterReadingUnits {
  w = 'W',
  wh = 'Wh',
  kw = 'kW',
  kwh = 'kWh',
  kvarh = 'kvarh',
  mw = 'mW',
  mwh = 'mWh',
  unit = 'unit',
  'cubic-meter' = 'm³',
  hour = 'hour',
  day = 'day',
  month = 'month',
  year = 'year',
  percentage = 'percentage'
}

/** @deprecated please use RUNTIME_ENTITY enum from {@link src/types/next/enums.ts} */
export const RUNTIME_ENTITY = {
  ORDER: 'ORDER',
  OPPORTUNITY: 'OPPORTUNITY'
}

export const runtimeEntities = [
  RUNTIME_ENTITY.ORDER,
  RUNTIME_ENTITY.OPPORTUNITY
]
export type EntitiesRunTime = (typeof runtimeEntities)[number]

/**  FILTERS (Currently only used for product Filters) */

export type Filters = Filter[]

export type Filter = {
  blockName: string
  options: FilterOption[] // array to check if any matches
}

export type FilterOption = {
  value: string | number | boolean
  type?: FilterOptionType
  ids: string[]
}

type FilterOptionType = 'INCLUDE' | 'EXCLUDE' // Default: 'INCLUDE'

export type ContextData = Record<string, string>

export const CONTEXT_MENTION_PREFIX = 'context'

/** CONTEXT (Initially created for EUJ M4) **/

export type ContextItem = {
  /**
   * Unique identifier for the context parameter.
   *
   * WARNING: This is currently NOT implemented, but rather used during the
   * schema transtormation process to uniquely identify context parameters.
   */
  id?: string
  type: 'String' | string
  paramKey: string
  isRequired?: boolean
  shouldLoadEntity?: boolean
}

/** @see {isValidLiveValue} ts-auto-guard:type-guard */
export type LiveValue =
  | string
  | string[]
  | boolean
  | number
  | Record<string, unknown>

export type Rule = {
  type: 'inject' | 'injectWithKey'
  sourceType: 'journey' | 'step' | 'block'
  source: string
  target: string
}

/**
 * Trigger and Target strings follow the format: 'stepIndex/BlockName'
 * Example: { '1/BlockAddress': { display: true, trigger: '0/BlockBinary' } }
 */
export type DisplayConditionsStatus = {
  [target: string]: {
    display?: boolean
    trigger: string
  }
}

export type BlockConfiguration = {
  isRequired?: boolean
  showQuantity?: boolean
  blockPath?: string
}

export type BlockMappingData = {
  numberInput?: number
  frequencyUnit?: TimeFrequency
}

export type RendererReturnValue<T extends Record<string, unknown>> =
  | (T & {
      _isValid?: boolean
    })
  | undefined
  | null

export type ZoneLayout = [
  {
    zoneKey: 'content'
    elements: UiSchema[]
    order: 2
  },
  {
    zoneKey: 'side'
    elements: UiSchema[]
    order: 3
  },
  {
    zoneKey: 'header'
    elements: UiSchema[]
    order: 1
  },
  {
    zoneKey: 'footer'
    elements: UiSchema[]
    order: 4
  }
]

export type ZoneKeys = 'content' | 'side' | 'header' | 'footer' | string

export enum STEP_MAX_WIDTH_OPTIONS {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra large'
}

export const STEP_MAX_WIDTH_MAP: Record<STEP_MAX_WIDTH_OPTIONS, string> = {
  small: '720px',
  medium: '960px',
  large: '1256px',
  'extra large': '1440px'
}
