import { memo, type ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { NotFoundError } from './NotFoundError'

type Props = Omit<ComponentProps<typeof NotFoundError>, 'i18n'>

// TODO: make `onClose` side effects a self-contained action that is globally available
export const JourneyNotFoundError = memo((props: Props) => {
  const { t } = useTranslation()

  return (
    <NotFoundError
      i18n={{
        heading: t('Oops! The Journey is not available.'),
        content: t('Please contact customer support for further information.'),
        close: t('Close')
      }}
      {...props}
    />
  )
})

JourneyNotFoundError.displayName = 'JourneyNotFoundError'
