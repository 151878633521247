import { Typography, Popover, PopoverContent } from '@epilot/concorde-elements'
import {
  getComputedMappingDataGraduated,
  omitTrailingDoubleDecimalZeros,
  type Price
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

import classes from './ComponentMappingDataGraduated.module.scss'
import { MappingDataTriggerButton } from './components/MappingDataTriggerButton'
import { ProductTitleMappingDataTriggerButton } from './components/ProductTileMappingDataTriggerButton'
import { useComponentMappingDataGraduated } from './hooks/useComponentMappingDataGraduated'

type ComponentMappingDataGraduatedProps = {
  displayInOneLine?: boolean
  displayUnitaryAverage?: boolean
  price?: Price
  quantity?: number
  showPriceDescription?: boolean
  showTrailingDecimalZeros: boolean
  t: TFunction
  className?: string
  useNewDesign?: boolean
  isShownOnProductCard?: boolean
  unitAmount?: string
  unitAmountWithUnit?: string
  formattedQuantity?: string
  amountTotal?: string
  beforeDiscountUnitAmount?: string
  discountAmount?: string
  beforeDiscountAmountTotal?: string
}

export const ComponentMappingDataGraduated = ({
  displayInOneLine,
  displayUnitaryAverage,
  price: priceFromProps,
  quantity,
  showPriceDescription,
  t,
  className,
  isShownOnProductCard,
  unitAmount,
  unitAmountWithUnit,
  formattedQuantity,
  amountTotal,
  showTrailingDecimalZeros
}: ComponentMappingDataGraduatedProps) => {
  const { open, setOpen, handleClick, handleMouseEnter, handleMouseLeave } =
    useComponentMappingDataGraduated({
      unitAmount,
      formattedQuantity,
      amountTotal
    })

  const data = getComputedMappingDataGraduated({
    price: priceFromProps,
    quantity,
    showTrailingDecimalZeros,
    t
  })

  if (!data) {
    return null
  }

  const {
    formattedNormalizedQuantity,
    formattedTotalWithPrecision,
    breakdown,
    price
  } = data

  return (
    <Popover
      data-testid="mapping-data-graduated-popover"
      onOpenChange={setOpen}
      open={open}
    >
      <div>
        {isShownOnProductCard ? (
          <ProductTitleMappingDataTriggerButton
            displayUnitaryAverage={displayUnitaryAverage}
            formattedAverage={unitAmount}
            handleClick={handleClick}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            price={price}
            showPriceDescription={showPriceDescription}
          />
        ) : (
          <MappingDataTriggerButton
            className={className}
            displayInOneLine={displayInOneLine}
            displayUnitaryAverage={displayUnitaryAverage}
            formattedAverage={unitAmountWithUnit}
            formattedQuantity={formattedQuantity}
            handleClick={handleClick}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            price={price}
            showPriceDescription={showPriceDescription}
          />
        )}
      </div>
      <PopoverContent
        avoidCollisions
        collisionPadding={20}
        side="top"
        sideOffset={8}
      >
        <div className={classes.contentContainer}>
          <Typography variant="primary">
            <b>
              {displayUnitaryAverage
                ? t(
                    'cumulative_breakdown_title',
                    '{{formattedNormalizedQuantity}} cost {{formattedTotal}}, for an {{formattedAverage}}.',
                    {
                      formattedNormalizedQuantity,
                      formattedTotal: amountTotal,
                      formattedAverage: unitAmountWithUnit
                    }
                  )
                : t(
                    'cumulative_breakdown_title_no_average',
                    '{{formattedNormalizedQuantity}} cost {{formattedTotal}}.',
                    {
                      formattedNormalizedQuantity,
                      formattedTotal: amountTotal
                    }
                  )}
            </b>
          </Typography>
          <div>
            <Typography className={classes.title} variant="primary">
              {t(
                'cumulative_breakdown_subtitle',
                'This is how we calculate it:'
              )}
            </Typography>
            {breakdown.map((breakdownItem, index) => (
              <Typography
                className={classes.title}
                key={`${index}-${breakdownItem.totalAmountDecimal}`}
                variant="primary"
              >
                {t(
                  `${index === 0 ? 'first' : 'next'}_cumulative_breakdown_item`,
                  index === 0 ? 'First' : 'Next'
                )}{' '}
                {breakdownItem.quantityUsed} *{' '}
                {showTrailingDecimalZeros
                  ? breakdownItem.tierAmountDecimal
                  : omitTrailingDoubleDecimalZeros(
                      breakdownItem.tierAmountDecimal
                    )}{' '}
                ={' '}
                {showTrailingDecimalZeros
                  ? breakdownItem.totalAmountDecimal
                  : omitTrailingDoubleDecimalZeros(
                      breakdownItem.totalAmountDecimal
                    )}
              </Typography>
            ))}
          </div>
          {displayUnitaryAverage && (
            <Typography className={classes.title} variant="primary">
              {`${formattedTotalWithPrecision} / ${formattedNormalizedQuantity} = ${unitAmountWithUnit}`}
            </Typography>
          )}
        </div>
      </PopoverContent>
    </Popover>
  )
}
