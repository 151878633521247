import { DottedLine, Spacing, Typography } from '@epilot/concorde-elements'
import type {
  CompositePriceItem,
  BillingPeriod,
  Price,
  PriceItem,
  Tax
} from '@epilot/journey-logic-commons'
import {
  getFormattedQuantity,
  getTaxIndexes,
  isCompositePrice,
  getPricingDetailsFormatted,
  getPriceType
} from '@epilot/journey-logic-commons'
import { computeQuantities } from '@epilot/pricing'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PriceComponentDetails } from './PriceComponentDetails'
import { PriceComponentDiscount } from './PriceComponentDiscount'
import classes from './PriceComponents.module.scss'

type TaxWithTaxInclusivityBehaviour = Tax & { inclusive: boolean }

type PriceComponentProps = {
  component: CompositePriceItem
  showTrailingDecimalZeros: boolean | undefined
  displayUnitaryAverage: boolean | undefined
  taxes?: TaxWithTaxInclusivityBehaviour[]
  shouldHideRecurrence?: boolean
  animate?: boolean
  quantity: number
}

type PriceAdditionalDetail = {
  label: string
  value: string
}

export const PriceComponent = ({
  animate,
  component,
  showTrailingDecimalZeros,
  displayUnitaryAverage,
  shouldHideRecurrence,
  taxes = [],
  quantity
}: PriceComponentProps) => {
  const { t: productSelectionT } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  const taxIndexes = useMemo<number[]>(
    () =>
      getTaxIndexes(component.taxes, component._price?.is_tax_inclusive, taxes),
    [taxes, component.taxes, component._price?.is_tax_inclusive]
  )

  if (component._price && isCompositePrice(component._price)) {
    return <></>
  }

  const type = getPriceType(
    component._price?.type as Price['type'],
    component._price?.billing_period as BillingPeriod
  )

  const { quantityToSelectTier } = computeQuantities(
    component._price,
    quantity,
    {
      frequency_unit: component._price?.blockMappingData?.frequencyUnit,
      value: component._price?.blockMappingData?.numberInput
    }
  )

  const { amountTotal, unitAmount, unitAmountWithUnit } =
    getPricingDetailsFormatted(
      component,
      1,
      quantityToSelectTier,
      productSelectionT,
      Boolean(showTrailingDecimalZeros)
    )

  const displayDiscount =
    component._price?.price_display_in_journeys !== 'show_as_on_request' &&
    component.discount_amount !== undefined

  const priceAdditionalDetails = component._price?.additional_details ?? []
  const formattedQuantity = getFormattedQuantity(
    component._price as Price,
    productSelectionT
  )

  return (
    <div
      className={classNames(
        {
          [classes.animate]: animate
        },
        classes.component
      )}
    >
      <Spacing key={component.price_id} scale={8} variant={'inline'}>
        <div className={classes.detailContainer}>
          <Typography variant={'secondary'}>
            <strong>{component.description}</strong>{' '}
            {!!taxIndexes?.length && (
              <sup className={classes.superscript}>{taxIndexes.join(', ')}</sup>
            )}
          </Typography>
          <Typography
            className={classes.details}
            data-testid="cart-item-line-mapping-data"
            variant={'secondary'}
          >
            <PriceComponentDetails
              amountTotal={amountTotal}
              component={component}
              displayUnitaryAverage={displayUnitaryAverage}
              formattedQuantity={formattedQuantity}
              quantity={quantity}
              showTrailingDecimalZeros={showTrailingDecimalZeros}
              t={productSelectionT}
              unitAmount={unitAmount}
              unitAmountWithUnit={unitAmountWithUnit}
            />
          </Typography>
        </div>
        <Spacing className={classes.amountContainer} variant={'stack'}>
          <Spacing variant={'inline'}>
            {!shouldHideRecurrence && !displayDiscount && (
              <Typography variant={'secondary'}>
                {productSelectionT(type)}
              </Typography>
            )}
            {displayDiscount && !shouldHideRecurrence && (
              <Typography variant={'secondary'}>
                {productSelectionT(type)}
              </Typography>
            )}
            <Typography
              className={classes.amount}
              data-testid="cart-item-line-price"
              variant={'secondary'}
            >
              <strong>{amountTotal}</strong>
            </Typography>
          </Spacing>
          {displayDiscount && (
            <PriceComponentDiscount
              item={component as PriceItem}
              showTrailingDecimalZeros={showTrailingDecimalZeros}
              t={productSelectionT}
            />
          )}
        </Spacing>
      </Spacing>
      {priceAdditionalDetails?.length > 0 &&
        priceAdditionalDetails.map(
          (additionalDetail: PriceAdditionalDetail, index: number) => (
            <Spacing
              className={classes.additionalDetails}
              justifyContent="space-between"
              key={`detail-${index}`}
              variant="inline"
            >
              <div>{additionalDetail?.label}</div>
              <DottedLine />
              <div>{additionalDetail?.value}</div>
            </Spacing>
          )
        )}
    </div>
  )
}
