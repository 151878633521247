import {
  ThemeProvider as ConcordeThemeProvider,
  JOURNEY_EXIT_EVENT,
  JOURNEY_NAVIGATION_EVENT
} from '@epilot/concorde-elements'
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline
} from '@epilot/journey-elements'
import {
  LogicTriggerEventName,
  findStepIndexById,
  generateSources,
  computeMaxLengthOfStepChains,
  computePossibleNextStepMap,
  computeDefaultNextStepMap,
  ACTION_TYPE
} from '@epilot/journey-logic-commons'
import type {
  Journey,
  StepState,
  EventDetailType,
  JourneyNavigationEventPayload,
  ValueError,
  JourneyRenderFlags,
  HistoryStackState
} from '@epilot/journey-logic-commons'
import isEqual from 'fast-deep-equal/react'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { setLastStepNavigated } from '../../analytics/service'
import {
  NavigationProvider,
  useJourneyContext,
  makeJourneyEmptyStepValuesWithDefaults,
  useUserProgress
} from '../../blocks-renderers'
import type { NavigationContextValue } from '../../blocks-renderers'
import { useCustomCSS } from '../../blocks-renderers/utils/hooks/useCustomCSS'
import { useAnalytics } from '../../context/AnalyticsContext'
import { useListener } from '../../hooks/use-listener'
import { useGetJourneyParams } from '../../hooks/useGetJourneyParams'
import { useInitiateProductSelection } from '../../hooks/useInitiateProductSelection'
import { useIsDirty } from '../../hooks/useIsDirty'
import { getNextNonSkippedStepIndex } from '../../hooks/useLogics/applySkipStepLogics'
import { useLogics } from '../../hooks/useLogics/useLogics'
import type { ApplyLogicsNavigationCallback } from '../../hooks/useLogics/useLogics'
import useUpdateTheme from '../../hooks/useUpdateTheme'
import {
  SubmissionError,
  submissionListener
} from '../../listeners/submissionListener'
import { getDatadogClient } from '../../services/datadog'
import { extractLauncherJourneyLauncherBlock } from '../../utils/extractLauncherJourneyLauncherBlock'
import { findDuplicateStepId } from '../../utils/findDuplicateStepId'
import {
  getBlockTypesForError,
  getHigherPriorityErrorKey
} from '../../utils/journeyErrorUtils'
import { unreachable } from '../../utils/unreachable'
import { useJourneyLauncherContext } from '../JourneyLauncher/JourneyLauncherContextProvider'
import { JourneyNotFoundError } from '../JourneyNotFoundError'

import { LinearJourney } from './components/LinearJourney'
import type { StepComponentProps } from './components/StepComponent'
import {
  publishExitFullScreenMessage,
  publishEnterFullScreenMessage,
  publishCloseJourneyMessage,
  publishJourneyLoadedMessage
} from './embedJourneyPublishers'
import { dispatchExitEvent, dispatchLoadingEvent } from './eventsDispatchers'
import { useThemes } from './use-themes'
import { getValueErrors, getWhichBlockHasChanged } from './utils'

export type JourneyPageProps = {
  journey: Journey
  launcherJourney: Journey | null
  onJourneyChange: (journey: Journey) => void
  isFocusedOnJourney: boolean
  initialStepValues?: StepState[]
  flags: JourneyRenderFlags
  history: HistoryStackState
  launcherBlockName?: string
}

export const JourneyPage = ({
  journey,
  onJourneyChange,
  isFocusedOnJourney,
  initialStepValues,
  flags,
  history,
  launcherJourney
}: JourneyPageProps) => {
  const { clearCurrentUserSession } = useUserProgress({ journey })

  const { context, updateContext } = useJourneyContext()
  const { analytics } = useAnalytics()
  const { t } = useTranslation()
  const { mode, isEmbedded } = useGetJourneyParams()

  /* New simplified journey launcher logic, not relevant unless FeatureFlags.SIMPLIFIED_JOURNEY_LAUNCHER is enabled */
  const { selectedJourneyId: activeLinkedJourneyId } =
    useJourneyLauncherContext()

  const parentJourney = launcherJourney ?? journey
  const activeLinkedJourney = useMemo(
    () =>
      isFocusedOnJourney && journey.journeyId === activeLinkedJourneyId
        ? journey
        : null,
    [activeLinkedJourneyId, isFocusedOnJourney, journey]
  )

  const activeJourney = activeLinkedJourney ?? journey
  const launcherBlockName = useMemo(() => {
    const launcherBlock =
      launcherJourney && extractLauncherJourneyLauncherBlock(launcherJourney)

    return launcherBlock?.name
  }, [launcherJourney])

  const [submitJourneySuccess, setSubmitJourneySuccess] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  /**
   * This control state flag is used to prevent the journey from going into fullscreen mode,
   * when navigating back from a linked journey to a parent journey, given the parent journey to linked step
   * behavior triggers the fullscreen mode.
   */
  const [isNavigatingBack, setIsNavigatingBack] = useState(false)

  // used to send InitiateProductSelection event to website
  useInitiateProductSelection(
    activeJourney,
    history.currentIndex,
    history.stack
  )

  const initialStepsForCurrentJourney = useMemo(
    () =>
      makeJourneyEmptyStepValuesWithDefaults(activeJourney, initialStepValues),
    [initialStepValues, activeJourney]
  )

  const isDirty = useIsDirty(
    initialStepsForCurrentJourney,
    context._stepsStateArray,
    journey.journeyId,
    journey.name,
    history.stack
  )

  /**
   * Publish event when journey loads
   */
  useEffect(() => {
    publishJourneyLoadedMessage({
      journeyName: journey.name,
      journeyId: journey.journeyId
    })
  }, [journey.journeyId, journey.name])

  const stepsErrors =
    context._errors.length === activeJourney.steps.length
      ? context._errors
      : Array.from<unknown, ValueError[]>(
          { length: activeJourney.steps.length },
          () => []
        )

  const currentStepIndex = history.currentIndex
  const stepsState = context._stepsStateArray

  const isLinkedJourneyInitialStep = useMemo(
    () => context?._linkedJourneyInitialStepIndex === currentStepIndex,
    [context, currentStepIndex]
  )

  const currentStep = activeJourney.steps[currentStepIndex] || {}
  const currentStepErrors = stepsErrors[currentStepIndex] || []

  /**
   * data injection is deprecated
   */
  const injectStateAndNavigateToStep: ApplyLogicsNavigationCallback = (
    stepIndexCandidate,
    localStepsState
  ) => {
    const stepIndex = getNextNonSkippedStepIndex(
      stepIndexCandidate,
      skipStepConditionsStatusRef.current,
      possibleDefaultNextStepMap
    )

    const newState = localStepsState ?? stepsState

    /* If the step indexes are different, we want to perform the navigation */
    if (stepIndex !== currentStepIndex) {
      analytics?.addEvents([
        {
          type: 'step_navigation',
          details: {
            fromStepNumber: currentStepIndex + 1,
            fromStepName: journey?.steps?.[currentStepIndex]?.name,
            toStepNumber: stepIndex + 1,
            toStepName: journey?.steps?.[stepIndex]?.name
          },
          timestamp: new Date().toISOString()
        }
      ])
      setLastStepNavigated(
        {
          stepNumber: stepIndex + 1,
          stepName: journey?.steps?.[stepIndex]?.name
        },
        journey?.journeyId
      )

      const targetStep =
        stepIndex === 0
          ? parentJourney.steps[stepIndex]
          : journey.steps[stepIndex]

      if (targetStep) {
        history.push(targetStep.name, {
          stepIndex,
          stack: history.stack,
          userValues: stepsState,
          steps: journey.steps
        })
      } else {
        console.error(new Error('Next step not found'))
      }
    }

    onStepStateChange({ newState, stepIndex })

    updateContext((context) => ({
      ...context,
      errorValidationMode: 'ValidateAndHide'
    }))
  }

  const {
    navigationLogics,
    applyLogics,
    displayConditionsStatusRef,
    skipStepConditionsStatusRef,
    resetInitializedLogics,
    isCurrentStepReadyToRender
  } = useLogics({
    journey: activeJourney,
    isPreview: flags.isPreview,
    currentStepIndex,
    onJourneyChange,
    injectStateAndNavigateToStep,
    stepsState,
    currentStep
  })

  const possibleDefaultNextStepMap = useMemo(() => {
    if (activeJourney.journeyId && activeJourney.steps) {
      return computeDefaultNextStepMap(activeJourney.steps)
    }

    return {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeJourney.journeyId])

  const possibleNextStepMap = useMemo(
    () => computePossibleNextStepMap(activeJourney.steps, navigationLogics),
    [activeJourney.steps, navigationLogics]
  )

  const remainingSteps = useMemo(() => {
    if (flags.isPreview) {
      // if in preview mode, simply show sequential progress bar
      return activeJourney.steps.length - currentStepIndex
    } else {
      const currentStepId = currentStep?.stepId ?? currentStep?.name

      if (currentStepId) {
        const maxLengthOfStepChain = computeMaxLengthOfStepChains(
          possibleNextStepMap,
          currentStepId
        )

        return maxLengthOfStepChain - 1
      }
    }
  }, [
    activeJourney.steps.length,
    currentStep?.name,
    currentStep?.stepId,
    currentStepIndex,
    flags.isPreview,
    possibleNextStepMap
  ])

  /* If navigating within a launcher journey, we might need to enter or exit  */
  useEffect(
    function triggerFullscreenOnActiveLinkedJourney() {
      if (flags.isPreview || !launcherJourney) {
        return
      }

      const { journeyId } = launcherJourney
      const initialStepIndex = context._linkedJourneyInitialStepIndex ?? 0

      if (isFullscreen && currentStepIndex === initialStepIndex) {
        /* If we're in fullscreen and we're going back, we need to exit */
        setIsFullscreen(false)
        publishExitFullScreenMessage({
          journeyId,
          isLauncherJourney: true
        })
      } else if (!isFullscreen && currentStepIndex !== initialStepIndex) {
        /* If we're not in fullscreen and we're not going back, we need to enter */
        setIsFullscreen(true)
        publishEnterFullScreenMessage({
          journeyId,
          isLauncherJourney: true
        })
      }
    },
    [
      context._linkedJourneyInitialStepIndex,
      currentStepIndex,
      flags.isPreview,
      isFocusedOnJourney,
      isFullscreen,
      launcherJourney,
      parentJourney
    ]
  )

  // updating initial State
  /**
   * Will only be executed on first render.
   * @todo Review its' purpose
   */
  useEffect(() => {
    if (initialStepValues && !isEqual(initialStepValues, stepsState)) {
      updateContext((prevState) => ({
        ...prevState,
        journeyStepStateMap: {
          ...prevState.journeyStepStateMap,
          [journey.journeyId]: initialStepValues
        }
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Gets called prior to navigating to a new step,
   * if there are errors for the current step.
   */
  const updateJourneyContextWithError = () => {
    const erroredBlockTypes = getBlockTypesForError(
      currentStepErrors,
      activeJourney,
      currentStepIndex
    )

    /**
     * Undefined error blocks -> generic error
     * 1 error block -> try to get the error key specific for the block
     * More than one error blocks -> show higher priority error or generic one
     */
    const errorMessageTranslationKey: string = getHigherPriorityErrorKey(
      erroredBlockTypes || undefined
    )

    updateContext((context) => ({
      ...context,
      errorValidationMode: 'ValidateAndShow',
      _errors: [...stepsErrors],
      errorMessageTranslationKey,
      erroredStepIndex: currentStepIndex
    }))
  }

  const navigateToNextStep = async (targetStepId: string) => {
    if (currentStepErrors.length > 0) {
      updateJourneyContextWithError()

      return
    }

    if (!flags.isPreview) {
      const appliedLogicsInfo = await applyLogics(
        stepsState,
        currentStepIndex,
        LogicTriggerEventName.NEXT
      )

      if (appliedLogicsInfo.journey) {
        onJourneyChange(appliedLogicsInfo.journey)
      }

      if (typeof appliedLogicsInfo.navigationTargetStep === 'number') {
        injectStateAndNavigateToStep(
          appliedLogicsInfo.navigationTargetStep,
          stepsState
        )
      } else {
        // next step index is:
        // if targetStepId is passed then it is the step that has stepId == targetStepId, but if none is found, then look in the step names
        // if targetStepId is NOT passed, then it is currentStepIndex + 1
        const nextStepIndex = targetStepId
          ? findStepIndexById(
              activeJourney.steps,
              targetStepId,
              currentStepIndex + 1
            )
          : currentStepIndex + 1

        injectStateAndNavigateToStep(nextStepIndex)
      }
    }
  }

  const onStepStateChange = async ({
    newState,
    stepIndex,
    valueErrors,
    triggerBlockNames
  }: {
    newState: StepState | StepState[]
    stepIndex: number
    valueErrors?: ValueError[]
    triggerBlockNames?: string[]
  }) => {
    let updatedJourney: Journey | undefined = undefined
    let tempStepsState = [...stepsState]
    const errors = [...stepsErrors]

    if (Array.isArray(newState)) {
      tempStepsState = [...newState]
    } else {
      // Only dispatch event when the data is changed by the user
      window.dispatchEvent(
        new CustomEvent('journey-state-update', {
          detail: { newState, stepIndex }
        })
      )
      tempStepsState[stepIndex] = { ...newState }
    }

    if (valueErrors && !isEqual(errors[currentStepIndex], valueErrors)) {
      errors[currentStepIndex] = valueErrors
    }

    if (!flags.isPreview) {
      const appliedLogicsInfo = await applyLogics(
        tempStepsState,
        currentStepIndex,
        LogicTriggerEventName.VALUE_CHANGE,
        triggerBlockNames
      )

      if (typeof appliedLogicsInfo.navigationTargetStep === 'number') {
        injectStateAndNavigateToStep(
          appliedLogicsInfo.navigationTargetStep,
          tempStepsState
        )
      }

      if (appliedLogicsInfo.journey) {
        updatedJourney = appliedLogicsInfo.journey
      }

      const updatedStepsState = appliedLogicsInfo.updatedStepsState || []

      tempStepsState = [...updatedStepsState]
    }

    updateContext((context) => {
      const journeyId = journey.journeyId

      return {
        ...context,
        _errors: errors,
        journeyStepStateMap: {
          ...context.journeyStepStateMap,
          [journeyId]: tempStepsState.map((element) => element ?? {})
        }
      }
    })

    if (updatedJourney) {
      onJourneyChange(updatedJourney)
    }
  }

  const alertNavigationIsDisabledWithinPreview = useCallback(() => {
    alert(
      t(
        'navigationIsDisabledWithinPreview',
        'Die Navigation innerhalb der Vorschau ist deaktiviert. Navigiere im Journey Builder über die Schritte am unteren Bildschirmrand.'
      )
    )
  }, [t])

  // a function that checkes the journey config if it includes steps with the same stepId
  // if so, it will create an alert via DD
  // it is using useEffect so it works in the journey preview too
  useEffect(() => {
    const duplicateStepIds = findDuplicateStepId(journey.steps)
    const isNotAvailableStepId = duplicateStepIds.every(
      (stepId: string) =>
        stepId === 'Nichtverfügbarkeit' || stepId === 'Not Available'
    )

    if (duplicateStepIds.length > 0 && !isNotAvailableStepId) {
      getDatadogClient()?.addError(
        `The journey ${
          journey.journeyId
        } was found with duplicate stepIds: ${duplicateStepIds.join(', ')}`,
        {
          orgId: journey.organizationId,
          journeyId: journey.journeyId
        }
      )
      // eslint-disable-next-line no-console
      console.error(
        new Error(
          `The journey ${
            journey.journeyId
          } was found with duplicate stepIds: ${duplicateStepIds.join(', ')}`
        )
      )
    }

    if (flags.isPreview) {
      cleanJourneyState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey])

  const cleanJourneyState = useCallback(() => {
    updateContext((context) => ({
      ...context,
      journeyStepStateMap: {
        ...context.journeyStepStateMap,
        [journey.journeyId]: Array.from(
          { length: journey.steps.length },
          () => ({})
        )
      },
      _errors: []
    }))

    resetInitializedLogics()
    setSubmitJourneySuccess(false)
  }, [
    journey.journeyId,
    journey.steps.length,
    updateContext,
    resetInitializedLogics
  ])

  const handleOnClose = useCallback(() => {
    dispatchExitEvent()
    publishCloseJourneyMessage({
      journeyId: parentJourney.journeyId,
      isDirty
    })
  }, [isDirty, parentJourney.journeyId])

  const handleGoBack = useCallback(async () => {
    setIsNavigatingBack(true)
    analytics?.addEvents([
      {
        type: 'step_navigation',
        details: {
          fromStepNumber: currentStepIndex + 1,
          fromStepName: journey?.steps?.[currentStepIndex]?.name,
          toStepNumber: currentStepIndex,
          toStepName: journey?.steps?.[currentStepIndex - 1]?.name
        },
        timestamp: new Date().toISOString()
      }
    ])
    setLastStepNavigated(
      {
        stepNumber: currentStepIndex,
        stepName: journey?.steps?.[currentStepIndex - 1]?.name
      },
      journey?.journeyId
    )

    if (flags.isPreview) {
      alertNavigationIsDisabledWithinPreview()
    } else if (launcherJourney && isLinkedJourneyInitialStep) {
      handleOnClose()
    } else {
      const overridedState = [...stepsState]

      overridedState[currentStepIndex] = {}
      const appliedLogicsInfo = await applyLogics(
        overridedState,
        currentStepIndex,
        LogicTriggerEventName.NEXT
      )

      if (appliedLogicsInfo.journey) {
        onJourneyChange(appliedLogicsInfo.journey)
      }

      history.back()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flags.isPreview,
    alertNavigationIsDisabledWithinPreview,
    stepsState,
    currentStepIndex,
    applyLogics,
    launcherJourney,
    isLinkedJourneyInitialStep,
    onJourneyChange,
    handleOnClose,
    history
  ])

  /**
   * Called when
   * - User navigates with stepper
   * - Navigating from summary block
   * - Handling exit event fired by thank you block
   * @todo Consider how to combine this with handleGoBack and onNextStep
   */
  const handleNavigationToStep = useCallback(
    async (
      stepIndex: number,
      {
        shouldReapplyLogics,
        /* It's redundant with cleanJourneyState */
        shouldStepOutOfLauncherJourney
      }: {
        /**
         * If caller is summary, we want to re-apply logics to steps without step data
         * Step data itself is preserved
         */
        shouldReapplyLogics?: boolean
        /**
         * In case we're in a launcher journey, we might want to step out of it
         */
        shouldStepOutOfLauncherJourney?: boolean
      } = {}
    ) => {
      if (flags.isPreview) {
        alertNavigationIsDisabledWithinPreview()

        return
      }

      analytics?.addEvents([
        {
          type: 'step_navigation',
          details: {
            fromStepNumber: currentStepIndex + 1,
            fromStepName: journey?.steps?.[currentStepIndex]?.name,
            toStepNumber: stepIndex + 1,
            toStepName: journey?.steps?.[stepIndex]?.name
          },
          timestamp: new Date().toISOString()
        }
      ])
      setLastStepNavigated(
        {
          stepNumber: stepIndex + 1,
          stepName: journey?.steps?.[stepIndex]?.name
        },
        journey?.journeyId
      )
      if (shouldReapplyLogics) {
        const overridedState = [...stepsState]

        for (let index = stepIndex; index < stepsState.length; index++) {
          overridedState[index] = {}
          const appliedLogicsInfo = await applyLogics(
            overridedState,
            index,
            LogicTriggerEventName.NEXT
          )

          if (appliedLogicsInfo.journey) {
            onJourneyChange(appliedLogicsInfo.journey)
          }
        }
      }

      if (stepIndex === 0 && shouldStepOutOfLauncherJourney) {
        // if close button clicked, we need to use .push as we want to set isTrackingDisabled to false
        history.push(parentJourney.steps[stepIndex].name, {
          stepIndex,
          stack: [],
          userValues: undefined,
          isTrackingDisabled: true
        })
      } else {
        /* Moving to any step but first */
        history.go(stepIndex)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeLinkedJourney,
      alertNavigationIsDisabledWithinPreview,
      applyLogics,
      cleanJourneyState,
      flags.isPreview,
      history,
      onJourneyChange,
      parentJourney,
      stepsState
    ]
  )

  /**
   * @function handleAction Gets triggered when action buttons are pressed
   */
  const handleAction: NavigationContextValue['onAction'] = async (action) => {
    // only use the states that belong to the Steps in History
    const historyIndexes = history.stack.map(({ stepIndex }) => stepIndex)
    const historyErrors = stepsErrors.map((errors, index) =>
      historyIndexes.includes(index) ? errors : null
    )

    switch (action.actionType) {
      case ACTION_TYPE.GO_BACK: {
        handleGoBack()
        break
      }
      case ACTION_TYPE.GO_NEXT: {
        navigateToNextStep(action.targetStepId)
        setIsNavigatingBack(false)
        // after submission,, if we landed here by the user clicking next after submit, lets reset the journey submitted state
        if (submitJourneySuccess) {
          setSubmitJourneySuccess(false)
        }
        break
      }
      case ACTION_TYPE.SUBMIT_GO_NEXT: {
        setIsNavigatingBack(false)
        if (historyErrors[currentStepIndex]?.length) {
          updateJourneyContextWithError()
        } else if (!flags.isPreview) {
          if (historyErrors.some((errors) => errors?.length)) {
            console.warn(
              'Journey submitted with errors',
              journey.journeyId,
              historyErrors
            )
          }

          dispatchLoadingEvent(true)

          updateContext((currentContext) => ({
            ...currentContext,
            submissionErrorKey: undefined
          }))

          try {
            await submissionListener(
              activeJourney,
              stepsState,
              history,
              context,
              displayConditionsStatusRef.current,
              t,
              clearCurrentUserSession
            )
            analytics?.addEvents([
              {
                type: 'journey_submit',
                details: {},
                timestamp: new Date().toISOString()
              }
            ])
            setSubmitJourneySuccess(true)
            navigateToNextStep(action.targetStepId)
          } catch (error) {
            setSubmitJourneySuccess(false)

            if (error instanceof SubmissionError) {
              updateContext((currentContext) => ({
                ...currentContext,
                /**
                 * @todo Remove type assertion once we bump TypeScript version
                 */
                submissionErrorKey: (error as SubmissionError).errorKey
              }))
            }
          } finally {
            dispatchLoadingEvent(false)
          }
        }
        break
      }
      default:
        unreachable(action satisfies never)
    }
  }

  const journeyNavigationEventHandler = useCallback(
    ({
      detail: { payload }
    }: CustomEvent<EventDetailType<JourneyNavigationEventPayload>>) => {
      if (typeof payload?.stepIndex === 'number') {
        handleNavigationToStep(payload.stepIndex, {
          shouldReapplyLogics: true
        })
      }
    },
    [handleNavigationToStep]
  )

  const journeyExitEventHandler = useCallback(() => {
    cleanJourneyState()

    if (flags.mode === 'full-screen' || launcherJourney?.journeyId) {
      /**
       * On launcher journeys, the iframe to be adjusted is attached to
       * the launcher journey id instead of the current journey.
       */
      const journeyId = parentJourney.journeyId

      setIsFullscreen(false)
      publishExitFullScreenMessage({
        journeyId,
        isLauncherJourney: Boolean(launcherJourney)
      })
      handleNavigationToStep(0, {
        shouldStepOutOfLauncherJourney: true
      })
    }
  }, [
    cleanJourneyState,
    flags.mode,
    handleNavigationToStep,
    parentJourney.journeyId,
    launcherJourney
  ])

  useListener<JourneyNavigationEventPayload>(
    JOURNEY_NAVIGATION_EVENT,
    journeyNavigationEventHandler
  )
  useListener(JOURNEY_EXIT_EVENT, journeyExitEventHandler)

  const handleChange: StepComponentProps['onChange'] = (value) => {
    const { data } = value

    /**
     * If step journey launcher is being rendered, the step's newState
     * will be contained in the key named after the launcher block
     */
    const newState: StepState =
      launcherBlockName && launcherBlockName in data
        ? 'data' in data[launcherBlockName]
          ? data[launcherBlockName].data
          : data[launcherBlockName]
        : data

    const valueErrors = getValueErrors(
      value,
      currentStepIndex,
      newState,
      currentStep
    )

    const currentStepState = stepsState[currentStepIndex] ?? {}

    if (newState && !isEqual(newState, currentStepState)) {
      /* If state has changed */
      // handle navigation from Step events
      const triggerBlockNames = getWhichBlockHasChanged(
        currentStepState,
        newState
      )

      onStepStateChange({
        newState,
        stepIndex: currentStepIndex,
        valueErrors,
        triggerBlockNames
      })
    } else if (!isEqual(stepsErrors[currentStepIndex] ?? [], valueErrors)) {
      /* If state has not changed but errors have changed */
      const errors = [...stepsErrors]

      errors[currentStepIndex] = valueErrors
      updateContext((context) => ({
        ...context,
        _errors: errors
      }))
    }
  }

  const { theme: designBuilderTheme, designTokens: designBuilderDesignTokens } =
    useUpdateTheme()

  const { theme, transformedTheme, designTokens } = useThemes({
    journey,
    activeJourney,
    designBuilderTheme,
    designBuilderDesignTokens
  })

  // Handle custom css
  useCustomCSS(journey, designTokens?.custom_css)

  /**
   * @todo Have _journeySources be computed within the context provider component
   */
  useEffect(() => {
    updateContext((context) => ({
      ...context,
      _journeySources: generateSources('*', activeJourney.steps)
    }))
  }, [activeJourney.steps, updateContext])

  if (!context._isPreview && context._missingContext) {
    return (
      <JourneyNotFoundError
        embedMode={mode}
        isEmbedded={isEmbedded}
        onClose={() => {
          dispatchExitEvent()
          publishCloseJourneyMessage({
            journeyId: journey.journeyId,
            isDirty: false
          })
        }}
      />
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ConcordeThemeProvider
        designTokens={designTokens}
        theme={transformedTheme}
      >
        <CssBaseline />
        <NavigationProvider
          isNavigatingBack={isNavigatingBack}
          onAction={handleAction}
        >
          {isCurrentStepReadyToRender && (
            <LinearJourney
              activeJourneyEnabled={flags?.activeJourneyEnabled}
              currentStepIndex={currentStepIndex}
              debug={flags.debug}
              isFocusedOnJourney={isFocusedOnJourney}
              isLinearJourney={flags.isLinear}
              isParentLauncherJourney={Boolean(launcherJourney)}
              isPreview={flags.isPreview}
              journey={journey}
              mode={mode}
              onChange={handleChange}
              onCloseButtonClicked={handleOnClose}
              onGoBack={handleGoBack}
              onNavigateToStep={handleNavigationToStep}
              parentJourneyId={launcherJourney?.journeyId}
              remainingSteps={remainingSteps}
              showCloseButton={flags.showCloseButton}
              showTopBar={flags.showTopBar}
              stack={history.stack}
              stepsErrors={stepsErrors}
              stepsState={stepsState}
              submitJourneySuccess={submitJourneySuccess}
            />
          )}
        </NavigationProvider>
      </ConcordeThemeProvider>
    </MuiThemeProvider>
  )
}

JourneyPage.displayName = 'JourneyPage'
