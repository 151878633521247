import { ThemeProvider as ConcordeThemeProvider } from '@epilot/concorde-elements'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider
} from '@epilot/journey-elements'
import type { Journey } from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'

import useUpdateTheme from '../../hooks/useUpdateTheme'
import classes from '../JourneyPage/components/LinearJourney.module.scss'
import { useThemes } from '../JourneyPage/use-themes'

type JourneyLauncherTabsThemeProviderProps = ComponentProps<'div'> & {
  isFocusedOnJourney: boolean
  shouldUseConcordeJourneyLauncherTabs: boolean
  journey: Journey
}

export const JourneyLauncherTabsThemeProvider = ({
  isFocusedOnJourney,
  journey,
  children
}: JourneyLauncherTabsThemeProviderProps) => {
  const { theme: designBuilderTheme, designTokens: designBuilderDesignTokens } =
    useUpdateTheme()

  const { theme, transformedTheme, designTokens } = useThemes({
    journey,
    activeJourney: journey,
    designBuilderTheme: designBuilderTheme,
    designBuilderDesignTokens: designBuilderDesignTokens
  })

  return (
    <MuiThemeProvider theme={theme}>
      <ConcordeThemeProvider
        designTokens={designTokens}
        theme={transformedTheme}
      >
        {!isFocusedOnJourney && <CssBaseline />}
        <div className={classes.concordeStepContainer}>{children}</div>
      </ConcordeThemeProvider>
    </MuiThemeProvider>
  )
}
