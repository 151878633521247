import { JOURNEY_SAVING_PROGRESS_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { UserSessionIconProps } from './types'
import { UserSessionIconMUI } from './UserSessionIconMUI'

export function UserSessionIcon({
  iconColor,
  stepIndex,
  mode
}: UserSessionIconProps) {
  const { t } = useTranslation()
  const tooltipLabel =
    mode === JOURNEY_SAVING_PROGRESS_MODE.LOCAL
      ? t('user_progress.topbar_tooltip_local')
      : t('user_progress.topbar_tooltip_remote')
  const [showProgress, setShowProgress] = useState(false)

  useEffect(() => {
    // every time the stepIndex changes, setShowProgress as true for 500ms

    setShowProgress(true)
    const timeout = setTimeout(() => {
      setShowProgress(false)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [stepIndex])

  return (
    <UserSessionIconMUI
      iconColor={iconColor}
      showProgress={showProgress}
      tooltipLabel={tooltipLabel}
    />
  )
}
