import type { JsonSchema7 } from '@jsonforms/core'

import type { UiSchema } from '../../utils/types'

/**
 * Gets availabile string values by first attempting to check for schema.enum property,
 * and then falls back to find those values in the uischema options
 */
export const getEnumOptions = (
  schema: JsonSchema7,
  uischema: UiSchema
): string[] => {
  if (Array.isArray(uischema?.options?.options)) {
    const options = uischema?.options?.options.map((option) =>
      option === undefined || option === null ? '' : option
    )

    if (options && options.every((option) => typeof option === 'string')) {
      return options
    }
  }

  if (schema?.enum) {
    return schema.enum
  }

  return []
}

/**
 * Clean options from enum.
 */
export function cleanOptionsFromEnum(
  options: any[] | undefined, // this can be actually any type in the array
  enumOptions: string[] | undefined
) {
  return options?.filter((_label, idx) => enumOptions?.[idx])
}

/**
 * Appends a string to the field id
 */
export function appendToId(id?: string, text?: string) {
  return `${id || ''}.${text?.trim().replace(/\s/g, '_').replace(/\./g, '')}`
}

export const BUTTON_LAYOUT_OPTIONS = ['row', 'stack', 'chip']
