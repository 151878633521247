import { Spacing } from '@epilot/concorde-elements'
import type { PriceWithBlockMappings } from '@epilot/journey-logic-commons'
import {
  getAvailableChartsFromPrice,
  isRealtimePrice
} from '@epilot/journey-logic-commons'
import { Typography } from '@material-ui/core'
import { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { ChartPlaceholder } from './ChartPlaceholder'
import classes from './ProductDetailsChart.module.scss'

const PriceHistoryChart = lazy(() => import('./PriceHistoryChart'))

export const ProductDetailsChart = ({
  price
}: {
  price: PriceWithBlockMappings
}) => {
  const { t } = useTranslation()
  const availableCharts = getAvailableChartsFromPrice(price)
  const isRealtime = isRealtimePrice(price)

  return (
    <Spacing scale={4} variant={'stack'}>
      <Typography>
        {isRealtime
          ? t(
              'price_chart.explanation_realtime',
              'We have calculated an estimated price for you based on the average market price of the last month.'
            )
          : t(
              'price_chart.explanation',
              'This chart shows the past price development.'
            )}
      </Typography>
      <div className={classes.chartContainer}>
        <Suspense fallback={<ChartPlaceholder />}>
          <PriceHistoryChart availableCharts={availableCharts} />
        </Suspense>
      </div>
    </Spacing>
  )
}
