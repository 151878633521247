import {
  convertColorToHex,
  getAlphaContrastRatio
} from '@epilot/concorde-elements'

export const getIconColor = ({
  backgroundColor,
  mainColor
}: {
  backgroundColor: string
  mainColor: string
}) => {
  const hexBackgroundColor = convertColorToHex(backgroundColor)
  const hexMainColor = convertColorToHex(mainColor)

  const isBackgroundColorCloseToWhite =
    getAlphaContrastRatio(hexBackgroundColor, '#ffffff') < 2

  const isBackgroundColorCloseToMainColor =
    getAlphaContrastRatio(hexBackgroundColor, hexMainColor) < 2

  if (isBackgroundColorCloseToWhite && isBackgroundColorCloseToMainColor) {
    return 'black'
  }
  if (isBackgroundColorCloseToWhite) {
    return hexMainColor.slice(0, 7)
  }

  return 'white'
}
