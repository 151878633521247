/** @deprecated please use enum from {@link src/types/next/blocks/consentsBlock} instead */
export const ConsentTopics = {
  GTC: 'GTC',
  DATA_PRIVACY: 'DATA_PRIVACY',
  CANCELLATION_POLICY: 'CANCELLATION_POLICY',
  EMAIL_MARKETING: 'EMAIL_MARKETING',
  SMS_MARKETING: 'SMS_MARKETING',
  PHONE_CALL_MARKETING: 'PHONE_CALL_MARKETING',
  PRINT_MARKETING: 'PRINT_MARKETING'
}

export const DoubleOptInTopics = [
  ConsentTopics.EMAIL_MARKETING,
  ConsentTopics.SMS_MARKETING,
  ConsentTopics.PHONE_CALL_MARKETING,
  ConsentTopics.PRINT_MARKETING
]

export type ConsentsControlOptions = {
  configs: ConsentConfig[]
}

/** @deprecated please use {@link src/types/next/blocks/consentsBlock} instead */
export type ConsentTopic = keyof typeof ConsentTopics

export type ConsentConfig = {
  topics?: ConsentTopic[]
  textMD?: string
  required?: boolean
}

export type ConsentData = {
  topic: ConsentTopic
  text: string
  time: Date
  agreed: boolean
}
