import type { ChangeEvent, FocusEventHandler } from 'react'
import { useState, forwardRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { NumberTextField } from './NumberTextField'
import { TextField } from './TextField'
import type { TextFieldProps } from './types'

const DEFAULT_DEBOUNCE_TIME_MS = 300

type DebouncedTextFieldProps = TextFieldProps & {
  debounceTime?: number
  onChange?: (event: any) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void | string
  isNumberInput?: boolean
  /**
   * Formats the number input automatically
   */
  isFormattingEnabled?: boolean
}

export const DebouncedTextField = forwardRef<
  HTMLInputElement,
  DebouncedTextFieldProps
>(
  (
    {
      debounceTime = DEFAULT_DEBOUNCE_TIME_MS,
      isNumberInput,
      onChange,
      isFormattingEnabled,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState<any>(props.value)

    const onChangeDebounced = useDebouncedCallback((value: string) => {
      onChange?.({ target: { value } })
    }, debounceTime)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value)
      onChangeDebounced(event.target.value)
    }

    const handleNumberInputChange = (value: string) => {
      setValue(value)
      onChangeDebounced(value)
    }

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      if (props.onBlur) {
        const value = props.onBlur(event)

        if (typeof value === 'string') setValue(value)
      }
    }

    if (isNumberInput) {
      return (
        <NumberTextField
          {...props}
          isFormattingEnabled={isFormattingEnabled}
          onBlur={handleBlur}
          onChange={handleNumberInputChange}
          ref={ref}
          value={value}
        />
      )
    }

    return (
      <TextField
        {...props}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={ref}
        value={value}
      />
    )
  }
)

DebouncedTextField.displayName = 'DebouncedTextField'
