import type { DesignTokens } from '@epilot/journey-logic-commons'
import {
  DesignBuilderUpdateEvent,
  JourneyFeatureFlags as FeatureFlags
} from '@epilot/journey-logic-commons'
import { useEffect } from 'react'

import { useFlags } from '../blocks-renderers'
import { useDesignBuilderContext } from '../context/DesignBuilderContext'
import { env, isLocalOriginEventAllowed } from '../utils/config'

const useUpdateTheme = (): {
  theme: string
  logo: string
  designTokens?: DesignTokens
} => {
  const { theme, setTheme, logo, setLogo, designTokens, setDesignTokens } =
    useDesignBuilderContext()

  const isCustomCSSEnabled = useFlags()[FeatureFlags.CUSTOM_CSS]

  useEffect(() => {
    const updateThemeEventHandler = (event: MessageEvent) => {
      const origin = event.origin

      if (
        origin !== env('REACT_APP_JOURNEY_BUILDER_URL') &&
        !isLocalOriginEventAllowed(origin)
      ) {
        return
      }

      const data = event.data

      if (
        typeof data == 'object' &&
        data !== null &&
        data?.detail?.eventCaller === DesignBuilderUpdateEvent.UPDATE_THEME
      ) {
        const { theme, logo, journeyDesignTokens } = data.detail.payload

        setTheme(theme)
        setLogo(logo)
        setDesignTokens(journeyDesignTokens as DesignTokens)
      }
    }

    window.addEventListener('message', updateThemeEventHandler, false)

    return () => window.removeEventListener('message', updateThemeEventHandler)
  }, [setLogo, setTheme, setDesignTokens, isCustomCSSEnabled])

  return {
    theme,
    logo,
    ...(isCustomCSSEnabled ? { designTokens: designTokens } : {})
  }
}

export default useUpdateTheme
