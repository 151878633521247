import { CircularProgress } from '@epilot/journey-elements'

import classes from './ProductDetailsChart.module.scss'

export const ChartPlaceholder = () => {
  return (
    <div className={classes.chartPlaceholder}>
      <CircularProgress />
    </div>
  )
}
