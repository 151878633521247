import type {
  StepState,
  HistoryStack,
  JourneyAccessMode,
  JourneySession
} from '@epilot/journey-logic-commons'
import {
  JOURNEY_ACCESS_MODE,
  journeyStorage
} from '@epilot/journey-logic-commons'
import { JOURNEY_SAVING_PROGRESS_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { useCallback } from 'react'

import { parseUuidOnlyAndExcludeJourneyId } from '../../../../utils/parse'

import {
  deleteSessionRemotely,
  getSessionsRemotely,
  saveProgressRemotely
} from './service'
import type { useUserProgressProps } from './types'
import { isContextParamsEqual, mergeRemoteSessionsWithLocal } from './utils'

let currentSessionId: string | undefined = undefined
let journeyAPIBaseUrl: string | undefined = undefined
let uuidContextValues: Record<string, string> | undefined = undefined

export function useUserProgress({
  journey,
  paramContext
}: useUserProgressProps) {
  if (!uuidContextValues && paramContext) {
    uuidContextValues = parseUuidOnlyAndExcludeJourneyId(paramContext)
  }
  const mode = getStorageMode(
    journey?.settings?.savingProgress?.mode,
    journey?.settings?.accessMode
  )

  const onSaveProgress = (
    stepIndex: number,
    stepsState: StepState[],
    historyStack: HistoryStack[]
  ) => {
    if (
      journey?.journeyId &&
      mode !== JOURNEY_SAVING_PROGRESS_MODE.NONE &&
      currentSessionId
    ) {
      saveProgress(
        journey.journeyId,
        stepIndex,
        stepsState,
        currentSessionId,
        historyStack,
        Number(journey.revisions),
        mode,
        uuidContextValues
      )
    }
  }

  const setCurrentSessionId = useCallback(
    (sessionId: string, journeyAPI: string) => {
      currentSessionId = sessionId
      journeyAPIBaseUrl = journeyAPI
    },
    []
  )

  const getExistingSessions = useCallback(
    (journeyAPI: string) => {
      journeyAPIBaseUrl = journeyAPI
      if (journey?.journeyId && mode !== JOURNEY_SAVING_PROGRESS_MODE.NONE) {
        return getSessions(
          journey.journeyId,
          Number(journey.revisions),
          mode,
          uuidContextValues
        )
      }

      return Promise.resolve([])
    },
    [journey, mode]
  )

  const clearCurrentUserSession = async () => {
    if (journey?.journeyId && currentSessionId) {
      const sessions = await getSessions(
        journey.journeyId,
        Number(journey.revisions),
        mode,
        uuidContextValues
      )

      deleteSessionLocally(sessions, journey.journeyId)

      if (mode === JOURNEY_SAVING_PROGRESS_MODE.REMOTE && journeyAPIBaseUrl) {
        const currentSession = sessions.find(
          (session) => session.id === currentSessionId
        )

        currentSession &&
          deleteSessionRemotely(currentSession, journeyAPIBaseUrl)
      }

      currentSessionId = undefined
    }
  }

  return {
    saveProgress: onSaveProgress,
    setCurrentSessionId,
    currentSessionId,
    getExistingSessions,
    clearCurrentUserSession
  }
}

export function getJourneySessionKey(journeyId: string) {
  return `journey-progress-${journeyId}`
}

// write data to local storage
const saveProgress = (
  journeyId: string,
  stepIndex: number,
  stepsState: StepState[],
  sessionId: string,
  historyStack: HistoryStack[],
  journeyVersion: number,
  mode: JOURNEY_SAVING_PROGRESS_MODE,
  uuidContextValues?: Record<string, string>
): void => {
  // if data in the stepsState array is empty, return
  if (
    !stepsState.length ||
    stepsState.filter(
      (stepState) => !(stepState && Object.keys(stepState).length > 0)
    ).length === 0
  ) {
    return
  }
  const previousProgressStr = journeyStorage.getItem(
    getJourneySessionKey(journeyId)
  )

  let previousProgress: JourneySession[] = []

  if (previousProgressStr) {
    previousProgress = JSON.parse(previousProgressStr)
  }

  const currentSessionIndex = previousProgress.findIndex(
    (session) => session.id === sessionId
  )

  const progress: JourneySession = {
    journeyId,
    stepIndex,
    stepsState,
    id: sessionId,
    date: new Date(),
    journeyVersion,
    historyStack,
    uuidContextValues
  }

  if (currentSessionIndex > -1) {
    previousProgress[currentSessionIndex] = progress
  } else {
    previousProgress.push(progress)
  }

  saveProgressLocally(previousProgress, journeyId)
  journeyAPIBaseUrl &&
    mode === JOURNEY_SAVING_PROGRESS_MODE.REMOTE &&
    saveProgressRemotely(progress, journeyAPIBaseUrl)
}

async function deleteSessionLocally(
  sessions: JourneySession[],
  journeyId: string
) {
  const cleanSessions = sessions?.filter(
    (session) => session.id !== currentSessionId
  )

  journeyStorage.setItem(
    getJourneySessionKey(journeyId),
    JSON.stringify(cleanSessions)
  )
}

export function getStorageMode(
  storageMode?: JOURNEY_SAVING_PROGRESS_MODE,
  journeyAccessMode?: JourneyAccessMode
) {
  switch (storageMode) {
    // in case it is auto, check the journey access mode, if private then store remotely, otherwise store locally
    case JOURNEY_SAVING_PROGRESS_MODE.AUTO:
      return journeyAccessMode === JOURNEY_ACCESS_MODE.PRIVATE
        ? JOURNEY_SAVING_PROGRESS_MODE.REMOTE
        : JOURNEY_SAVING_PROGRESS_MODE.LOCAL

    case JOURNEY_SAVING_PROGRESS_MODE.REMOTE:
      return JOURNEY_SAVING_PROGRESS_MODE.REMOTE
    case JOURNEY_SAVING_PROGRESS_MODE.LOCAL:
      return JOURNEY_SAVING_PROGRESS_MODE.LOCAL
    default:
      return JOURNEY_SAVING_PROGRESS_MODE.NONE
  }
}

function saveProgressLocally(
  previousProgress: JourneySession[],
  journeyId: string
) {
  journeyStorage.setItem(
    getJourneySessionKey(journeyId),
    JSON.stringify(previousProgress)
  )
}

async function getSessions(
  journeyId: string,
  journeyVersion: number,
  mode: JOURNEY_SAVING_PROGRESS_MODE,
  uuidContextValues?: Record<string, string>
) {
  let sessions = getSessionsLocally(journeyId, journeyVersion)

  if (mode === JOURNEY_SAVING_PROGRESS_MODE.REMOTE && journeyAPIBaseUrl) {
    const remoteSessions = await getSessionsRemotely(
      journeyId,
      journeyAPIBaseUrl,
      journeyVersion
    )

    if (Array.isArray(remoteSessions) && remoteSessions.length > 0) {
      sessions = mergeRemoteSessionsWithLocal(sessions, remoteSessions)
      saveProgressLocally(sessions, journeyId)
    }
  }

  // in case uuidContextValues has value, accept only sessions that matches them
  sessions = sessions.filter((session: JourneySession): boolean =>
    isContextParamsEqual(uuidContextValues, session.uuidContextValues)
  )

  return sessions
}

function getSessionsLocally(journeyId: string, journeyVersion?: number) {
  const previousProgressStr = journeyStorage.getItem(
    getJourneySessionKey(journeyId)
  )

  let previousProgress: JourneySession[] = []

  if (previousProgressStr) {
    previousProgress = JSON.parse(previousProgressStr)
  }

  return typeof journeyVersion === 'number'
    ? previousProgress.filter(
        (session) => session.journeyVersion === journeyVersion
      )
    : previousProgress
}
