import {
  areProductDownloadsHydrated,
  areProductImagesHydrated,
  extractPaymentConditionItems,
  JourneyFeatureFlags
} from '@epilot/journey-logic-commons'
import type {
  Price,
  PriceWithBlockMappings,
  Product,
  Coupon
} from '@epilot/journey-logic-commons'
import type { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { useMemo } from 'react'

import type { ProductDetailsContentConfig } from '../../../../../concorde-renderers'
import { ProductDetailsContent as ConcordeProductDetailsContent } from '../../../../../concorde-renderers'
import { ProductDetailsContent } from '../../../../components/ProductDetails/components/ProductDetailsContent'
import { useFlags } from '../../../../providers'
import { useComputePricingDetails } from '../../../../utils/pricing'

import type { SummaryPriceItem } from './useGetProductBlockSummaryData'

export const ProductBlockSummaryItem = ({
  item,
  generalConfiguration,
  classes
}: {
  item: SummaryPriceItem
  classes: ClassNameMap<string>
  generalConfiguration: {
    displayUnitaryAverage: boolean
    showTrailingDecimalZeros: boolean
    summaryBlockId: string
    useNewDesign: boolean
  }
}) => {
  const {
    summaryBlockId,
    displayUnitaryAverage,
    showTrailingDecimalZeros,
    useNewDesign
  } = generalConfiguration
  const product = item._product as Product
  const price = item._price as PriceWithBlockMappings
  const coupons = item._coupons as Coupon[]
  const externalCatalogData = item.externalCatalogData
  const useConcordeProductDetailsFF = useFlags()[
    JourneyFeatureFlags.CONCORDE_PRODUCT_DETAILS
  ] as boolean

  const enableCompositePriceConditionsFF = useFlags()[
    JourneyFeatureFlags.PRICE_COMPOSITE_CONDITIONS
  ] as boolean

  const { pricingDetails } = useComputePricingDetails({
    price: item,
    externalCatalogData: externalCatalogData,
    quantity: item.quantity || 1,
    /**
     * Non-concorde shopping cart does not support promo-code redemption
     */
    redeemedPromos: []
  })

  const downloads = useMemo(
    () =>
      areProductDownloadsHydrated(item._product)
        ? item._product.product_downloads.filter(
            (download) => download.access_control !== 'private'
          )
        : [],
    [item._product]
  )

  const conditions = useMemo(
    () =>
      extractPaymentConditionItems(
        [price, ...((price?.price_components as Price[]) ?? [])],
        enableCompositePriceConditionsFF
      ),
    [price, enableCompositePriceConditionsFF]
  )

  const shouldDisplayImageGallery = useMemo(
    () =>
      areProductImagesHydrated(product) &&
      product.product_images.some(
        (image) => image.access_control !== 'private'
      ),
    [product]
  )

  const productDetailsConfig = {
    showChart: false,
    showFeatures: false
  } satisfies ProductDetailsContentConfig

  return (
    <div className={classes.summaryItem}>
      {useNewDesign && useConcordeProductDetailsFF ? (
        <ConcordeProductDetailsContent
          conditions={conditions}
          config={productDetailsConfig}
          data={{
            product,
            price,
            coupons,
            id: summaryBlockId,
            pricingDetails,
            externalCatalogData,
            displayUnitaryAverage,
            showTrailingDecimalZeros
          }}
          downloads={downloads}
        />
      ) : (
        <ProductDetailsContent
          classes={classes}
          conditions={conditions}
          data={{
            product,
            price,
            id: summaryBlockId,
            pricingDetails,
            externalCatalogData,
            displayUnitaryAverage,
            showTrailingDecimalZeros
          }}
          downloads={downloads}
          shouldDisplayImageGallery={shouldDisplayImageGallery}
          shouldDisplayPriceChart={false}
        />
      )}
    </div>
  )
}
