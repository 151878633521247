import type { JourneySession } from '@epilot/journey-logic-commons'
import { isEqual } from 'radashi'

export function mergeRemoteSessionsWithLocal(
  localSessions: JourneySession[],
  remoteSessions: JourneySession[]
) {
  const sessionMap = new Map<string, JourneySession>()

  // Helper function to add/update the session in the map
  function addOrUpdateSession(session: JourneySession) {
    const existingSession = sessionMap.get(session.id)

    if (
      !existingSession ||
      new Date(session.date) > new Date(existingSession.date)
    ) {
      sessionMap.set(session.id, session)
    }
  }

  // Merge both arrays
  localSessions.forEach(addOrUpdateSession)
  remoteSessions.forEach(addOrUpdateSession)

  // Convert map values to array and sort by date (most recent first)
  return Array.from(sessionMap.values()).sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  )
}

export function isContextParamsEqual(
  currentParams?: Record<string, string>,
  sessionParams?: Record<string, string>
) {
  if (!currentParams && !sessionParams) {
    return true
  }
  if (!currentParams || !sessionParams) {
    return false
  }

  return isEqual(currentParams, sessionParams)
}

export function getUpdateJourneySessionURL(
  journeyId: string,
  userId: string,
  journeyAPIBaseUrl: string
) {
  return `${journeyAPIBaseUrl}/user-sessions/${journeyId}/session?user_id=${encodeURIComponent(userId)}`
}

export function getDeleteJourneySessionURL(
  journeyId: string,
  userId: string,
  sessionId: string,
  journeyAPIBaseUrl: string
) {
  return `${journeyAPIBaseUrl}/user-sessions/${journeyId}/session/${sessionId}?user_id=${encodeURIComponent(userId)}`
}

export function getJourneySessionsURL(
  journeyId: string,
  userId: string,
  journeyAPIBaseUrl: string
) {
  return `${journeyAPIBaseUrl}/user-sessions/${journeyId}?user_id=${encodeURIComponent(userId)}`
}
