import { useJourneyContext } from '../../../../../blocks-renderers/providers/JourneyContext'

export const useGetJourneySettings = () => {
  const { context } = useJourneyContext()
  const useNewDesign = Boolean(context.journey.settings?.useNewDesign)

  return {
    useNewDesign
  }
}
