import type {
  PriceItemWithBlockConfiguration,
  CompositePrice,
  ExternalCatalogData,
  Price,
  SelectedProductTile,
  ShoppingCartDataItem,
  Source
} from '@epilot/journey-logic-commons'
import { toDashCase, defineCartData } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { usePromoCodeValidationState } from '../../../../../concorde-renderers/renderers/ShopCartControl/components/ShoppingCartPromoCode/hooks/usePromoCodeValidationState'

import { useGetJourneySettings } from './useGetJourneySettings'

export type SummaryPriceItem = PriceItemWithBlockConfiguration & {
  externalCatalogData: ExternalCatalogData | undefined
}

export type SummaryGeneralConfiguration = {
  displayUnitaryAverage: boolean
  showTrailingDecimalZeros: boolean
  summaryBlockId: string
  useNewDesign: boolean
}

type UseGetProductBlockSummaryDataProps = {
  data: SelectedProductTile | SelectedProductTile[]
  source: Source | undefined
}

export const useGetProductBlockSummaryData = (
  props: UseGetProductBlockSummaryDataProps
): {
  summaryPriceItems: SummaryPriceItem[]
  generalConfiguration: SummaryGeneralConfiguration
} => {
  const { redeemedPromos } = usePromoCodeValidationState()
  const { useNewDesign } = useGetJourneySettings()

  const summaryPriceItems = useMemo(() => {
    const items = Array.isArray(props.data) ? props.data : [props.data]

    const productsData = items.map<ShoppingCartDataItem>((item) => {
      const { product, quantity } = item
      const { selectedPriceId, selectionMetadata } = product ?? {}
      const {
        selectedProduct,
        selectedPrice,
        selectedExternalCatalogData,
        blockConfiguration
      } = selectionMetadata ?? {}

      return {
        _id: selectedPriceId ?? '',
        quantity,
        product: selectedProduct,
        price: selectedPrice as Price | CompositePrice,
        externalCatalogData: selectedExternalCatalogData,
        blockConfiguration
      }
    })

    const { aggregatedPrices } = defineCartData(productsData, {
      redeemedPromos
    })

    const aggregatedItems =
      aggregatedPrices.items as PriceItemWithBlockConfiguration[]

    return (
      aggregatedItems?.map((item) => ({
        ...item,
        externalCatalogData: productsData.find(
          (cartItem) => cartItem._id === item._id
        )?.externalCatalogData
      })) ?? []
    )
  }, [props.data, redeemedPromos])

  const { source } = props

  const generalConfiguration = {
    displayUnitaryAverage:
      source?.uischema.options?.displayUnitaryAverage ?? true,
    showTrailingDecimalZeros:
      Boolean(source?.uischema.options?.showTrailingDecimalZeros) ?? false,
    summaryBlockId: `product_summary_${source?.stepIndex || 0}_${toDashCase(source?.name || '')}`,
    useNewDesign: useNewDesign
  }

  return { summaryPriceItems, generalConfiguration }
}
