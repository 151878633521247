export abstract class AuthServiceError extends Error {
  /**
   * Uniquely identifies the error type
   */
  public readonly code: string

  constructor(message: string, code: string) {
    super(message)
    this.code = code
  }
}
