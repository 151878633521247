import { Icon, useTheme } from '@epilot/concorde-elements'
import type { Step } from '@epilot/journey-logic-commons'
import classNames from 'classnames'

import { getIconColor } from '../../TopBar/utils'

import classes from './DesktopStepper.module.scss'

type DesktopStepperProps = {
  onStepClick: (index: number) => void
  steps: Step[]
  injectedStepsCount: number
  currentStepIndex: number
  showStepperLabels: boolean
}

export const DesktopStepper = ({
  onStepClick,
  steps,
  injectedStepsCount,
  currentStepIndex,
  showStepperLabels
}: DesktopStepperProps) => {
  return (
    <div className={classes.stepperContainer}>
      {steps.map((step, index) =>
        injectedStepsCount && index <= injectedStepsCount ? null : (
          <DesktopStep
            index={index}
            isActive={currentStepIndex >= index}
            isCompleted={currentStepIndex > index}
            key={index}
            label={step.name}
            onClick={() => onStepClick(index)}
            showLine={index < steps.length - 1}
            showStepperLabels={showStepperLabels}
          />
        )
      )}
    </div>
  )
}

interface StepProps {
  index: number
  label: string
  isActive: boolean
  isCompleted?: boolean
  onClick: () => void
  showStepperLabels: boolean
  showLine: boolean
}

const DesktopStep = ({
  index,
  label,
  isActive,
  isCompleted,
  onClick,
  showStepperLabels,
  showLine
}: StepProps) => {
  const theme = useTheme()

  const iconColor = getIconColor({
    backgroundColor: theme.palette.primary,
    mainColor: theme.palette.primary
  })

  return (
    <button
      className={classNames(classes.step, {
        [classes.active]: isActive,
        [classes.completed]: isCompleted
      })}
      onClick={onClick}
    >
      <div className={classes.circle} style={{ color: iconColor }}>
        {isCompleted ? <Icon name="check" size="24px" /> : index + 1}
      </div>
      {showStepperLabels && <div className={classes.label}>{label}</div>}
      {showLine && (
        <div
          className={classNames(classes.line, {
            [classes.completedLine]: isCompleted
          })}
        />
      )}
    </button>
  )
}
