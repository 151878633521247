import {
  useTheme,
  IconButton,
  useIsMobileOrTablet
} from '@epilot/concorde-elements'
import { clsx } from '@epilot/journey-logic-commons'
import { JOURNEY_SAVING_PROGRESS_MODE } from '@epilot/journey-logic-commons/src/types/next'

import { getStorageMode } from '../../../../blocks-renderers'
import type { TopBarProps } from '../../../../components/TopBar'

import classes from './TopBar.module.scss'
import { UserSessionIcon } from './UserSessionIcon'
import { getIconColor } from './utils'

export const TopBar = (props: TopBarProps) => {
  const {
    logoUrl,
    onClose,
    onBack,
    isTopBarEnabled,
    isProgressBar,
    journey,
    stepIndex,
    showBackButton
  } = props
  const theme = useTheme()
  const isMobileOrTablet = useIsMobileOrTablet()
  const defaultBackgroundColor = theme?.palette.topbar || theme.palette.primary

  const iconColor = getIconColor({
    backgroundColor: defaultBackgroundColor,
    mainColor: theme.palette.primary
  })

  if (!isTopBarEnabled && onBack && showBackButton) {
    if (isProgressBar) {
      return (
        <IconButton
          aria-label="Back button"
          color={iconColor}
          name="arrow_back"
          onClick={onBack}
        />
      )
    } else if (!isMobileOrTablet) {
      return (
        <IconButton
          aria-label="Back button"
          color="primary"
          name="arrow_back"
          onClick={onBack}
          size="16px"
        />
      )
    }
  }

  const sessionStorageMode = getStorageMode(
    journey.settings?.savingProgress?.mode,
    journey.settings?.accessMode
  )

  if (!isTopBarEnabled) return null

  return (
    <div className={clsx('Concorde-Topbar', classes.toolbar)}>
      <>
        {onBack && showBackButton ? (
          <IconButton
            aria-label="Back button"
            color={iconColor}
            name="arrow_back"
            onClick={onBack}
            size="16px"
          />
        ) : (
          /* to align the logo */
          <div className={classes.dummyDiv} />
        )}

        {logoUrl && (
          <div className={classes.logoContainer}>
            <img alt="Logo" className={classes.logo} src={logoUrl} />
          </div>
        )}
        {onClose && (
          <div className={classes.rightIcons}>
            {sessionStorageMode !== JOURNEY_SAVING_PROGRESS_MODE.NONE && (
              <UserSessionIcon
                iconColor={iconColor}
                mode={sessionStorageMode}
                stepIndex={stepIndex}
              />
            )}
            <IconButton
              aria-label="Close Button"
              color={iconColor}
              name="close"
              onClick={onClose}
              size="16px"
            />
          </div>
        )}
      </>
    </div>
  )
}

TopBar.displayName = 'TopBar'
