import {
  getAvailableProductIds,
  getJourneyPricesToQuery,
  getPricingClient
} from '@epilot/journey-logic-commons'
import type {
  GetAvailableProductIdsOptions,
  GetJourneyPricesToQueryOptions,
  ProductType
} from '@epilot/journey-logic-commons'
import type {
  Components,
  IntegrationId,
  JourneyContext,
  Paths
} from '@epilot/pricing-client'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { useConfig } from '../providers'

export type CatalogSearchResult = Components.Schemas.CatalogSearchResult
type ComputePriceParams = Components.Schemas.ComputePriceParams

type AverageMarketPriceParams = Paths.$AverageMarketPrice.QueryParameters

export type ProductPrice = {
  productId: string
  priceId: string
}

const SERVER_KEYS = {
  SEARCH_PRODUCTS_BY_PRICES: 'SEARCH_PRODUCTS_BY_PRICES'
}

export const searchGetAgProviders = async (
  apiUrl: string,
  orgId: string,
  params: {
    productType: ProductType
    postalCode: string
    city: string
    street?: string
    streetNumber?: string
  }
) => {
  return getPricingClient(apiUrl).$searchProviders(
    {
      integrationId: 'getag',
      'X-Epilot-Org-ID': orgId
    },
    {
      type: params.productType,
      postal_code: params.postalCode,
      city: params.city,
      street: params.street,
      street_number: params.streetNumber
    }
  )
}

export const searchExternalCatalog = async (
  apiUrl: string,
  orgId: string,
  publicToken: string,
  integrationId: string,
  params: {
    context: JourneyContext
  }
) => {
  return getPricingClient(apiUrl, orgId, publicToken).$searchExternalCatalog(
    {
      integrationId: integrationId as IntegrationId
    },
    {
      context: params.context
    }
  )
}

export const computeGetAGPrices = async (
  apiUrl: string,
  orgId: string,
  params: ComputePriceParams
) => {
  return getPricingClient(apiUrl).$computePrice(
    {
      integrationId: 'getag',
      'X-Epilot-Org-ID': orgId
    },
    params
  )
}

export const fetchAverageMarketPrice = async (
  apiUrl: string,
  token: string,
  params: AverageMarketPriceParams
) => {
  return getPricingClient(apiUrl, undefined, token).$averageMarketPrice(params)
}

export async function searchPrices(
  apiUrl: string,
  orgId: string,
  productPriceIds: Array<string>,
  publicToken: string
) {
  const searchQuery = productPriceIds
    .flatMap((id) => [
      /* Find prices with this id */
      `(_id:${id} AND active:true)`,
      /* Find coupons for this price */
      `(prices.$relation.entity_id:${id} AND active:true)`
    ])
    .join(' OR ')

  const data = {
    q: searchQuery,
    hydrate: true
  }

  /**
   * @todo Remove assertion of response body type when the API response is typed to include coupons
   */
  return getPricingClient(apiUrl, orgId, publicToken).$searchCatalog(
    undefined,
    data
  )
}

const DEFAULT_SEARCH_PRICES_RESULTS: NonNullable<
  Awaited<ReturnType<typeof searchPrices>>['data']['results']
> = []
const DEFAULT_SEARCH_PRICES_HITS = 0

type UseSearchPricesOptions = GetAvailableProductIdsOptions &
  Pick<GetJourneyPricesToQueryOptions, 'journeyPrices'> & {
    orgId: string
    publicToken: string
  }

export const searchPricesQueryOptions = (
  orgId: string,
  productPriceIds: Array<string>,
  publicToken: string,
  PRICING_API_URL: string
) => {
  return {
    queryKey: [
      SERVER_KEYS.SEARCH_PRODUCTS_BY_PRICES,
      orgId,
      ...productPriceIds
    ],
    queryFn: async () => {
      if (!orgId || productPriceIds.length === 0) {
        return {
          data: {
            hits: DEFAULT_SEARCH_PRICES_HITS,
            results: DEFAULT_SEARCH_PRICES_RESULTS
          }
        }
      } else {
        return searchPrices(
          PRICING_API_URL,
          orgId,
          productPriceIds,
          publicToken
        )
      }
    }
  }
}

export function useSearchPrices({
  orgId,
  journeyPrices,
  crossSellProductIds,
  availabilityProductIds,
  publicToken
}: UseSearchPricesOptions) {
  const { PRICING_API_URL } = useConfig()
  const productPriceIds = useMemo(() => {
    const availableProductIds = getAvailableProductIds({
      crossSellProductIds,
      availabilityProductIds
    })

    const pricesToQuery = getJourneyPricesToQuery({
      journeyPrices,
      availableProductIds
    })

    return extractProductPriceIds(pricesToQuery)
  }, [availabilityProductIds, crossSellProductIds, journeyPrices])

  const queryResult = useQuery(
    searchPricesQueryOptions(
      orgId,
      productPriceIds,
      publicToken,
      PRICING_API_URL
    )
  )

  const { data, ...rest } = queryResult

  return useMemo(
    () => ({
      ...rest,
      hits: DEFAULT_SEARCH_PRICES_HITS,
      results: DEFAULT_SEARCH_PRICES_RESULTS,
      ...data?.data
    }),
    [rest, data?.data]
  )
}

const deduplicateArray = <T>(array: T[]) => [...new Set(array)]

export const extractProductPriceIds = (prices: ProductPrice[]) =>
  deduplicateArray(
    prices
      .flatMap(({ productId, priceId }) => [productId, priceId])
      .filter(Boolean)
  )
