import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@epilot/concorde-elements'
import { useTranslation } from 'react-i18next'

import classes from './SessionSelectDialog.module.scss'
import type { SelectorDialogProps } from './types'

export function SessionSelectorDialogConcorde({
  open,
  setSelectedId,
  value,
  onDiscard,
  onSessionSelected,
  sessionsIds,
  sessionsLabel
}: SelectorDialogProps) {
  const { t } = useTranslation()

  return (
    <Modal onClose={onDiscard} open={open}>
      <div className={classes.container}>
        <ModalHeader>
          <Typography as="h4">
            {t('user_progress.dialog_header', 'Session Picker')}
          </Typography>
        </ModalHeader>

        <ModalContent>
          <Typography>{t('user_progress.dialog_subheader')}</Typography>
          <RadioGroup orientation="vertical" value={value}>
            {sessionsIds.map((id, index) => (
              <Radio
                key={id}
                label={sessionsLabel[index]}
                onChange={() => setSelectedId(id)}
                value={id}
              />
            ))}
          </RadioGroup>
        </ModalContent>
        <ModalActions>
          <Button
            color="primary"
            label={t('user_progress.button_discard', 'Discard')}
            onClick={() => onDiscard && onDiscard()}
            variant="bare"
          />
          <Tooltip
            isLocal={true}
            title={!value ? t('user_progress.button_tooltip') : ''}
          >
            <span>
              <Button
                isDisabled={!value}
                label={t('user_progress.button_continue', 'Continue')}
                onClick={() => value && onSessionSelected?.(value)}
                variant="primary"
              />
            </span>
          </Tooltip>
        </ModalActions>
      </div>
    </Modal>
  )
}
