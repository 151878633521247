import type { UiSchema } from '../../utils'
import type { BlockVariableConfig } from '../types'

import { getValuesFromBlockState } from './utils'

type BlockState = {
  solarPanelsUserCount?: number
  maxArrayAreaMeters2?: number
  panelLifetimeYears?: number
  co2Savings?: number
  coordinates?: string
  maxArrayPanelsCount?: number
}

const optionalPvRoofPlannerBlockProperties = ['solarPanelsUserCount'] as const

export const pvRoofPlannerBlockProperties = [
  'maxArrayAreaMeters2',
  'co2Savings',
  'maxArrayPanelsCount',
  ...optionalPvRoofPlannerBlockProperties
] as const

export type PvRoofPlannerBlockProperties =
  (typeof pvRoofPlannerBlockProperties)[number]

export const getVariabelsFromPvRoofPlannerBlock = (
  uiSchema: UiSchema
): BlockVariableConfig => {
  const properties: PvRoofPlannerBlockProperties[] = [
    'maxArrayAreaMeters2',
    'co2Savings'
  ]

  if (uiSchema.options?.showRecommendAmountOfPanels === true) {
    properties.push('solarPanelsUserCount')
  }

  properties.push('maxArrayPanelsCount')

  return {
    isAccessedByIndex: false,
    properties: properties
  }
}

export const getValuesFromPvRoofPlannerBlock = ({
  state: blockState
}: {
  state: unknown
}) => {
  if (!blockState) {
    return {}
  }

  const data: Partial<Record<PvRoofPlannerBlockProperties, string>> =
    getValuesFromBlockState(
      blockState as BlockState,
      pvRoofPlannerBlockProperties
    )

  return data
}
