import {
  blockController,
  CONTROL_NAME,
  type Journey,
  type ProductControlOptions
} from '@epilot/journey-logic-commons'
import { useEffect } from 'react'

import { queryClient } from '../blocks-renderers/providers/ReactQuery'
import {
  extractProductPriceIds,
  searchPricesQueryOptions
} from '../blocks-renderers/services/product-service'

type UsePrefetchCatalogQueriesProps = {
  journey: Journey | undefined
  isPreview: boolean
  pricingApiUrl: string
  enabled: boolean
}

export const usePrefetchCatalogQueries = ({
  journey,
  isPreview,
  pricingApiUrl,
  enabled
}: UsePrefetchCatalogQueriesProps) => {
  const journeyId = journey?.journeyId
  const publicToken = journey?.settings?.publicToken ?? ''
  const organizationId = journey?.organizationId

  useEffect(() => {
    if (!enabled || !journeyId || isPreview || !organizationId) {
      return
    }

    // Find all product selection blocks in the journey
    const productSelectionBlocks = blockController.findBlocks(journey.steps, {
      type: CONTROL_NAME.PRODUCT_SELECTION_CONTROL
    })

    // Prefetch internal product selection options
    productSelectionBlocks
      ?.map((block) => block.uischema.options as ProductControlOptions)
      .filter(shouldPrefetchProductSelectionBlock)
      .forEach((option) => {
        const productPriceIds = extractProductPriceIds(option.products)

        if (productPriceIds.length === 0) return

        queryClient.prefetchQuery(
          searchPricesQueryOptions(
            organizationId,
            productPriceIds,
            publicToken,
            pricingApiUrl
          )
        )
      })
    // using exhaustive-deps because of journey.steps changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    journeyId,
    isPreview,
    pricingApiUrl,
    organizationId,
    publicToken,
    enabled
  ])
}

const shouldPrefetchProductSelectionBlock = (
  option: ProductControlOptions
): boolean => {
  return (
    !option.externalIntegration?.enabled &&
    Array.isArray(option.products) &&
    option.products.length > 0
  )
}
