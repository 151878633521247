import type {
  EpilotECPTokenPayload,
  JourneySession
} from '@epilot/journey-logic-commons'
import { jwtDecode } from 'jwt-decode'

import { getLocalToken } from '../usePortalCheck'

import type { UserSessions } from './types'
import {
  getDeleteJourneySessionURL,
  getJourneySessionsURL,
  getUpdateJourneySessionURL
} from './utils'

export function saveProgressRemotely(
  jourrneySession: JourneySession,
  journeyAPIBaseUrl: string
) {
  const token = getLocalToken()

  if (token) {
    const claims = jwtDecode<EpilotECPTokenPayload>(token)
    const url = getUpdateJourneySessionURL(
      jourrneySession.journeyId,
      claims.email,
      journeyAPIBaseUrl
    )

    // put jourrneySession via fetch call to url, token should be sat in the Authorization header
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(jourrneySession)
    })
  }
}

export async function getSessionsRemotely(
  journeyId: string,
  journeyAPIBaseUrl: string,
  journeyVersion?: number
) {
  const token = getLocalToken()

  if (token) {
    try {
      const claims = jwtDecode<EpilotECPTokenPayload>(token)
      const url = getJourneySessionsURL(
        journeyId,
        claims.email,
        journeyAPIBaseUrl
      )

      const res = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      })

      const userSessions: UserSessions = await res.json()

      const previousProgress = userSessions.journeyId
        ? userSessions.sessions
        : []

      return typeof journeyVersion === 'number'
        ? previousProgress.filter(
            (session) => session.journeyVersion === journeyVersion
          )
        : previousProgress
    } catch {
      return []
    }
  }

  return []
}

export function deleteSessionRemotely(
  jourrneySession: JourneySession,
  journeyAPIBaseUrl: string
) {
  const token = getLocalToken()

  if (token) {
    const claims = jwtDecode<EpilotECPTokenPayload>(token)
    const url = getDeleteJourneySessionURL(
      jourrneySession.journeyId,
      claims.email,
      jourrneySession.id,
      journeyAPIBaseUrl
    )

    // put jourrneySession via fetch call to url, token should be sat in the Authorization header
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
  }
}
