import { makeStyles, createStyles, List } from '@epilot/journey-elements'
import type { PaymentConditionItem } from '@epilot/journey-logic-commons'

import { ProductDetailsPaymentConditionsItem } from './ProductDetailsPaymentConditionsItem'

type ProductDetailsPaymentConditionsProps = {
  conditions: PaymentConditionItem[]
}

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      '& li': {
        padding: '0'
      }
    }
  })
)

export const ProductDetailsPaymentConditions = ({
  conditions
}: ProductDetailsPaymentConditionsProps) => {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      {conditions.map((condition) => (
        <ProductDetailsPaymentConditionsItem
          item={condition}
          key={condition.type}
        />
      ))}
    </List>
  )
}
