import { makeStyles, createStyles } from '@material-ui/core/styles'

import type { EpilotTheme } from '..'
import { isTransparent } from '../utils/isTransparent'

export const useAutocompleteStyles = makeStyles<EpilotTheme>((theme) =>
  createStyles({
    clearIconOverwrite: {
      '& .MuiAutocomplete-clearIndicator': {
        color: theme.palette?.secondary.main
      }
    },
    paper: {
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '4px',
      backgroundColor: isTransparent(theme.palette?.background.default)
        ? 'white'
        : theme.palette?.background.default || 'white'
    }
  })
)
