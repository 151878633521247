import { Spacing } from '@epilot/concorde-elements'
import type {
  CompositePriceItem,
  PriceItem,
  PriceWithBlockMappings,
  RecurrenceAmount,
  TaxWithTaxInclusivityBehaviour
} from '@epilot/journey-logic-commons'
import type { PricingDetails } from '@epilot/pricing'
import classNames from 'classnames'

import { PriceComponent } from './PriceComponent'
import classes from './PriceComponents.module.scss'
import { PriceComponentsTotals } from './PriceComponentsTotals'

export type PriceComponentsProps = {
  components: PriceItem[]
  item: PriceWithBlockMappings
  showTrailingDecimalZeros?: boolean
  displayUnitaryAverage?: boolean
  hasOnlyOnetimeRecurrences?: boolean
  animate?: boolean
  quantity: number
  inTheBox?: boolean
  displayTaxIndexes: boolean
  taxes?: TaxWithTaxInclusivityBehaviour[]
  recurrenceDetails: RecurrenceAmount[]
  pricingDetails: PricingDetails
}

export const PriceComponents = ({
  animate,
  components,
  item,
  showTrailingDecimalZeros,
  displayUnitaryAverage,
  hasOnlyOnetimeRecurrences = false,
  quantity,
  inTheBox = true,
  displayTaxIndexes = false,
  taxes,
  recurrenceDetails,
  pricingDetails
}: PriceComponentsProps) => {
  const renderComponents = (
    components: CompositePriceItem[],
    recurrences: RecurrenceAmount[]
  ) => (
    <Spacing className={classes.componentsGroup} scale={2} variant="stack">
      {components.map((component: CompositePriceItem, index) => (
        <PriceComponent
          animate={animate}
          component={component}
          displayUnitaryAverage={displayUnitaryAverage}
          key={`price_component_${index}_${component._price?.id}`}
          quantity={quantity}
          shouldHideRecurrence={true}
          showTrailingDecimalZeros={showTrailingDecimalZeros}
          taxes={displayTaxIndexes && taxes ? taxes : []}
        />
      ))}
      {recurrences.map((recurrence, index) => (
        <PriceComponentsTotals
          animate={animate}
          item={item}
          key={index}
          pricingDetails={pricingDetails}
          quantity={quantity}
          recurrenceDetails={recurrence}
          showTrailingDecimalZeros={showTrailingDecimalZeros}
        />
      ))}
    </Spacing>
  )

  const { oneTimeComponents, otherComponents } = components.reduce<{
    oneTimeComponents: PriceItem[]
    otherComponents: PriceItem[]
  }>(
    (acc, component) => {
      if ((component as PriceItem)?.type === 'one_time') {
        acc.oneTimeComponents.push(component)
      } else {
        acc.otherComponents.push(component)
      }

      return acc
    },
    { oneTimeComponents: [], otherComponents: [] }
  )

  const { oneTimeRecurrences, otherRecurrences } = recurrenceDetails.reduce<{
    oneTimeRecurrences: RecurrenceAmount[]
    otherRecurrences: RecurrenceAmount[]
  }>(
    (acc, recurrence) => {
      if (recurrence.type === 'one_time') {
        acc.oneTimeRecurrences.push(recurrence)
      } else {
        acc.otherRecurrences.push(recurrence)
      }

      return acc
    },
    { oneTimeRecurrences: [], otherRecurrences: [] }
  )

  return (
    <Spacing
      className={classNames(classes.priceComponents, {
        [classes.inTheBox]: inTheBox
      })}
      scale={2}
      variant="stack"
    >
      {!hasOnlyOnetimeRecurrences && otherComponents.length > 0 && (
        <>{renderComponents(otherComponents, otherRecurrences)}</>
      )}
      {oneTimeComponents.length > 0 && (
        <>{renderComponents(oneTimeComponents, oneTimeRecurrences)}</>
      )}
    </Spacing>
  )
}
