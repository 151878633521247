import { Icon, PopoverTrigger, Typography } from '@epilot/concorde-elements'
import { clsx, type Price } from '@epilot/journey-logic-commons'

import classes from './MappingDataTriggerButton.module.scss'

type MappingDataTriggerButtonProps = {
  className?: string
  handleClick: () => void
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  formattedQuantity?: string
  displayUnitaryAverage?: boolean
  formattedAverage?: string
  showPriceDescription?: boolean
  displayInOneLine?: boolean
  price: Price
}

const MappingDataTriggerButton = ({
  className,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  formattedQuantity,
  displayUnitaryAverage,
  formattedAverage,
  showPriceDescription,
  price
}: MappingDataTriggerButtonProps) => {
  return (
    <PopoverTrigger
      asChild
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <button
        className={clsx(classes.mappingDataTriggerButton, className)}
        data-testid="mapping-data-graduated-button-component"
      >
        <Typography
          className={classes.description}
          data-testid="mapping-data-graduated-button-description"
        >
          {formattedQuantity && (
            <>
              {formattedQuantity}
              {displayUnitaryAverage || showPriceDescription ? ` | ` : ' '}
            </>
          )}
          {displayUnitaryAverage && formattedAverage && `${formattedAverage}`}
          {displayUnitaryAverage &&
            formattedAverage &&
            showPriceDescription &&
            ` - `}
          {showPriceDescription ? `${price.description}` : ''}
        </Typography>
        <Icon
          className={classes.cumulativeInfoIcon}
          data-testid="tooltip"
          name={'info'}
          variant="outlined"
        />
      </button>
    </PopoverTrigger>
  )
}

MappingDataTriggerButton.displayName = 'MappingDataButton'

export { MappingDataTriggerButton }
