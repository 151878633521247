import { BodyText, StackSpacing } from '@epilot/journey-elements'
import type { PriceWithBlockMappings } from '@epilot/journey-logic-commons'
import {
  getAvailableChartsFromPrice,
  isRealtimePrice
} from '@epilot/journey-logic-commons'
import { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'

import { ChartPlaceholder } from './ChartPlaceholder'
import { usePriceHistoryChartStyles } from './styles'

const PriceHistoryChart = lazy(() => import('./PriceHistoryChart'))

export const ProductDetailsChart = ({
  price
}: {
  price: PriceWithBlockMappings
}) => {
  const { t } = useTranslation()
  const classes = usePriceHistoryChartStyles()
  const availableCharts = getAvailableChartsFromPrice(price)
  const isRealtime = isRealtimePrice(price)

  return (
    <StackSpacing scale={4}>
      <BodyText>
        {isRealtime
          ? t(
              'price_chart.explanation_realtime',
              'We have calculated an estimated price for you based on the average market price of the last month.'
            )
          : t(
              'price_chart.explanation',
              'This chart shows the past price development.'
            )}
      </BodyText>
      <div className={classes.chartContainer}>
        <Suspense fallback={<ChartPlaceholder />}>
          <PriceHistoryChart availableCharts={availableCharts} />
        </Suspense>
      </div>
    </StackSpacing>
  )
}
