import { getAlphaContrastRatio } from '@epilot/concorde-elements'
import { getContrastRatio, rgbToHex } from '@epilot/journey-elements'

export const getIconColor = ({
  backgroundColor,
  mainColor,
  rawColor
}: {
  backgroundColor: string
  mainColor: string
  rawColor?: string
}) => {
  let isBackgroundColorCloseToWhite =
    getContrastRatio(backgroundColor, '#ffffff') < 2

  if (rawColor?.startsWith('#') && rawColor?.length > 7) {
    isBackgroundColorCloseToWhite =
      getAlphaContrastRatio(rawColor, '#ffffff') < 2
  }

  const isBackgroundColorCloseToMainColor =
    getContrastRatio(backgroundColor, rgbToHex(mainColor).slice(0, 7)) < 2

  if (isBackgroundColorCloseToWhite && isBackgroundColorCloseToMainColor) {
    return 'black'
  }
  if (isBackgroundColorCloseToWhite) {
    return rgbToHex(mainColor).slice(0, 7)
  }

  return 'white'
}
