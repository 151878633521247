import { ListItem, Typography } from '@epilot/journey-elements'
import type { PaymentConditionItem } from '@epilot/journey-logic-commons'
import {
  PAYMENT_CONDITION_LABEL_TRANSLATION_DEFAULTS,
  PAYMENT_CONDITION_LABEL_TRANSLATION_KEYS
} from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import { DottedLine } from '../../../Product'

import { useStyles } from './styles'

type ProductDetailsPaymentConditionsItemProps = {
  item: PaymentConditionItem
}

export const ProductDetailsPaymentConditionsItem = ({
  item: { type, amount, unit }
}: ProductDetailsPaymentConditionsItemProps) => {
  const { t } = useTranslation()
  const labelId = `product-details-payment-conditions-item-${type}`
  const descriptionId = `product-details-payment-conditions-item-description-${type}`

  const classes = useStyles()

  return (
    <ListItem
      aria-describedby={descriptionId}
      aria-labelledby={labelId}
      dense
      disableGutters
    >
      <Typography
        className={classes.title}
        color="textSecondary"
        variant="body2"
      >
        <span id={labelId}>
          {t(
            PAYMENT_CONDITION_LABEL_TRANSLATION_KEYS[type],
            PAYMENT_CONDITION_LABEL_TRANSLATION_DEFAULTS[type]
          )}
        </span>
        <DottedLine />
        <span id={descriptionId}>
          {amount}{' '}
          {t(`product_details.billing_duration_unit.${unit}`, {
            count: amount
          })}
        </span>
      </Typography>
    </ListItem>
  )
}
