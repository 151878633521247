import {
  QueryClientProvider,
  QueryClient,
  QueryCache
} from '@tanstack/react-query'
import { type PropsWithChildren } from 'react'

type Props = PropsWithChildren<{}>

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.meta?.errorMessage) {
        // eslint-disable-next-line no-console
        console.error(query.meta.errorMessage, error)
      }
    }
  })
})

export const ReactQueryProvider = (props: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {/* Uncomment the next line to enable the dev tools for React Query when running locally. */}
      {/* {STAGE === 'local' && <ReactQueryDevtools initialIsOpen={false} />} */}
    </QueryClientProvider>
  )
}
