import { Spacing, Typography } from '@epilot/concorde-elements'
import type {
  PriceWithBlockMappings,
  RecurrenceAmount
} from '@epilot/journey-logic-commons'
import {
  computePriceDisplayInJourneys,
  getDisplayPrice,
  computePrice,
  shouldShowDecimals
} from '@epilot/journey-logic-commons'
import type { PricingDetails } from '@epilot/pricing'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './PriceComponentsTotals.module.scss'
import { PriceComponentsTotalsRecurrence } from './PriceComponentsTotalsRecurrence'

export type PriceTotalsProps = {
  animate?: boolean
  quantity: number
  item: PriceWithBlockMappings
  pricingDetails: PricingDetails
  showTrailingDecimalZeros?: boolean
  recurrenceDetails: RecurrenceAmount
}

export const PriceComponentsTotals = ({
  quantity,
  item,
  pricingDetails,
  showTrailingDecimalZeros,
  recurrenceDetails,
  animate
}: PriceTotalsProps) => {
  const { t: productSelectionT } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })

  const { amountTotal, amountTotalDecimal } = useMemo(
    () => computePrice({ pricingDetails }),
    [pricingDetails]
  )
  const priceDisplayInJourneys = useMemo(
    () => computePriceDisplayInJourneys(item, quantity),
    [item, quantity]
  )

  const amountToDisplay = shouldShowDecimals(amountTotal)
    ? amountTotalDecimal
    : amountTotal

  return (
    <Spacing
      alignItems={'end'}
      className={classNames(
        {
          [classes.animate]: animate
        },
        classes.totals
      )}
      variant={'inline'}
    >
      {priceDisplayInJourneys !== 'show_price' && (
        <Typography>
          <strong>
            {getDisplayPrice(
              productSelectionT,
              amountToDisplay,
              priceDisplayInJourneys,
              showTrailingDecimalZeros,
              item.unit_amount_currency
            )}
          </strong>
        </Typography>
      )}
      {priceDisplayInJourneys === 'show_price' && (
        <Spacing alignItems={'end'} variant={'stack'}>
          <div key={recurrenceDetails.billing_period || recurrenceDetails.type}>
            <PriceComponentsTotalsRecurrence
              priceDisplayInJourneys={priceDisplayInJourneys}
              quantity={quantity}
              recurrence={recurrenceDetails}
              shouldHideRecurrence={false}
              showTrailingDecimalZeros={showTrailingDecimalZeros}
              t={productSelectionT}
            />
          </div>
        </Spacing>
      )}
    </Spacing>
  )
}
