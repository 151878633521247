import { makeStyles, createStyles } from '@epilot/journey-elements'

export const useWrapperStyles = makeStyles((theme) =>
  createStyles({
    wrapperStyles: {
      overscrollBehavior: 'none',
      position: 'relative',
      padding: theme.spacing(4),
      paddingBottom: 0,
      maxWidth: 1256,
      margin: '0 auto',
      [theme.breakpoints.between('xs', 'md')]: {
        maxWidth: 936
      },
      width: '100%'
    }
  })
)
