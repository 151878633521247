import type {
  HistoryStack,
  SerializedBlockSummary,
  Source,
  InputCalculatorDevice
} from '@epilot/journey-logic-commons'
import {
  CONTROL_NAME,
  defaultHiddenSummaryTypes,
  EXCLUDED_TYPES,
  parseBlockSummary
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

import { getBlockDataRender } from './getBlockDataRender'
import type { OutputRenderType } from './types'

export function formatDeviceConsumptionStr(
  device: InputCalculatorDevice,
  unitLabel: string
): string {
  const name = device.otherName
    ? `${device.name} (${device.otherName})`
    : device.name

  return `${name}: ${device.quantity} x ${formatConsumptionToLocaleString(
    device.unitaryConsumption
  )} ${unitLabel} = ${formatConsumptionToLocaleString(
    device.consumption
  )} ${unitLabel}`
}

export const formatConsumptionToLocaleString = (
  num: number | string | undefined,
  decimalPlaces = 10
) => {
  const parsedNumber = Number(num)

  if (isNaN(parsedNumber)) return num

  return parsedNumber.toLocaleString(navigator.language || 'de-DE', {
    maximumFractionDigits: decimalPlaces
  })
}

export const formatUserSummaryData = ({
  renderOutput,
  journeySources,
  data,
  t,
  currentStepIndex,
  useNewDesign,
  stack,
  fields,
  isProductBlocksInSummaryFF = false
}: {
  renderOutput: OutputRenderType[]
  journeySources: Source[]
  data: Record<string, any>
  t: TFunction
  currentStepIndex: number
  useNewDesign?: boolean
  stack: HistoryStack[]
  fields: SerializedBlockSummary[]
  isProductBlocksInSummaryFF?: boolean
}) => {
  Object.keys(data)
    .sort(
      (a, b) =>
        journeySources?.findIndex(
          (src) =>
            // match step number and block name
            src.stepNumber === +a.split('/')[0] && src.name === a.split('/')[1]
        ) -
        journeySources?.findIndex(
          (src) =>
            // match step number and block name
            src.stepNumber === +b.split('/')[0] && src.name === b.split('/')[1]
        )
    )
    .forEach((key) => {
      let rawData = data[key]
      const types = getTypeFromKey(key)

      if (types && typeof rawData !== 'undefined' && rawData !== null) {
        const { blockType, stepNumber, blockName } = types

        const source = journeySources.find(
          (src) => src.stepNumber === stepNumber && src.name === blockName
        )

        // if the block is in an injected step assign -1 as index so it does not have an edit button
        const isBlockInInjectedStep = stack.find(
          (his) => his.stepIndex === stepNumber - 1
        )?.isInjected

        if (isBlockInInjectedStep && source) {
          source.stepIndex = -1
        }

        // finding if there is a config for displaying the block
        const fieldConfigStr = fields.find((ff) =>
          ff.includes(`${stepNumber - 1}/${blockName}/`)
        )
        const fieldConfig = fieldConfigStr && parseBlockSummary(fieldConfigStr)

        // remove the _isValid from being displayed in the summary
        if (!Array.isArray(rawData) && typeof rawData === 'object') {
          rawData = { ...rawData }
          delete rawData['_isValid']
        }

        if (
          types &&
          typeof rawData !== 'undefined' &&
          rawData !== null &&
          shouldDisplayBlockInSummary({
            blockType: blockType,
            isProductBlocksInSummaryFF,
            currentStepIndex,
            stepNumber: stepNumber,
            fieldConfig
          })
        ) {
          // render each block based on mapped and ordered Block Control data
          renderOutput.push({
            blockDisplayName: fieldConfig?.customDisplayname || blockName,
            blockRenderData: getBlockDataRender(
              rawData,
              t,
              source,
              useNewDesign
            ),
            source: {
              stepIndex: source?.stepIndex,
              name: source?.name,
              stepName: source?.stepName,
              controlNameId: source?.controlNameId
            }
          })
        }
      }
    })

  return renderOutput
}

// get Control Type, e.g PersonalInformationControl is type of '2/Contact Info/PersonalInformationControl'
function getTypeFromKey(key: string):
  | {
      blockType: CONTROL_NAME
      stepNumber: number
      blockName: string
    }
  | undefined {
  const split = key.split('/')

  if (split.length > 1)
    return {
      blockType: split[2] as CONTROL_NAME,
      stepNumber: Number(split[0]),
      blockName: split[1]
    }
  else return undefined
}

export const shouldDisplayBlockInSummary = ({
  blockType,
  isProductBlocksInSummaryFF,
  currentStepIndex,
  stepNumber,
  fieldConfig
}: {
  blockType: CONTROL_NAME
  isProductBlocksInSummaryFF: boolean
  currentStepIndex: number
  stepNumber: number
  fieldConfig?: { display?: boolean }
}) => {
  return (
    // do not display the block if it is in the same step as the summary block
    currentStepIndex + 1 !== stepNumber &&
    // do not display the block if it is display block or product selector
    (!EXCLUDED_TYPES.includes(blockType) ||
      (isProductBlocksInSummaryFF &&
        blockType === CONTROL_NAME.PRODUCT_SELECTION_CONTROL)) &&
    // do not display the block if it is configured to not be displayed
    fieldConfig?.display !== false &&
    // do not display the block if it is in the default hidden types
    (defaultHiddenSummaryTypes.includes(blockType)
      ? fieldConfig?.display === true
      : true)
  )
}
