import { AuthServiceError } from './AuthServiceError'

export class UserNotFoundError extends AuthServiceError {
  constructor() {
    super(
      'Could not find an account with the provided email address.',
      'USER_NOT_FOUND'
    )
    this.name = 'UserNotFoundError'
  }
}
