import {
  Icon,
  List,
  ListItem,
  ListItemAdornment,
  ListItemContent,
  Typography
} from '@epilot/concorde-elements'
import type { Feature, Product } from '@epilot/journey-logic-commons'
import classNames from 'classnames'
import React from 'react'

import classes from './ProductFeatures.module.scss'

type ProductFeatureProps = { feature: Feature & { showIcon: boolean } }
type ProductFeaturesProps = {
  product: Product
  showIcon: boolean
  featuresAmountLimit?: number
}

export const ProductFeatures = (props: ProductFeaturesProps) => {
  const { product, showIcon = true, featuresAmountLimit } = props

  const limitFeatures = featuresAmountLimit ?? product.feature?.length
  const features: Feature[] = product.feature?.slice(0, limitFeatures) ?? []
  const columns = features.length > 5 ? 2 : 1

  // Distribute features evenly across columns
  const columnFeatures: Feature[][] = Array.from({ length: columns }, () => [])

  features.forEach((feature, index) => {
    columnFeatures[index % columns].push(feature)
  })

  return (
    <div className={classes.featuresList}>
      {columnFeatures.map((column, columnIndex) => (
        <List className={classes.column} key={columnIndex}>
          {column.map((feature, index) => (
            <ProductFeature
              feature={{
                ...feature,
                showIcon
              }}
              key={index}
            />
          ))}
        </List>
      ))}
    </div>
  )
}

const ProductFeature = ({ feature }: ProductFeatureProps) => {
  return (
    <ListItem>
      <ListItemAdornment>
        {feature.showIcon && (
          <Icon
            className={classNames(
              'Concorde-ProductTile__Features--icon',
              classes.featureIcon
            )}
            color="var(--concorde-primary-color)"
            name="check"
            size="medium"
          />
        )}
      </ListItemAdornment>
      <ListItemContent>
        <Typography>{feature.feature}</Typography>
      </ListItemContent>
    </ListItem>
  )
}
