import { isEmpty } from 'radashi'

import { getLogicsSkipStep } from '../display/getLogicsSkipStep'
import type { Logic, Step } from '../utils'
import { blockController } from '../utils'

import { getLogicsNavigateFromStep } from './getLogicsNavigateFromStep'

/**
 * Checks if the steps contain any navigation logic, and any action bar contains a different target step than the logically next one.
 * If any of this is true, the journey is not linear anymore
 *
 * WARNING: Expensive operation, use it wisely and cache the end-result as much as you can
 *
 * TODO: Consider moving this logic to the server?
 */
export const isLinearJourney = (
  steps: Step[] = [],
  logics: Logic[] = [],
  { sourceControl = 'ActionBarControl' }: { sourceControl?: string } = {}
): boolean => {
  // Check if any step has rewired navigation buttons
  const hasRewiredNavigation = steps.some((step, i) => {
    // Skip check for the last step as it has no next step
    if (i >= steps.length - 1) return false

    const nextStepIdByIndex = steps[i + 1]?.stepId
    const actionButtons = blockController.findBlocks(
      { ...step, stepIndex: i },
      { type: sourceControl }
    )

    // Check if any button's target differs from the sequential next step
    return actionButtons.some((button) => {
      const targetId = button.uischema?.options?.ctaButton.targetStepId

      return targetId && targetId !== nextStepIdByIndex
    })
  })

  if (hasRewiredNavigation) {
    return false
  }

  // check if logics contain navigation logic
  const navigationLogics = getLogicsNavigateFromStep(steps, logics)
  const skipStepLogics = getLogicsSkipStep(steps, logics)

  return isEmptyLogics(navigationLogics) && isEmptyLogics(skipStepLogics)
}

const isEmptyLogics = <T>(array: Array<T> | Array<Array<T>>): boolean =>
  isEmpty(array) || (Array.isArray(array) && array.every(isEmpty))
