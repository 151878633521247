import {
  JOURNEY_EXIT_EVENT,
  JOURNEY_LOADING_EVENT
} from '@epilot/concorde-elements'
import type { EventDetailType } from '@epilot/journey-logic-commons'

export const dispatchLoadingEvent = (isLoading: boolean) => {
  const event = new CustomEvent<EventDetailType>(JOURNEY_LOADING_EVENT, {
    detail: {
      payload: {
        value: isLoading
      }
    }
  })

  document.dispatchEvent(event)
}

export const dispatchExitEvent = () => {
  const event = new CustomEvent<EventDetailType>(JOURNEY_EXIT_EVENT)

  document.dispatchEvent(event)
}
