export * from './staticLists'
export * from './logicRegEx'
export * from './logicConverters'
export * from './isConditionTrue'
export * from './toDashCase'
export * from './blocks'
export * from './base64'
export * from './logics'
export * from './steps'
export * from './clsx'
export * from './getFlowForJourney'
export * from './journeyStorage'
export * from './getParagraphBlockString'
export * from './parseBlockSummary'
export * from './sharedConstants'
export * from './convertions'
export * from './contextValues'
export * from './areProductDownloadsHydrated'
export * from './types'
export * from './getBlockNameByScope'
export * from './product'
export * from './image'
export * from './price'
export * from './getPublicUrl'
export * from './shouldShowDecimals'
export * from './date'
export * from './units'
export * from './googleRequests'
export * from './coercion'
export * from './flags'
export * from './getBlockClassname'

/**
 * Get the value of an object property by path
 * @param obj
 * @param path - path to the property, e.g. 'a.b.c'
 * @returns
 */
export const getObjectPropertyByPath = (
  obj: Record<string, unknown> | undefined,
  path: string
) => {
  if (!obj) {
    return obj
  }

  return path.split('.').reduce((o: any, i) => o[i], obj)
}

/**
 * Ensures truthyness of value, and narrows the type of the value passed
 */
export const isTruthy = <T>(
  value: T
): value is Exclude<T, undefined | null | false | '' | 0> => Boolean(value)
