import { CONTROL_NAME } from '../utils/blocks/types'

export const ALLOWED_JOURNEY_SOURCE_TYPES: string[] = [
  CONTROL_NAME.CONTROL, // BOOLEAN/BINARY, TEXT or SINGLE CHOICE
  CONTROL_NAME.DATE_PICKER,
  CONTROL_NAME.NUMBER_INPUT_CONTROL,
  CONTROL_NAME.PERSONAL_INFORMATION_CONTROL,
  CONTROL_NAME.CONTACT_CONTROL,
  CONTROL_NAME.ACCOUNT_CONTROL,
  CONTROL_NAME.ADDRESS_BLOCK,
  CONTROL_NAME.PAYMENT_CONTROL,
  CONTROL_NAME.MULTICHOICE_CONTROL,
  CONTROL_NAME.DIGITAL_SIGNATURE_CONTROL,
  CONTROL_NAME.UPLOAD_CONTROL,
  CONTROL_NAME.PREVIOUS_PROVIDER_CONTROL,
  CONTROL_NAME.PRODUCT_CATEGORY_CONTROL,
  CONTROL_NAME.INPUT_CALCULATOR_CONTROL,
  CONTROL_NAME.ENTITY_ATTRIBUTE_CONTROL,
  CONTROL_NAME.PV_ROOF_PLANNER_CONTROL,
  CONTROL_NAME.AUTH_CONTROL,
  CONTROL_NAME.APP_CONTROL
]

const messages = {
  de: {
    automatic_mapping:
      'Daten aus diesem Block werden automatisch der Sektion metadata auf Opportunity oder Bestellung zugeordnet.',
    'sources.salutation': 'Anrede',
    'sources.title': 'Titel',
    'sources.firstName': 'Vorname',
    'sources.lastName': 'Nachname',
    'sources.birthDate': 'Geburtsdatum',
    'sources.email': 'E-Mail',
    'sources.telephone': 'Telefonnummer',
    'sources.customerType': 'Kundentyp',
    'sources.registerNumber': 'Handelsregisternummer',
    'sources.registryCourt': 'Amtsgericht',
    'sources.city': 'Ort',
    'sources.companyName': 'Firmenname',
    'sources.zipCode': 'Postleitzahl',
    'sources.streetName': 'Straße',
    'sources.houseNumber': 'Hausnummer',
    'sources.addressExtention': 'Adresszusatz',
    'sources.countryCode': 'Ländercode',
    'sources.label': 'Label',
    'sources.dataFullname': 'Vollständiger Name',
    'sources.type': 'Typ',
    'sources.numberInput': 'Wert',
    'sources.numberUnit': 'Einheit',
    'sources.startDate': 'Startdatum',
    'sources.endDate': 'Enddatum',
    'sources.consents': 'Zustimmungen',
    'sources.accountHolder': 'Kontoinhaber',
    'sources.iban': 'IBAN'
  },
  en: {
    automatic_mapping:
      'Data from this block is automatically mapped to the metadata section on Opportunity or Order',
    'sources.salutation': 'Salutation',
    'sources.title': 'Academic Degree',
    'sources.lastName': 'Last name',
    'sources.birthDate': 'Birth date',
    'sources.telephone': 'Phone Number',
    'sources.email': 'Email',
    'sources.firstName': 'First name',
    'sources.customerType': 'Customer Type',
    'sources.registerNumber': 'Register Number',
    'sources.registryCourt': 'Registry Court',
    'sources.city': 'City',
    'sources.companyName': 'Company name',
    'sources.zipCode': 'Postal code',
    'sources.streetName': 'Street',
    'sources.houseNumber': 'House number',
    'sources.addressExtention': 'Addition to address',
    'sources.countryCode': 'Country Code',
    'sources.label': 'Label',
    'sources.dataFullname': 'Full name',
    'sources.type': 'Type',
    'sources.numberInput': 'Value',
    'sources.numberUnit': 'Unit',
    'sources.startDate': 'Start date',
    'sources.endDate': 'End date',
    'sources.consents': 'Consents',
    'sources.accountHolder': 'Account holder',
    'sources.iban': 'IBAN'
  }
}

export const setTranslation = (lng: 'de' | 'en') => {
  return {
    translate: (
      key: keyof (typeof messages)['en'] | keyof (typeof messages)['de']
    ) => messages[lng]?.[key] || ''
  }
}
