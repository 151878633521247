import { useMediaQuery } from '@epilot/concorde-elements'
import { useCallback, useState } from 'react'

type UseComponentMappingDataGraduatedProps = {
  unitAmount?: string
  formattedQuantity?: string
  amountTotal?: string
}

export const useComponentMappingDataGraduated = ({
  unitAmount,
  formattedQuantity,
  amountTotal
}: UseComponentMappingDataGraduatedProps) => {
  const [open, setOpen] = useState(false)
  const isMobileOrTablet = useMediaQuery('(max-width: 800px)')

  const handleClick = useCallback(() => {
    if (!unitAmount || !formattedQuantity || !amountTotal) {
      return
    }

    if (isMobileOrTablet) {
      setOpen(!open)
    }
  }, [unitAmount, formattedQuantity, amountTotal, isMobileOrTablet, open])

  const handleMouseEnter = useCallback(() => {
    if (!isMobileOrTablet) {
      setOpen(true)
    }
  }, [isMobileOrTablet])

  const handleMouseLeave = useCallback(() => {
    setOpen(false)
  }, [])

  return { open, setOpen, handleClick, handleMouseEnter, handleMouseLeave }
}
