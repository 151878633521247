import { DottedLine, Spacing } from '@epilot/concorde-elements'
import type { PaymentConditionItem } from '@epilot/journey-logic-commons'
import {
  PAYMENT_CONDITION_LABEL_TRANSLATION_DEFAULTS,
  PAYMENT_CONDITION_LABEL_TRANSLATION_KEYS
} from '@epilot/journey-logic-commons'
import { useTranslation } from 'react-i18next'

import classes from './ProductDetailsPaymentConditions.module.scss'

type ProductDetailsPaymentConditionsItemProps = {
  item: PaymentConditionItem
}

export const ProductDetailsPaymentConditionsItem = ({
  item: { type, amount, unit }
}: ProductDetailsPaymentConditionsItemProps) => {
  const { t } = useTranslation()
  const labelId = `product-details-payment-conditions-item-${type}`
  const descriptionId = `product-details-payment-conditions-item-description-${type}`

  return (
    <div
      aria-describedby={descriptionId}
      aria-labelledby={labelId}
      className={classes.paymentConditionItem}
    >
      <Spacing justifyContent="space-between" scale={1} variant="inline">
        <div id={labelId}>
          {t(
            PAYMENT_CONDITION_LABEL_TRANSLATION_KEYS[type],
            PAYMENT_CONDITION_LABEL_TRANSLATION_DEFAULTS[type]
          )}
        </div>
        <DottedLine />
        <div id={descriptionId}>
          {amount}{' '}
          {t(`product_details.billing_duration_unit.${unit}`, {
            count: amount
          })}
        </div>
      </Spacing>
    </div>
  )
}
