import { computePricingDetails } from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

import { usePromoCodeValidationState } from '../../concorde-renderers/renderers/ShopCartControl/components/ShoppingCartPromoCode/hooks/usePromoCodeValidationState'

export const useComputePricingDetails: typeof computePricingDetails = ({
  price,
  quantity = 1,
  overrides,
  externalCatalogData
}) => {
  const { redeemedPromos } = usePromoCodeValidationState()

  return useMemo(() => {
    return computePricingDetails({
      price,
      quantity,
      overrides,
      externalCatalogData,
      redeemedPromos
    })
  }, [price, quantity, overrides, externalCatalogData, redeemedPromos])
}
