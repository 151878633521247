import {
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE,
  journeyStorage,
  type EpilotECPTokenPayload,
  type EpilotTokenPayload
} from '@epilot/journey-logic-commons'
import { jwtDecode } from 'jwt-decode'
import { useMemo } from 'react'

import { debug } from '../../../../utils/debug'
import { parseBoolean } from '../../../../utils/parse'

let isConfigured = false

async function configure(existingToken: string) {
  if (!isConfigured && existingToken) {
    // "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_ASauSlhLe"
    // https://cognito-idp.<Region>.amazonaws.com/<your user pool ID>
    const claims = jwtDecode(existingToken)
    const iss = claims.iss
    const aud = claims.aud as string

    if (!iss || !aud) {
      return
    }
    // extracting the region and user pool id from the iss
    const userPoolId = iss.split('/')[3]

    // we must import it dynamically here as Amplify will crash the app if cookies are disabled in the browser settings
    const { Amplify } = await import('aws-amplify')

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: userPoolId,
          userPoolClientId: aud
        }
      }
    })

    isConfigured = true
  }
}

export function getLocalToken() {
  return journeyStorage.getItem('token')
}

export function usePortalCheck() {
  const token = getLocalToken()

  const isECPPortal = useMemo(() => {
    return isInParentAuthContext() || isEndCustomerPortalToken(token ?? '')
  }, [token])

  return { isECPPortal }
}

export function isEndCustomerPortalToken(token: string) {
  try {
    if (!token) {
      return false
    }
    // token could be from 360 portal or ECP
    const decoded = jwtDecode<EpilotECPTokenPayload | EpilotTokenPayload>(token)

    // if this is ECP token then it should have origin field with value END_CUSTOMER_PORTAL/INSTALLER_PORTAL/...
    return !!decoded['custom:origin']
  } catch {
    return false
  }
}

async function getSession() {
  try {
    if (!isConfigured) {
      await configure(getLocalToken() || '')
    }

    // we must import it dynamically here as Amplify will crash the app if cookies are disabled in the browser settings
    const { fetchAuthSession } = await import('aws-amplify/auth')

    return fetchAuthSession()
  } catch (originalError) {
    const error = new Error('Failed to get Auth session')

    error.cause = originalError

    // eslint-disable-next-line no-console
    console.error(error)

    return null
  }
}

export function hasTokenExpired(token: string) {
  if (!token) {
    return true
  }

  const decoded = jwtDecode<EpilotECPTokenPayload | EpilotTokenPayload>(token)

  const exp = decoded?.exp
  const now = Date.now() / 1000

  return exp ? exp < now : true
}

export async function getPortalToken() {
  if (isInParentAuthContext()) return getParentToken()

  const localToken = getLocalToken()

  if (localToken) {
    // if token is not expired then return it
    if (!hasTokenExpired(localToken)) {
      return localToken
    }

    return await getTokenFromSession()
  }

  return localToken
}

export function isInParentAuthContext() {
  return parseBoolean(
    new URLSearchParams(window.location.search).get('parentAuthContext')
  )
}

export function getParentToken() {
  debug('Journey > Parent: getToken')

  return new Promise<string>((resolve, reject) => {
    const messageListener = (event: MessageEvent) => {
      if (
        event.data.type ===
        EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.PARENT_TOKEN_RECEIVED
      ) {
        debug('Parent > Journey: token', event.data)

        cleanup()

        resolve(event.data.token)
      }
    }

    const TOKEN_TIMEOUT = 60 * 1000
    const timeout = setTimeout(() => {
      cleanup()

      reject(new Error('Timeout waiting for token'))
    }, TOKEN_TIMEOUT)

    function cleanup() {
      clearTimeout(timeout)
      window.removeEventListener('message', messageListener)
    }

    try {
      window.addEventListener('message', messageListener)

      window.parent.postMessage(
        { type: EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.GET_PARENT_TOKEN },
        '*'
      )
    } catch (e) {
      debug('Error posting message to parent:', e)

      cleanup()

      reject(e)
    }
  })
}

async function getTokenFromSession(): Promise<string | null> {
  try {
    const session = await getSession()

    const idtoken = session?.tokens?.idToken?.toString()

    if (idtoken) {
      setTokenLocally(idtoken)

      return idtoken
    }

    return null
  } catch (err) {
    return null
  }
}

function setTokenLocally(token: string | null): void {
  if (token) {
    journeyStorage.setItem('token', token)
  }
}

export function getEmailFromToken(token: string) {
  try {
    if (!token) return undefined

    // token could be from 360 portal or ECP
    const decoded = jwtDecode<EpilotECPTokenPayload | EpilotTokenPayload>(token)

    // return the email from the token
    return decoded?.['email']
  } catch {
    return undefined
  }
}
