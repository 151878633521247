import { IconButton } from '@epilot/concorde-elements'
import classNames from 'classnames'

import classes from './MobileStepper.module.scss'

interface MobileStepperProps {
  activeStep: number
  steps: number
  onBack: () => void
  showBackButton: boolean
}

export const MobileStepper = ({
  activeStep,
  steps,
  onBack,
  showBackButton
}: MobileStepperProps) => {
  return (
    <div className={classes.stepperContainer}>
      {showBackButton && (
        <IconButton
          aria-label="Back button"
          className={classes.backButton}
          color="primary"
          name="arrow_back"
          onClick={onBack}
        />
      )}

      <div className={classes.dotsContainer}>
        {Array.from({ length: steps }).map((_, index) => (
          <span
            className={classNames(classes.dot, {
              [classes.activeDot]: index <= activeStep
            })}
            key={index}
          />
        ))}
      </div>
    </div>
  )
}
