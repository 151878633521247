import type {
  Journey,
  Source,
  StepState,
  DisplayConditionsStatus,
  JourneyContextValue,
  HistoryStackState
} from '@epilot/journey-logic-commons'
import {
  blockController,
  CONTROL_NAME,
  EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE
} from '@epilot/journey-logic-commons'
import type { TFunction } from 'i18next'

import type {
  MeterReadingFormValues,
  MeterReadingControlDataMeters,
  DynamicMeterReadingFormValues
} from '../blocks-renderers'
import { dispatchUserEvent, EventNames } from '../blocks-renderers'
import { toCartItems } from '../services/pricing-service'
import type { OptInArray } from '../services/submission-service'
import {
  cleanDisplayDataFromJourney,
  convertToSubmission,
  submitJourney
} from '../services/submission-service'
import {
  generateJourneyDataSources,
  POSSIBLE_JOURNEY_ENTITIES
} from '../utils/generateJourneyDataSources'
import { generateOptIns } from '../utils/optInUtils'
import { trimObject } from '../utils/trimObject'

import {
  autoFillAddressesData,
  autoFillPIData,
  compactInputCalculatorData,
  getFileEntities,
  isFaultyEmail
} from './submissionUtils'

export class SubmissionError extends Error {
  errorKey: string

  constructor(errorKey: string) {
    super()
    this.errorKey = errorKey
  }
}

export const submissionListener = async (
  journey: Journey,
  originalJourneyState: StepState[],
  history: HistoryStackState,
  journeyContext: JourneyContextValue,
  displayConditionsStatus: DisplayConditionsStatus,
  t: TFunction,
  clearCurrentUserSession: () => void
) => {
  const {
    _contextEntitiesData: fetchedEntities = {},
    _contextParams: contextData,
    _shoppingCart: cartValue,
    omittedPriceComponentsPerStep,
    redeemedPromoCodes
  } = journeyContext

  const dataSources = generateJourneyDataSources(journey)

  // only use the states that belong to the Steps in History
  const historyIndexes = history.stack.map((h) => h.stepIndex)
  const journeyState = originalJourneyState.map((os, index) =>
    historyIndexes.includes(index) ? os : {}
  )

  let journeyStateData: StepState[] = { ...journeyState }

  try {
    // sync identical addresses data if applicable
    journeyStateData = autoFillAddressesData(
      journey.steps,
      displayConditionsStatus,
      journeyStateData
    )

    // sync identical pi data if applicable
    journeyStateData = autoFillPIData(
      journey.steps,
      displayConditionsStatus,
      journeyStateData
    )

    // compact data for the input calculator
    journeyStateData = compactInputCalculatorData(
      journey.steps,
      journeyStateData,
      t
    )
    // dealing with files
    const fileEntities = getFileEntities(
      dataSources,
      journeyState,
      journey.settings?.filePurposes,
      Boolean(journey.featureFlags?.['journey-file-upload-fix'])
    )

    // deal with opt ins
    const loggedInContact = fetchedEntities._logged_in_contact

    const mixedConsents = generateOptIns(
      dataSources,
      journeyState,
      loggedInContact
    )
    const optIns: OptInArray | undefined = mixedConsents
      ? /**
         * @todo OptInArray is too literal, should be simplified to OptIn[]
         */
        (mixedConsents.optins as unknown as OptInArray | undefined)
      : undefined
    const consents = mixedConsents ? mixedConsents.consents : undefined

    // trim all empty spaces from the string values
    const trimmedData = journeyState.map((step) => trimObject(step))

    // clean data before submission
    const stepsState = cleanDisplayDataFromJourney(journey.steps, trimmedData)

    const sourceOrder = dataSources[POSSIBLE_JOURNEY_ENTITIES.ORDER]

    const contactAddressValue =
      sourceOrder?.contactAddressLocation &&
      typeof sourceOrder.contactAddressLocation !== 'string'
        ? journeyStateData[sourceOrder.contactAddressLocation.stepIndex][
            sourceOrder.contactAddressLocation.key
          ]
        : undefined

    const billingAddressValue =
      sourceOrder?.billingAddressLocation &&
      typeof sourceOrder?.billingAddressLocation !== 'string'
        ? journeyStateData[sourceOrder.billingAddressLocation.stepIndex][
            sourceOrder.billingAddressLocation.key
          ]
        : undefined

    const additionalAddressesValue = sourceOrder?.additionalAddressesLocation
      ? sourceOrder.additionalAddressesLocation.map((location: Source) => {
          return typeof location === 'string'
            ? journeyStateData[location]
            : journeyStateData[location.stepIndex][location.key]
        })
      : undefined

    const paymentValue =
      sourceOrder?.paymentLocation &&
      typeof sourceOrder.paymentLocation !== 'string'
        ? journeyStateData[sourceOrder.paymentLocation.stepIndex][
            sourceOrder.paymentLocation.key
          ]
        : undefined

    const cartItems = toCartItems(cartValue, omittedPriceComponentsPerStep)

    const staticMeterReadingControls = blockController.findBlocks(
      journey.steps,
      { type: CONTROL_NAME.METER_READING_CONTROL }
    )
    const dynamicMeterReadingControls = blockController.findBlocks(
      journey.steps,
      { type: CONTROL_NAME.METER_READING_DYNAMIC_CONTROL }
    )

    const meterReadingControls = [
      ...staticMeterReadingControls,
      ...dynamicMeterReadingControls
    ]

    let meterReadings:
      | MeterReadingFormValues[]
      | DynamicMeterReadingFormValues[] = []

    meterReadingControls.forEach((meterReadingControl) => {
      const blockData =
        stepsState[meterReadingControl.stepIndex]?.[meterReadingControl.name]

      const meters = (blockData as MeterReadingControlDataMeters)?.meters

      if (meters) {
        meterReadings = [...meterReadings, ...meters]
      } else {
        meterReadings.push(blockData as never)
      }
    })

    // submit the journey
    const submission = convertToSubmission({
      journey,
      stepsState,
      fileEntities,
      cartItems,
      billingAddressValue,
      contactAddressValue,
      additionalAddressesValue,
      paymentValue,
      optIns,
      consents,
      contextData,
      meterReadings,
      displayConditionsStatus,
      historyIndexes,
      fetchedEntities,
      redeemedPromoCodes
    })

    if (isFaultyEmail(journey.steps, originalJourneyState, historyIndexes)) {
      console.warn(
        'Empty email for required field detected. Current state-values are:',
        {
          currentValues: originalJourneyState,
          submitValues: journeyState,
          cleanedValues: stepsState,
          stepsInHistory: historyIndexes
        }
      )
    }

    const res = await submitJourney(submission, journey)

    if (res?.errorKey) {
      throw new SubmissionError(res.errorKey)
    }

    clearCurrentUserSession()

    dispatchUserEvent(
      EMBEDDED_JOURNEY_MESSAGE_EVENT_TYPE.USER_EVENT_PROGRESS,
      journey.journeyId,
      {
        journeyName: journey.name,
        eventName: EventNames.JOURNEY_SUBMIT,
        eventData: submission
      }
    )
  } catch (err) {
    console.error('submissionListener failure -->', err)

    throw err instanceof Error ? err : new Error(JSON.stringify(err))
  }
}
