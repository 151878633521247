import type { Journey } from '@epilot/journey-logic-commons'

/**
 * Retrieves the query parameters that are defined in the context schema
 * @param queryParams passed from the consuming website
 * @param contextValues defined in the journey config
 */
export const getParamsByContextValues = (
  queryParams: Record<string, string>,
  contextValues: Journey['contextSchema']
) =>
  contextValues
    ? Object.fromEntries(
        Object.entries(queryParams).filter(([key]) =>
          contextValues.some(({ paramKey }) => key === paramKey)
        )
      )
    : {}
