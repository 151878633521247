import {
  CircularProgress,
  Icon,
  IconButton,
  Tooltip
} from '@epilot/concorde-elements'
import { JOURNEY_SAVING_PROGRESS_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './UserSessionIcon.module.scss'

export type UserSessionIconProps = {
  iconColor: string
  stepIndex?: number
  mode?:
    | JOURNEY_SAVING_PROGRESS_MODE.LOCAL
    | JOURNEY_SAVING_PROGRESS_MODE.REMOTE
}

export function UserSessionIcon({
  iconColor,
  stepIndex,
  mode
}: UserSessionIconProps) {
  const { t } = useTranslation()
  const tooltipLabel =
    mode === JOURNEY_SAVING_PROGRESS_MODE.LOCAL
      ? t('user_progress.topbar_tooltip_local')
      : t('user_progress.topbar_tooltip_remote')
  const [showProgress, setShowProgress] = useState(false)

  useEffect(() => {
    // every time the stepIndex changes, setShowProgress as true for 500ms

    setShowProgress(true)
    const timeout = setTimeout(() => {
      setShowProgress(false)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [stepIndex])

  return (
    <Tooltip className={classes.tooltip} title={tooltipLabel}>
      <IconButton
        aria-label="User Session Button"
        label={
          showProgress ? (
            <CircularProgress color={iconColor} size={12} />
          ) : (
            <Icon color={iconColor} name="cloud" />
          )
        }
      />
    </Tooltip>
  )
}
