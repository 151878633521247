/* eslint-disable @typescript-eslint/no-empty-function */
import type { DesignTokens } from '@epilot/journey-logic-commons'
import type { ComponentProps } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'

type DesignBuilderContextValue = {
  theme: string
  setTheme: (theme: string) => void
  logo: string
  setLogo: (logo: string) => void
  designTokens?: DesignTokens
  setDesignTokens: (designTokens?: DesignTokens) => void
}

const defaultContext: DesignBuilderContextValue = {
  theme: '',
  setTheme: () => {},
  logo: '',
  setLogo: () => {},
  designTokens: undefined,
  setDesignTokens: () => {}
}

const DesignBuilderContext = createContext<DesignBuilderContextValue | null>(
  null
)

export const DesignBuilderContextProvider = (
  props: Omit<ComponentProps<typeof DesignBuilderContext.Provider>, 'value'>
) => {
  const [theme, setTheme] = useState('')
  const [logo, setLogo] = useState('')
  const [designTokens, setDesignTokens] = useState<DesignTokens | undefined>(
    undefined
  )

  const value = useMemo(
    () => ({ theme, setTheme, logo, setLogo, designTokens, setDesignTokens }),
    [theme, setTheme, logo, setLogo, designTokens, setDesignTokens]
  )

  return <DesignBuilderContext.Provider value={value} {...props} />
}

export const useDesignBuilderContext = () => {
  const context = useContext(DesignBuilderContext)

  if (!context) {
    console.error(new Error('DesignBuilderContext not found'))

    return defaultContext
  }

  return context
}
