import { Icon, PopoverTrigger, Typography } from '@epilot/concorde-elements'
import { type Price } from '@epilot/journey-logic-commons'

import classes from './ProductTileMappingDataTriggerButton.module.scss'

type ProductTileMappingDataTriggerButtonProps = {
  className?: string
  handleClick: () => void
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  formattedQuantity?: string
  displayUnitaryAverage?: boolean
  formattedAverage?: string
  showPriceDescription?: boolean
  price: Price
  displayInOneLine?: boolean
}

const ProductTitleMappingDataTriggerButton = ({
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  displayUnitaryAverage,
  formattedAverage,
  showPriceDescription,
  price
}: ProductTileMappingDataTriggerButtonProps) => {
  return (
    <span className={classes.productMappingDataTriggerButton}>
      <Typography
        className={classes.text}
        data-testid="product-mapping-data-graduated-description"
        title={price.description}
        variant="secondary"
      >
        {showPriceDescription ? `${price.description}` : ''}
      </Typography>
      <PopoverTrigger
        asChild
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography className={classes.price}>
          <div
            className={classes.button}
            data-testid="product-mapping-data-graduated-average-container"
          >
            <span>{displayUnitaryAverage && `Ø ${formattedAverage}`}</span>
            <Icon
              className={classes.icon}
              data-testid="tooltip"
              name={'info'}
            />
          </div>
        </Typography>
      </PopoverTrigger>
    </span>
  )
}

ProductTitleMappingDataTriggerButton.displayName =
  'ProductTitleMappingDataTriggerButton'

export { ProductTitleMappingDataTriggerButton }
