import { FeatureFlagProvider as ConcordeFeatureFlagProvider } from '@epilot/concorde-elements'
import {
  initServices as initLogicCommonsServices,
  isLauncherJourney,
  JourneyFeatureFlags as FeatureFlags,
  extractLinkedJourneys,
  extractLauncherUiSchemaElements
} from '@epilot/journey-logic-commons'
import type {
  Journey,
  JourneyRenderFlags,
  JourneySession
} from '@epilot/journey-logic-commons'
import { JOURNEY_EMBED_MODE } from '@epilot/journey-logic-commons/src/types/next'
import { useEffect, useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import { setJourneyToken } from './analytics/service'
import {
  AuthenticatedUserProvider,
  ConfigProvider,
  FeatureFlagProvider,
  getPortalToken,
  JourneyContextProvider,
  useHistoryStack,
  useUserProgress
} from './blocks-renderers'
import { ExpiredJourneyTokenError } from './components/ExpiredJourneyTokenError'
import { JourneyAlreadySubmittedError } from './components/JourneyAlreadySubmittedError'
import { useJourneyLauncherContext } from './components/JourneyLauncher/JourneyLauncherContextProvider'
import { JourneyLauncherTabs } from './components/JourneyLauncher/JourneyLauncherTabs'
import { JourneyLauncherTabsThemeProvider } from './components/JourneyLauncher/JourneyLauncherTabsThemeProvider'
import { JourneyNotFoundError } from './components/JourneyNotFoundError'
import { JourneyPage } from './components/JourneyPage'
import { publishCloseJourneyMessage } from './components/JourneyPage/embedJourneyPublishers'
import { dispatchExitEvent } from './components/JourneyPage/eventsDispatchers'
import { SessionSelectorDialog } from './components/SessionSelectorDialog'
import { SpinnerPage } from './components/SpinnerPage'
import { useAnalytics } from './context/AnalyticsContext'
import { useGetJourney } from './hooks/useGetJourney'
import { useMessageHandler } from './hooks/useMessageHandler'
import { usePrefetchCatalogQueries } from './hooks/usePrefetchCatalogQueries'
import { useTracking } from './hooks/useTracking'
import { instance18n, loadRemoteNamespace } from './locales/i18n'
import { getDatadogClient } from './services/datadog'
import { MAJOR_APP_ERROR } from './types'
import { env } from './utils/config'
import { getInitialHistoryStack } from './utils/getInitialHistoryStack'
import { getParamsByContextValues } from './utils/getParamsByContextValues'
import { parseUuidOrValueParams } from './utils/parse'
import { scrollToThePageTop } from './utils/scrollToTop'
import { TRACE_KEYS, getTraceId } from './utils/trace'

const rendererConfig = {
  STAGE: env('REACT_APP_STAGE'),
  GOOGLE_MAPS_API_URL: env('REACT_APP_GOOGLE_MAPS_API_URL'),
  PRICING_API_URL: env('REACT_APP_PRICING_API_URL'),
  FILE_API_URL: env('REACT_APP_FILE_API_URL'),
  IMAGE_PREVIEW_API_URL: env('REACT_APP_IMAGE_PREVIEW_API_URL'),
  ADDRESS_API_URL: env('REACT_APP_ADDRESS_API_URL'),
  ADDRESS_SUGGESTIONS_API_URL: env('REACT_APP_ADDRESS_SUGGESTIONS_API_URL'),
  PREVIOUS_PROVIDER_URL: env('REACT_APP_PREVIOUS_PROVIDER_URL'),
  ENTITY_API_URL: env('REACT_APP_ENTITY_API_URL'),
  CUSTOMER_PORTAL_API_URL: env('REACT_APP_CUSTOMER_PORTAL_API_URL'),
  METERING_API_URL: env('REACT_APP_METERING_API_URL'),
  JOURNEYS_API_BASE_URL: env('REACT_APP_JOURNEYS_API_BASE_URL'),
  JOURNEYS_API_URL: env('REACT_APP_JOURNEYS_API_URL')
}

const sessionIdGetter = () => getTraceId(TRACE_KEYS.JOURNEY_SESSION_ID)

export const App = ({
  isFocusedOnJourney = true,
  journeyIdOverride,
  parentJourney = null
}: {
  journeyIdOverride?: string
  isFocusedOnJourney?: boolean
  parentJourney?: Journey | null
}) => {
  const {
    journey,
    error,
    stepIndex: jbStepIndex,
    debug,
    isLoading,
    initialState,
    preview,
    isLinearJourney,
    journeyId,
    contextData: previewContextData,
    topBarParam,
    modeParam,
    setJourney,
    journeyLanguage: languageParam,
    isEmbedded,
    dataInjectionOptionsParam,
    isNonceUsed
  } = useGetJourney({ journeyIdOverride })

  // Allow tracking only if third-party cookies are enabled, otherwise not
  useTracking(journey?.settings?.thirdPartyCookies !== false)

  const {
    mode: modeMessage,
    topBar,
    scrollToTop,
    closeButton,
    lang,
    contextData,
    queryParams,
    dataInjectionOptions,
    isFullScreenEntered,
    initialMessageEventReceived
  } = useMessageHandler(journeyId || undefined) || {}

  const contextParameter = parseUuidOrValueParams({
    ...getParamsByContextValues(queryParams || {}, journey?.contextSchema),
    ...(contextData || previewContextData)
  })

  const { currentSessionId, setCurrentSessionId, getExistingSessions } =
    useUserProgress({
      journey,
      paramContext: contextParameter
    })

  const { initializeAnalytics } = useAnalytics()

  useEffect(() => {
    initLogicCommonsServices({
      pricingServiceUrl: env('REACT_APP_JOURNEYS_PRICING_API'),
      addressSuggestionsServiceUrl: env('REACT_APP_ADDRESS_SUGGESTIONS_API'),
      orgId: journey?.organizationId || '',
      publicToken: journey?.settings?.publicToken || ''
    })
  }, [journey?.organizationId, journey?.settings?.publicToken])

  const mode = modeMessage || modeParam

  const {
    initialState: injectionInitialState,
    initialStepIndex: injectionStepIndex,
    blocksDisplaySettings
  } = dataInjectionOptionsParam || dataInjectionOptions || {}

  const initialStepIndex = injectionStepIndex || jbStepIndex

  const [journeyInitialState, setJourneyInitialState] = useState(
    /**
     * This clone is to avoid issues where the initial state gets mutated later in the journey
     * which will cause the journey to remount due to forceRemountAfterInjection used as react key in JourneyContextProvider.
     * TODO: Let's clarify if this remount forcing is really needed or not.
     */
    structuredClone(injectionInitialState || initialState)
  )

  const [sessionStartTime, setSessionStartTime] = useState<Date | null>(null)

  const isAnalyticsEnabled =
    journey?.featureFlags?.[FeatureFlags.JOURNEY_DATE_REWORK]

  const isUserProgressEnabled = Boolean(
    journey?.featureFlags?.[FeatureFlags.USER_PROGRESS]
  )

  useEffect(() => {
    setSessionStartTime(new Date())
  }, [])

  useEffect(() => {
    try {
      if (journey?.journeyId && !journeyFlags?.isPreview) {
        getPortalToken().then((privateToken) => {
          if (isAnalyticsEnabled) {
            const publicToken = journey?.settings?.publicToken || ''
            const token = privateToken || publicToken
            const embeddedIn = document?.referrer || ''

            setJourneyToken(token)
            initializeAnalytics({
              embeddedIn,
              isLauncherJourney: isLauncherJourney(journey?.steps),
              journeyId: journey?.journeyId,
              journeyName: journey?.name,
              journeyLoadTime: sessionStartTime
                ? (new Date().getTime() - sessionStartTime.getTime()) / 1000
                : 0,
              landingStepName: journey?.steps?.[0]?.name
            })
          }
        })
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error initializing analytics', err)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journey?.journeyId])

  const history = useHistoryStack({
    initialValues: {
      stepName: journey?.steps?.[initialStepIndex]?.name || '',
      stepIndex: initialStepIndex || 0
    },
    scrollToThePageTop,
    journey,
    // when journey is using injection, it should not allow navigating back to a skip step
    initialHistoryStack: getInitialHistoryStack(
      journey?.steps,
      injectionStepIndex
    ),
    options: {
      scrollIntoView:
        typeof scrollToTop === 'undefined' || scrollToTop === true,
      forceInitialize: preview,
      isTrackingDisabled:
        mode === JOURNEY_EMBED_MODE.FULL_SCREEN && !isFullScreenEntered,
      isUserProgressEnabled: isUserProgressEnabled
    },
    datadogRum: getDatadogClient()
  })

  const [existingSessions, setExistingSessions] = useState<JourneySession[]>([])

  const onRestoreSession = (sessionId: string) => {
    setCurrentSessionId(sessionId, rendererConfig.JOURNEYS_API_BASE_URL)
    const session = existingSessions.find((s) => s.id === sessionId)

    if (session && journey) {
      setJourneyInitialState(session.stepsState)
      history.initSession(session, journey.steps)
    }
  }

  useEffect(() => {
    if (
      isUserProgressEnabled &&
      journey?.journeyId &&
      !preview &&
      !currentSessionId
    ) {
      getExistingSessions(rendererConfig.JOURNEYS_API_BASE_URL).then(
        (sessions) => {
          if (
            Array.isArray(sessions) &&
            sessions.length > 0 &&
            initialStepIndex === 0
          ) {
            setExistingSessions(sessions)
          } else {
            setCurrentSessionId(uuidV4(), rendererConfig.JOURNEYS_API_BASE_URL)
            setExistingSessions([])
          }
        }
      )
    }
  }, [
    getExistingSessions,
    preview,
    currentSessionId,
    initialStepIndex,
    journey,
    isUserProgressEnabled,
    setCurrentSessionId
  ])

  usePrefetchCatalogQueries({
    journey,
    isPreview: preview,
    pricingApiUrl: rendererConfig.PRICING_API_URL,
    enabled: Boolean(
      journey?.featureFlags?.[FeatureFlags.PREFETCH_INTERNAL_PRODUCTS]
    )
  })

  const journeyFlags: JourneyRenderFlags = {
    debug,
    isLinear: isLinearJourney,
    isPreview: preview,
    showCloseButton: closeButton,
    showTopBar:
      typeof topBar === 'boolean'
        ? topBar
        : typeof topBarParam === 'boolean'
          ? topBarParam
          : true,
    mode: mode,
    scrollToTop: scrollToTop || false,
    activeJourneyEnabled: !!journey?.featureFlags?.[FeatureFlags.ACTIVE_JOURNEY]
  }

  useEffect(() => {
    instance18n.changeLanguage(lang || languageParam)

    loadRemoteNamespace(lang || languageParam)
  }, [languageParam, lang])

  const contextValues = {
    ...contextParameter,
    embedded_in: document.referrer,
    journey_url_used: document.location.href
  }

  const forceRemountAfterInjection =
    injectionInitialState || currentSessionId
      ? JSON.stringify(injectionInitialState)
      : 'normalcontext'

  // not in preview mode + we finished loading + journey was not found
  const isJourneyNotFound = !journeyFlags.isPreview && !isLoading && !journey

  /* New simplified journey launcher logic, not relevant unless FeatureFlags.SIMPLIFIED_JOURNEY_LAUNCHER is enabled */
  const { selectedJourneyId, setSelectedJourneyId } =
    useJourneyLauncherContext()

  const { isLauncher, linkedJourneys } = useMemo(() => {
    const isLauncher = journey && isLauncherJourney(journey.steps)

    const linkedJourneys = journey && extractLinkedJourneys(journey.steps)

    return { isLauncher, linkedJourneys }
  }, [journey])

  useEffect(() => {
    if (
      !selectedJourneyId &&
      isLauncher &&
      linkedJourneys &&
      linkedJourneys[0]
    ) {
      setSelectedJourneyId((current) => current ?? linkedJourneys[0].id)
    }
  }, [
    isLauncher,
    journey,
    linkedJourneys,
    selectedJourneyId,
    setSelectedJourneyId
  ])

  if (isJourneyNotFound || isNonceUsed) {
    let ErrorComponent = JourneyNotFoundError

    if (error === MAJOR_APP_ERROR.EXPIRED_JOURNEY_TOKEN) {
      ErrorComponent = ExpiredJourneyTokenError
    } else if (isNonceUsed) {
      ErrorComponent = JourneyAlreadySubmittedError
    }

    return (
      <I18nextProvider i18n={instance18n}>
        <ErrorComponent
          embedMode={mode}
          isEmbedded={isEmbedded}
          onClose={() => {
            dispatchExitEvent()
            publishCloseJourneyMessage({
              journeyId: journeyId ?? undefined,
              isDirty: false
            })
          }}
        />
      </I18nextProvider>
    )
  }

  const showSessionSelectorDialog =
    !currentSessionId &&
    !preview &&
    existingSessions.length > 0 &&
    history.currentIndex === 0

  // show spinner while loading or while initializing in preview mode
  if (isLoading || !journey) return <SpinnerPage />

  const concordeJourneyLauncherTabsFeatureFlagEnabled = Boolean(
    journey.featureFlags?.[FeatureFlags.CONCORDE_JOURNEY_LAUNCHER]
  )

  if (isLauncher && linkedJourneys && selectedJourneyId) {
    const _isFocusedOnJourney = !!history.currentIndex

    const useNewDesign = Boolean(journey.settings?.useNewDesign)

    const shouldUseConcordeJourneyLauncherTabs =
      concordeJourneyLauncherTabsFeatureFlagEnabled && useNewDesign

    const launcherUiSchemaElements = extractLauncherUiSchemaElements(
      journey.steps
    )

    const uiSchema = launcherUiSchemaElements.at(0)

    if (!uiSchema) {
      throw new Error('No UI schema found for launcher journey')
    }

    const showPaper = Boolean(uiSchema.options?.showPaper)

    if (journey.journeyId === selectedJourneyId) {
      throw new Error(
        'Rendering launcher journey within itself, should not occur'
      )
    }

    if (journeyIdOverride) {
      /**
       * This race condition is occurring because of messages for journey being emitted
       * Don't render anything until the child journey is loaded
       */
      // throw new Error('Journey ID override should not be used in launcher')
      return null
    }

    return (
      <FeatureFlagProvider featureFlags={journey.featureFlags}>
        <ConcordeFeatureFlagProvider featureFlags={journey.featureFlags}>
          <ConfigProvider config={rendererConfig}>
            <JourneyLauncherTabsThemeProvider
              isFocusedOnJourney={_isFocusedOnJourney}
              journey={journey}
              shouldUseConcordeJourneyLauncherTabs={
                shouldUseConcordeJourneyLauncherTabs
              }
            >
              <JourneyLauncherTabs
                isFocusedOnJourney={_isFocusedOnJourney}
                linkedJourneys={linkedJourneys}
                selectedJourneyId={selectedJourneyId}
                setSelectedJourneyId={setSelectedJourneyId}
                shouldUseConcordeJourneyLauncherTabs={
                  shouldUseConcordeJourneyLauncherTabs
                }
                showPaper={showPaper}
              >
                <App
                  isFocusedOnJourney={_isFocusedOnJourney}
                  journeyIdOverride={selectedJourneyId}
                  parentJourney={journey}
                />
              </JourneyLauncherTabs>
            </JourneyLauncherTabsThemeProvider>
          </ConfigProvider>
        </ConcordeFeatureFlagProvider>
      </FeatureFlagProvider>
    )
  }

  return (
    <FeatureFlagProvider featureFlags={journey.featureFlags}>
      <ConcordeFeatureFlagProvider featureFlags={journey.featureFlags}>
        <ConfigProvider config={rendererConfig}>
          <JourneyContextProvider
            blocksDisplaySettings={blocksDisplaySettings}
            contextValues={contextValues}
            history={history}
            initialState={journeyInitialState}
            isPreview={preview}
            journey={journey}
            key={forceRemountAfterInjection}
            sessionIdGetter={sessionIdGetter}
            shouldCheckContextValues={
              isEmbedded ? !!initialMessageEventReceived : true
            }
          >
            <AuthenticatedUserProvider>
              <JourneyPage
                flags={journeyFlags}
                history={history}
                initialStepValues={initialState}
                isFocusedOnJourney={isFocusedOnJourney}
                journey={journey}
                key={journey.journeyId}
                launcherJourney={parentJourney}
                onJourneyChange={setJourney}
              />
              {isUserProgressEnabled && (
                <SessionSelectorDialog
                  availableSessions={existingSessions}
                  onDiscard={() => {
                    setCurrentSessionId(
                      uuidV4(),
                      rendererConfig.JOURNEYS_API_BASE_URL
                    )
                    setExistingSessions([])
                  }}
                  onSessionSelected={onRestoreSession}
                  open={showSessionSelectorDialog}
                  useNewDesign={journey.settings?.useNewDesign}
                />
              )}
            </AuthenticatedUserProvider>
          </JourneyContextProvider>
        </ConfigProvider>
      </ConcordeFeatureFlagProvider>
    </FeatureFlagProvider>
  )
}

App.displayName = 'App'
