import type { EpilotTheme, Theme } from '@epilot/journey-elements'
import {
  createStyles,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from '@epilot/journey-elements'
import {
  getPriceType,
  getPricingDetailsFormatted,
  isCompositePrice,
  getFormattedQuantity,
  getTaxIndexes
} from '@epilot/journey-logic-commons'
import type {
  BillingPeriod,
  Price,
  PriceItem,
  TaxWithTaxInclusivityBehaviour
} from '@epilot/journey-logic-commons'
import { computeQuantities } from '@epilot/pricing'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DottedLine } from '../Product/DottedLine'

import { CartItemDetailsLineDiscount } from './CartItemDetailsLineDiscount'
import { ComponentMappingData } from './ComponentMappingData/ComponentMappingData'

const useStyles = makeStyles<Theme & EpilotTheme>((theme) =>
  createStyles({
    listItem: {
      padding: '8px 12px',
      borderRadius:
        theme.shape?.borderRadius !== undefined
          ? `min(${theme.shape.borderRadius}px, 20px)`
          : '8px',
      backgroundColor:
        'var(--pd-price-item-background, var(--concorde-default-background))'
    },
    superscript: {
      lineHeight: 0
    },
    item: {
      gap: theme.spacing(2),
      // Default: nowrap
      flexWrap: 'nowrap',
      // Apply wrap for small screen sizes
      [theme.breakpoints.down(540)]: {
        flexWrap: 'wrap'
      }
    },
    detail: {
      gap: theme.spacing(1),
      flex: '1 1 auto !important',
      width: 'initial'
    },
    discountRecurrence: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: theme.spacing(1)
    }
  })
)

type PriceComponentInfoProps = {
  component: PriceItem
  displayUnitaryAverage?: boolean
  taxes?: TaxWithTaxInclusivityBehaviour[]
  showTrailingDecimalZeros?: boolean
  shouldHideRecurrence?: boolean
}

type PriceAdditionalDetail = {
  label: string
  value: string
}

export const PriceComponentInfo = ({
  component,
  displayUnitaryAverage,
  taxes = [],
  showTrailingDecimalZeros,
  shouldHideRecurrence
}: PriceComponentInfoProps) => {
  const { t: productSelectionT } = useTranslation(undefined, {
    keyPrefix: 'product.selection',
    useSuspense: true
  })
  const classes = useStyles()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const taxIndexes = useMemo<number[]>(
    () =>
      getTaxIndexes(component.taxes, component._price?.is_tax_inclusive, taxes),
    [taxes, component.taxes, component._price?.is_tax_inclusive]
  )

  const type = getPriceType(
    component._price?.type as Price['type'],
    component._price?.billing_period as BillingPeriod
  )

  if (component._price && isCompositePrice(component._price)) {
    return <></>
  }

  const priceAdditionalDetails = component._price?.additional_details ?? []

  const { quantityToSelectTier } = computeQuantities(
    component._price,
    component.quantity || 1,
    {
      frequency_unit: component._price?.blockMappingData?.frequencyUnit,
      value: component._price?.blockMappingData?.numberInput
    }
  )

  const { unitAmount, unitAmountWithUnit, amountTotal } =
    getPricingDetailsFormatted(
      component,
      1,
      quantityToSelectTier,
      productSelectionT,
      Boolean(showTrailingDecimalZeros)
    )

  const formattedQuantity = getFormattedQuantity(
    component._price as Price,
    productSelectionT
  )

  const displayDiscount =
    component._price?.price_display_in_journeys !== 'show_as_on_request' &&
    component.discount_amount !== undefined

  const wrapOnSmallScreen =
    isSmallScreen &&
    component._price?.price_display_in_journeys === 'estimated_price'

  return (
    <li className={classes.listItem} key={component.price_id}>
      <Grid
        alignItems="center"
        className={classes.item}
        container
        justifyContent="space-between"
        wrap={wrapOnSmallScreen ? 'wrap' : 'nowrap'}
      >
        <Grid className={classes.detail} container item wrap="wrap">
          <Grid item>
            <Typography color="textSecondary" variant="body2">
              <strong>{component.description}</strong>{' '}
              {!!taxIndexes?.length && (
                <sup className={classes.superscript}>
                  {taxIndexes.join(', ')}
                </sup>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              data-testid="cart-item-line-mapping-data"
              noWrap
              variant="caption"
            >
              <ComponentMappingData
                amountTotal={amountTotal}
                component={component}
                displayUnitaryAverage={displayUnitaryAverage}
                formattedQuantity={formattedQuantity || ''}
                itemQuantity={component.quantity}
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                t={productSelectionT}
                unitAmount={unitAmount}
                unitAmountWithUnit={unitAmountWithUnit}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.detail}
          container
          item
          justifyContent="flex-end"
          wrap="wrap-reverse"
        >
          <Grid item>
            <div className={classes.discountRecurrence}>
              {!shouldHideRecurrence && (
                <Typography color="textSecondary" noWrap variant="caption">
                  {productSelectionT(type)}
                </Typography>
              )}
              <Typography
                align="right"
                color="textSecondary"
                data-testid="cart-item-line-price"
                noWrap
                variant="body2"
              >
                <strong>{amountTotal}</strong>
              </Typography>
            </div>
            {displayDiscount && (
              <CartItemDetailsLineDiscount
                item={component}
                showTrailingDecimalZeros={showTrailingDecimalZeros}
                t={productSelectionT}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {priceAdditionalDetails?.length > 0 &&
        priceAdditionalDetails.map(
          (additionalDetail: PriceAdditionalDetail, index: number) => (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              key={`detail-${index}`}
            >
              <Grid item xs="auto">
                {additionalDetail?.label}
              </Grid>
              <DottedLine />
              <Grid item>{additionalDetail?.value}</Grid>
            </Grid>
          )
        )}
    </li>
  )
}
