import {
  getOptimizedImageURL,
  areProductImagesHydrated,
  getPublicUrl,
  type File,
  type Product
} from '@epilot/journey-logic-commons'
import { useMemo } from 'react'

export type ImageFile = File & {
  id: string
  src: string
}

const DEFAULT_IMAGES: Array<ImageFile> = []

export const useOptimizedProductImages = (
  product: Product,
  IMAGE_PREVIEW_API_URL: string | undefined
): ImageFile[] => {
  return useMemo(() => {
    if (!areProductImagesHydrated(product) || !IMAGE_PREVIEW_API_URL) {
      return DEFAULT_IMAGES
    }

    /* Omit images with private access control, map them into optimized images */
    return product.product_images
      .filter(({ access_control }) => access_control !== 'private')
      .map<ImageFile>((image) => ({
        ...image,
        id: image._id,
        src: getOptimizedImageURL(
          IMAGE_PREVIEW_API_URL,
          getPublicUrl(image),
          image._id
        )
      }))
  }, [IMAGE_PREVIEW_API_URL, product])
}
