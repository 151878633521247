import type { ComponentProps, Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

type JourneyLauncherContextValue = {
  selectedJourneyId: string | null
  setSelectedJourneyId: Dispatch<SetStateAction<string | null>>
}

const JourneyLauncherContext =
  createContext<JourneyLauncherContextValue | null>(null)

const JourneyLauncherContextProvider = (
  props: Omit<ComponentProps<typeof JourneyLauncherContext.Provider>, 'value'>
) => {
  const [selectedJourneyId, setSelectedJourneyId] =
    useState<JourneyLauncherContextValue['selectedJourneyId']>(null)

  const value = useMemo(
    () => ({ selectedJourneyId, setSelectedJourneyId }),
    [selectedJourneyId]
  )

  return <JourneyLauncherContext.Provider value={value} {...props} />
}

const DEFAULT_CONTEXT_VALUE: JourneyLauncherContextValue = {
  selectedJourneyId: null,
  setSelectedJourneyId: () => {}
}

export const useJourneyLauncherContext = () => {
  const context = useContext(JourneyLauncherContext)

  useEffect(() => {
    if (!context) {
      /**
       * @todo Once we're sure it's always being applied, throw error rather than logging
       */
      console.error(
        new Error(
          'useJourneyLauncherContext must be used within a JourneyLauncherContextProvider'
        )
      )
    }
  }, [context])

  return context ?? DEFAULT_CONTEXT_VALUE
}

export default JourneyLauncherContextProvider
